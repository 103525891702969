import { Button } from 'react-bootstrap'
import { useRecoilState } from 'recoil'

import * as state from '../states'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'

export const DashboardPage = () => {
	const [tokens, setTokens] = useRecoilState(state.tokens)
	const [profile, setProfile] = useRecoilState(state.profile)

	const navigate = useNavigate()
	//TODO: remove redirection after DashboardPage is ready
	useEffect(() => {
		navigate('/offer/scope')
	}, [])


	const onClick = () => {
		console.log({ tokens, profile })
		setTokens(null)
		setProfile(null)
	}

	return <>
		Dashboard

		<Button onClick={onClick}>Logout</Button>
	</>
}
