import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import * as lib from '../../lib'
import * as state from '../../states'
import { IOfferScope } from '../../types'
import { useSearchParams } from 'react-router'

type IOfferScopeEditNotesModalProps = {
	item: IOfferScope | null;
	show: boolean;
	onClose: () => void;
	onSave: () => void;
	isViewMode?: boolean;
}

export const OfferScopeEditNotesModal = (
	props: IOfferScopeEditNotesModalProps
) => {
	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)
	const [searchParams] = useSearchParams()
	const tokens = useRecoilValue(state.tokens)
	const access = tokens?.access ?? null
	const group = lib.groupSearchParams(searchParams.get('group') ?? '')

	const { item, show, onClose, onSave, isViewMode = false } = props

	const [notes, setNotes] = useState<string>(item?.notes ?? '')
	const [prevNotes, setPrevNotes] = useState<string>(item?.notes ?? '')

	if (!show) {
		return null
	}

	const onUpdate = async () => {
		const uri: string = `offer/scope/${item?.id}`
		const method: string = 'PATCH'
		const params: lib.FetchOptions['params'] = {}
		const headers = lib.bearer(access)
		const body: string = JSON.stringify({
			notes,
			...(item?.group ? { group: item?.group } : {})
		})
		const options: lib.FetchOptions = { body, method, params, headers }

		try {
			setFetchIsLoading(state.turnOnLoader)
			await lib.fetching<void>(uri, options)
			setPrevNotes(notes)
			onSave()
		} catch (error: unknown) {
			// TODO: pretty notification alert
			console.error(error)
		} finally {
			setFetchIsLoading(state.turnOffLoader)
		}
	}

	return <div
		className="modal show"
		style={{ display: 'block', position: 'initial' }}
	>
		<Modal show={show} onHide={onClose} className={'edit-notes-modal'}>
			<Modal.Header closeButton>
				<Modal.Title>Edit notes for offer scope #{item?.id}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>Notes</Form.Label>
						<textarea name="notes"
						          id={`scope-notes-${item?.id}`}
						          className={'form-control textarea-field'}
						          value={notes}
						          disabled={isViewMode}
						          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				{
					!isViewMode ? (
						<Button variant="primary"
						        onClick={onUpdate}
						        disabled={prevNotes === notes}
						>
							Save
						</Button>
					) : null
				}

				<Button variant="secondary" onClick={onClose}>Close</Button>
			</Modal.Footer>
		</Modal>
	</div>
}
