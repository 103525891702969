import React from 'react'
import { useRecoilValue } from 'recoil'
import dt from 'dayjs'

import { ACCOUNT_PAGE_SORT_FIELDS, IAccountSortField } from '../../hooks'
import * as state from '../../states'
import { IAccount, IRole, Part } from '../../types'
import {
	UiTable,
	IUiTableConfig,
	IconDeleteBin,
	IconEdit,
	headCellFormatterSortable,
	ESortOrder
} from '../ui'
import { EAccountIsActive, EAccountIsEmailVerified } from '../../types/enum'
import { IS_ACTIVE_COLORS_MAP, IS_EMAIL_VERIFIED_COLORS_MAP } from '../../constants'
import { Access } from '../../lib'


type IAccountListTableProps = {
	items: IAccount[];
	skip: number;
	sortBy: IAccountSortField;
	setSortBy: (sortBy: IAccountSortField) => void;
	order: ESortOrder;
	setOrder: (order: ESortOrder) => void;
	onEdit?: (item: IAccount, e: React.MouseEvent) => void;
	onRemove?: (item: IAccount, e: React.MouseEvent) => void;
}

type IAccountIsActiveProps = {
	value: EAccountIsActive;
	rowData: IAccount; // { }
	onClick?: (rowData: IAccount, e: React.MouseEvent) => void;
	classNamesToAdd?: string;
}

type IAccountIsEmailVerifiedProps = {
	value: EAccountIsEmailVerified;
	rowData: IAccount; // { }
	onClick?: (rowData: IAccount, e: React.MouseEvent) => void;
	classNamesToAdd?: string;
}

const AccountIsActiveField = (props: IAccountIsActiveProps) => {
	const { value, classNamesToAdd, rowData, onClick } = props

	return <>
		<span
			/*style={{cursor: 'pointer'}}*/
			className={`badge text-white fw-normal py-1 px-2 fs-12 rounded-1 edit ${classNamesToAdd ?? ''}`}
			onClick={(e) => onClick && onClick(rowData, e)}
		>
			{value === EAccountIsActive.active ? 'Active' : value === EAccountIsActive.unactive ? 'Unactive' : '?' }
		</span>
	</>
}

const AccountIsEmailVerifiedField = (props: IAccountIsEmailVerifiedProps) => {
	const { value, classNamesToAdd, rowData, onClick } = props

	return <>
		<span
			/*style={{cursor: 'pointer'}}*/
			className={`badge text-white fw-normal py-1 px-2 fs-12 rounded-1 edit ${classNamesToAdd ?? ''}`}
			onClick={(e) => onClick && onClick(rowData, e)}
		>
			{value === EAccountIsEmailVerified.verified ? 'Verified' : value === EAccountIsEmailVerified.pending ? 'Pending' : '?'}
		</span>
	</>
}

export const AccountListTable = (props: IAccountListTableProps) => {
	const {
		items = [],
		skip = 0,
		sortBy,
		setSortBy,
		order,
		setOrder,
		onEdit,
		onRemove
	} = props
	const profile = useRecoilValue(state.profile)
	const $access = profile ? new Access(profile) : null

	const accountListTableConfig: IUiTableConfig<IAccount>[] = [
		{
			key: 'id',
			title: 'ID'
		},
		{
			key: 'email',
			title: 'Email'
		},
		{
			key: 'role',
			title: 'Role',
			rowCellFormatter: ({ value, rowData, rowsData, rowIndex, cellIndex }) => {
				const role: Part<IRole, 'createdAt' | 'updatedAt'> | null = rowData.role ?? null
				const valueFormat: string = role ? `${role.name} (${role.code})` : '?'

				return valueFormat
			}
		},
		{
			key: 'isEmailVerified',
			title: 'Email verified',
			headCellFormatter: ({ value, configItem }) => {
				return <div style={{ textAlign: 'center' }}>{value}</div>
			},
			rowCellFormatter: ({ value, rowData, rowIndex, cellIndex }) => {
				const valueFormat: EAccountIsEmailVerified = value ? EAccountIsEmailVerified.verified : EAccountIsEmailVerified.pending

				return <div style={{ textAlign: 'center' }}
				>
					<AccountIsEmailVerifiedField
						value={valueFormat}
						rowData={rowData}
						classNamesToAdd={IS_EMAIL_VERIFIED_COLORS_MAP?.[valueFormat]}
						onClick={(rowData, e) => {
							console.log('Click on Status field :::', { value, rowData, rowIndex, cellIndex })
						}}
					/>
				</div>
			}
		},
		{
			key: 'isActive',
			title: 'Active',
			headCellFormatter: ({ value, configItem }) => {
				return <div style={{ textAlign: 'center' }}>{value}</div>
			},
			rowCellFormatter: ({ value, rowData, rowIndex, cellIndex }) => {
				const valueFormat: EAccountIsActive = value ? EAccountIsActive.active : EAccountIsActive.unactive

				return <div style={{ textAlign: 'center' }}
				>
					<AccountIsActiveField
						value={valueFormat}
						rowData={rowData}
						classNamesToAdd={IS_ACTIVE_COLORS_MAP?.[valueFormat]}
						onClick={(rowData, e) => {
							console.log('Click on Status field :::', { value, rowData, rowIndex, cellIndex })
						}}
					/>
				</div>
			}
		},
		{
			key: 'registeredAt',
			title: 'Registered At',
			headCellFormatter: ({ value, configItem, cellIndex }) => {
				return <div style={{ textAlign: 'center' }}>{value}</div>
			},
			rowCellFormatter: ({ value, rowData, rowIndex, cellIndex }) => {
				const date: Date | null = value ? dt(value).toDate() : null
				const dateLong: string = date ? dt(date).format('DD.MM.YYYY HH:mm') : ''
				const dateShort: string = date ? dt(date).format('DD.MM.YYYY') : ''
				return <div style={{ textAlign: 'center' }} title={dateLong}>{dateShort}</div>
			}
		},
		{
			key: 'ACTIONS',
			title: 'Actions',
			headCellFormatter: ({ configItem }) => {
				return <div>{''}</div>
			},
			rowCellFormatter: ({ rowData }) => {
				return <>
					<div
						style={{
							margin: '0 auto',
							textAlign: 'center'
						}}
					>

						{
							!!onEdit && !!$access?.accountModify
								? (
									<button name="edit"
									        className="icon border-0 rounded-circle text-center edit bg-success-transparent edit-item"
									        onClick={(e) => onEdit && onEdit(rowData, e)}
									>
										<IconEdit/>
									</button>
								)
								: null
						}

						{
							!!onRemove && !!$access?.accountRemove
								? (
									<button
										name="delete"
										className="icon border-0 rounded-circle text-center trash bg-danger-transparent delete-item"
										onClick={(e) => onRemove && onRemove(rowData, e)}
									>
										<IconDeleteBin/>
									</button>
								)
								: null
						}
					</div>
				</>
			}
		}
	]

	const accountListTableConfigSortable: IUiTableConfig<IAccount>[] = accountListTableConfig.map(
		(configItem) => {
			const configItemIsSortable = ACCOUNT_PAGE_SORT_FIELDS.includes(configItem.key as IAccountSortField)

			if (!configItemIsSortable) {
				return configItem
			} else {
				return {
					...configItem,
					isSortable: true,
					headCellFormatter: ({
						value,
						configItem,
						cellIndex
					}) => {

						return headCellFormatterSortable({
							value,
							configItem,
							cellIndex,
							sortBy,
							setSortBy,
							order,
							setOrder
						})
					}
				}
			}
		})

	//console.log('AccountListTable:: ', { accountListTableConfig, accountListTableConfigSortable })

	return <>
		<UiTable
			data={items}
			config={accountListTableConfigSortable}
			// TODO: uncomment if necessary
			// classesToAdd={{
			// 	wrapper: 'table-sticky-wrapper',
			// }}
		/>
	</>
}