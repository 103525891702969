import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
// import styled from 'styled-components'

import { useAuthLogin } from '../hooks'
import { UiNotification, LogoIcon } from '../components'
import { INoFetch } from '../types'

// const Span = styled.span<{ toggle: string }>``

export const LoginPage = () => {
	const [login, setLogin] = useState<string>(process.env?.REACT_APP_LOGIN ?? '')
	const [password, setPassword] = useState<string>(process.env?.REACT_APP_PASSWORD ?? '')
	const [submit, setSubmit] = useState<boolean>(false)

	const { error, profile, tokens, ip, warmUp } = useAuthLogin(login, password, submit)
	const errorText = (error as INoFetch)?.message ?? 'Invalid request. Please try again.'

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		warmUp()
		setSubmit(true)
	}

	return <>
		<div className="main-content-area ps-0">
			<div className="container">
				{/* <!--=== Start Login Area ===--> */}
				<div className="login-area">
					<div className="text-center mb-4 d-flex align-items-center justify-content-center">
						<LogoIcon width="43px" height="54px"/>
						<span className="fs-30 ms-3 logo-text">All In CRM</span>
					</div>

					<div className="card rounded-3 border-0 mb-24 mw-560 m-auto">
						<div className="card-body p-30">

							{/* Welcome */}
							{/* <div className="card-title mb-20 pb-20 border-bottom border-color text-center">
								<h4 className="mb-0 mb-2 fs-20">Login</h4>
								<p className="text-body fs-14">Welcome back Jacob Smith!</p>
							</div> */}
							{/* Welcome end */}

							{/* Notification */}
							{!!error && errorText && (
								<div className="section-title mb-24 text-center">
									<UiNotification text={errorText}/>
								</div>
							)}

							{/* <div className="bg-primary-transparent p-3 rounded-3 text-center mb-25">
								<span className="text-dark fs-14 fw-semibold mb-2 d-block">Login Access</span>
								<p className="text-primary fs-14 mb-0">
									<span className="text-dark">Email:</span>
									jacobsmith@dass.com
								</p>
								<p className="text-primary fs-14">
									<span className="text-dark">Password:</span>
									jacobsmith123456
								</p>
							</div> */}
							{/* Notofication end */}

							<Form onSubmit={onSubmit}>
								<Form.Group>
									<div className="form-group mb-4">
										{/* <label className="fw-semibold fs-14 mb-2">
											User Name
											<span className="text-danger">*</span>
										</label> */}
										<Form.Label className="fw-semibold fs-14 mb-2" htmlFor={'user-name'}>
											User Name
											<span className="text-danger">*</span>
										</Form.Label>
										<div className="form-floating">
											{/* <input type="text" className="form-control" value="jacobsmith@dass.com" /> */}
											<Form.Control
												id={'user-name'}
												className="form-control"
												placeholder="User Name"
												type="text"
												value={login}
												onChange={(e) => setLogin(e.target.value)}
											/>
										</div>
									</div>
									<div className="form-group mb-4">
										<Form.Label className="fw-semibold fs-14 mb-2" htmlFor={'user-password'}>
											Password
											<span className="text-danger">*</span>
										</Form.Label>
										{/* <label className="fw-semibold fs-14 mb-2">
											Password
											<span className="text-danger">*</span>
										</label> */}
										<div className="form-floating position-relative">
											{/* <input type="password" className="form-control" id="password-field1" value="jacobsmith123456" /> */}
											<Form.Control
												id={'user-password'}
												className="form-control"
												placeholder="User Name"
												type="password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
											/>
											{/* <Span className="text-body ri-eye-line field-icon toggle-password position-absolute top-50 end-0 fs-20 translate-middle-y" style={{ right: '10px !important', cursor: 'pointer' }} toggle="#password-field1" /> */}
										</div>
									</div>

									{/* <div className="form-group d-sm-flex justify-content-between">
										<div className="form-check mb-4">
											<input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
												style={{ position: 'relative', top: '-2px' }} />
											<label className="form-check-label fs-14 text-body ms-2" htmlFor="flexCheckDefault">
												Remember password ?
											</label>
										</div>
										<div className="mb-4">
											<a href="forgot-password.html" className="fs-14 text-primary text-decoration-none">Forgot your password?</a>
										</div>
									</div> */}

									<div className="form-group mb-4">
										{/* <a href="index.html" className="btn btn-primary rounded-1 w-100 py-3">Login</a> */}
										<Button variant="primary" className="btn btn-primary rounded-1 w-100 py-3"
										        type="submit">Login</Button>
									</div>

									{/* <div className="form-group mb-4 text-center">
										<p className="text-body mb-4 fs-14">
											Don't have an account?
											<a href="register.html" className="text-primary text-decoration-none">
												Register
											</a>
										</p>
										<span className="or d-block">
											<span className="px-3 bg-white fw-medium">
												OR
											</span>
										</span>
									</div> */}

									{/* Media */}
									{/* <div className="form-group mb-0 text-center">
										<ul className="social-link ps-0 mb-0 list-unstyled">
											<li>
												<a href="https://www.google.com/" target="_blank" rel="noreferrer" data-bs-toggle="tooltip" data-bs-placement="top"
													data-bs-title="Google">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
														stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
														className="feather feather-mail">
														<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
														<polyline points="22,6 12,13 2,6"></polyline>
													</svg>
												</a>
											</li>
											<li>
												<a href="https://www.facebook.com/" target="_blank" rel="noreferrer" data-bs-toggle="tooltip" data-bs-placement="top"
													data-bs-title="Facebook">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
														stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
														className="feather feather-facebook">
														<path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
													</svg>
												</a>
											</li>
											<li>
												<a href="https://www.twitter.com/" target="_blank" rel="noreferrer" data-bs-toggle="tooltip" data-bs-placement="top"
													data-bs-title="Twitter">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
														stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
														className="feather feather-twitter">
														<path
															d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z">
														</path>
													</svg>
												</a>
											</li>
											<li>
												<a href="https://www.linkedin.com/" target="_blank" rel="noreferrer" data-bs-toggle="tooltip" data-bs-placement="top"
													data-bs-title="Linkedin">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
														stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
														className="feather feather-linkedin">
														<path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
														<rect x="2" y="9" width="4" height="12"></rect>
														<circle cx="4" cy="4" r="2"></circle>
													</svg>
												</a>
											</li>
										</ul>
									</div> */}
									{/* Media end */}
								</Form.Group>
							</Form>
						</div>
					</div>
				</div>
				{/* <!--=== End Login Area ===--> */}
			</div>
		</div>
	</>
}