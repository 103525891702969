import dt from 'dayjs'
import 'dayjs/locale/uk'

import * as lib from '../lib'
import { IFetchItems, IFetchAccount, IAccount } from '../types'

export const listAccount = async (
	filter: ReturnType<typeof lib.filterSetup>,
	sort: ReturnType<typeof lib.sortSetup>,
	skip: ReturnType<typeof lib.skipSetup>,
	take: ReturnType<typeof lib.takeSetup>,
	signal: AbortSignal,
	token?: string | null,
): Promise<IFetchItems<IAccount>> => {
	const headers = lib.bearer(token)
	const method: string = 'GET'
	const params: lib.FetchOptions['params'] = {
		f: filter,
		s: sort,
		skip,
		take
	}
	const uri: string = 'account'

	const options: lib.FetchOptions = {
		headers,
		method,
		params,
		signal,
	}
	const data = await lib.fetching<IFetchItems<IFetchAccount>>(uri, options)

	const rows = data?.items
		?.map((item: IFetchAccount): IAccount => {
			const { auth, role, registeredAt, createdAt, updatedAt, deletedAt, ...row } = item
			return {
				...row,
				registeredAt: registeredAt ? dt(registeredAt).toDate() : null,
				createdAt: dt(createdAt).toDate(),
				updatedAt: dt(updatedAt).toDate(),
				deletedAt: deletedAt ? dt(deletedAt).toDate() : null,
				role: role ? {
					...role,
					createdAt: dt(role.createdAt).toDate(),
					updatedAt: dt(role.updatedAt).toDate(),
				} : null,
				auth: auth ? {
					...auth,
					updatedAt: dt(auth.updatedAt).toDate(),
				} : null,
			}
		})

	return {
		items: rows ?? [],
		total: data?.total ?? 0,
	}
}
