import { selector } from 'recoil'
import uniqid from 'uniqid'

import * as s1 from './state.profile.access'

import { ERoleAccess } from '../types/enum'

const key = uniqid()

export const isRoot = selector<boolean>({
	key: `profile.isRoot:selector:${key}`,
	get: ({ get }) => {
		const access = get(s1.access)
		const rule = access.find((item) => item.$key === ERoleAccess.any)
		return rule?.$value ?? false
	},
})
