import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import * as lib from '../../lib'
import * as state from '../../states'
import { IFetchItemsSetup, IOffer, IOfferScope } from '../../types'
import { OfferListTable } from './OfferListTable'
import { useOfferList, useFiltersOfferListModal, useSortOfferListModal } from '../../hooks'
import { ToolPanel, UiPagination, ESortOrder } from '../ui'
import { Filters, FiltersButtonIcon } from '../filters'
import { EFilterOperator } from '../../types/enum'
import { Access } from '../../lib'

type IOfferUnbindListModalProps = {
	scope: IOfferScope | null;
	show: boolean;
	take: number | 'all';
	onClose: () => void;
}

export const OfferUnbindListModal = (
	props: IOfferUnbindListModalProps
) => {
	const tokens = useRecoilValue(state.tokens)
	const access = tokens?.access ?? null
	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)

	const { scope, show, take = 10, onClose } = props

	const profile = useRecoilValue(state.profile)
	const $access = profile ? new Access(profile) : null

	const [page, setPage] = useState<number>(0)
	const [sort, setSort] = useState<[string, string][]>([])
	const skip = (take === 'all') ? 0 : page > 0 ? page * take : 0

	const {
		filter,
		send,
		activeFiltersCount,
		filterFillFactor,
		isFiltersPanelOpen,
		setIsFiltersPanelOpen,
		offerModalFiltersConfig,
		defaultFiltersValue,
		initialFiltersValue,
		clearChildFilters,
		setUserFilters
	} = useFiltersOfferListModal(setPage)

	const {
		sortBy,
		setSortBy,
		order,
		setOrder
	} = useSortOfferListModal()

	const setup: IFetchItemsSetup = {
		filter: [[`scopeId${EFilterOperator.isEqual}`, scope?.id], ...filter],
		sort: order !== ESortOrder.unset ? [[sortBy, order]] : [],
		skip,
		take
	}
	const { error, items, total, warmUp } = useOfferList(setup, send)

	const onBind = undefined // (item: IOffer, e: React.MouseEvent) => { }
	const onUnBind = async (item: IOffer, e: React.MouseEvent) => {
		e.preventDefault()
		//console.log({ item, scope })
		const uri: string = `offer/${item?.id}/unbind`
		const method: string = 'PATCH'
		const params: lib.FetchOptions['params'] = {}
		const headers = lib.bearer(access)
		const options: lib.FetchOptions = { method, params, headers }

		try {
			setFetchIsLoading(state.turnOnLoader)
			await lib.fetching<void>(uri, options)
			warmUp()
		} catch (error: unknown) {
			// TODO: pretty notification alert
			console.error(error)
		} finally {
			setFetchIsLoading(state.turnOffLoader)
		}
	}

	return <div
		className="modal show"
		style={{ display: 'block', position: 'initial' }}
	>
		<Modal show={show} onHide={onClose} size="xl" className={'modal-with-sticky'}>
			<Modal.Header className={'modal-header--sticky'} closeButton>
				<FiltersButtonIcon
					activeFiltersCount={activeFiltersCount}
					filterFillFactor={filterFillFactor}
					onClick={() => setIsFiltersPanelOpen((val) => !val)}
					styles={{ marginRight: 'auto' }}
				/>

				<Modal.Title>Unbinding offers from scope #{scope?.id}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Filters
					classesToAdd={{ filters: 'filters-offer-modal' }}
					config={offerModalFiltersConfig}
					initialFiltersValue={defaultFiltersValue}
					resetFiltersValue={initialFiltersValue}
					isAutoModeWithDebounce={false}
					isFiltersPanelOpen={isFiltersPanelOpen}
					isFiltersToClear={clearChildFilters}
					setFiltersCB={(value) => {
						setUserFilters(value)
					}}
				/>

				<OfferListTable
					items={items}
					skip={skip}
					sortBy={sortBy}
					setSortBy={setSortBy}
					order={order}
					setOrder={setOrder}
					onBind={onBind}
					onUnBind={onUnBind}
				/>
			</Modal.Body>

			<Modal.Footer className={'modal-footer--sticky'}>
				<ToolPanel flow=">-|-<" width={'100%'}>
					<Button variant="secondary" onClick={onClose}>Close</Button>

					<UiPagination
						page={page}
						take={take}
						total={total}
						click={(page: number, e: React.MouseEvent) => {
							e.preventDefault()
							setPage(page)
						}}
					/>
				</ToolPanel>

			</Modal.Footer>
		</Modal>
	</div>
}
