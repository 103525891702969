export const url = (
	uri: string,
	params?: Record<string, any> | null,
): URL => {
	const ORIGIN: string = process.env?.REACT_APP_BE_ORIGIN ?? window.location.origin
	const path: string = uri.trim().replace(/^[/]?(.*?)[/]?$/, '$1')
	const url = new URL(`/api/${path}`, ORIGIN)
	if (params) {
		for (const key in params) {
			//const value = encodeURIComponent(params[key])
			const value = params[key]
			url.searchParams.append(key, value)
		}
	}
	return url
}
