import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import * as lib from '../../lib'
import * as state from '../../states'
import { IOfferScope } from '../../types'
import { EOfferScopeCapType, EOfferScopeStatus } from '../../types/enum'
import { ISelectOption } from '../../types/ui'
import { CAP_TYPE_OPTIONS, STATUS_OPTIONS } from '../../constants'
import { useSearchParams } from 'react-router'

type IOfferScopeEditModalProps = {
	item: IOfferScope | null;
	show: boolean;
	onClose: () => void;
	onSave: () => void;
}

export const OfferScopeEditModal = (
	props: IOfferScopeEditModalProps
) => {
	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)
	const [searchParams] = useSearchParams()
	const tokens = useRecoilValue(state.tokens)
	const access = tokens?.access ?? null
	const group = lib.groupSearchParams(searchParams.get('group') ?? '')

	const { item, show, onClose, onSave } = props

	const [name, setName] = useState<string>(item?.name ?? '')
	const [cap, setCap] = useState<string>(Number.isInteger(item?.cap) ? `${item?.cap}` : '')
	const [capType, setCapType] = useState<ISelectOption<EOfferScopeCapType> | null>(() => {
		return CAP_TYPE_OPTIONS.find(
			(optionItem) => optionItem.value === item?.capType
		) ?? CAP_TYPE_OPTIONS[0]
	})
	const [status, setStatus] = useState<ISelectOption<EOfferScopeStatus> | null>(() => {
		return STATUS_OPTIONS.find(
			(optionItem) => optionItem.value === item?.status
		) ?? STATUS_OPTIONS[0]
	})

	if (!show) {
		return null
	}

	const onUpdate = async () => {
		const uri: string = `offer/scope/${item?.id}`
		const method: string = 'PATCH'
		const params: lib.FetchOptions['params'] = {}
		const headers = lib.bearer(access)
		const body: string = JSON.stringify({
			name,
			cap: +cap > 0 ? +cap : 0,
			capType: capType?.value,
			status: status?.value,
			...(item?.group ? { group: item?.group } : {})
		})
		const options: lib.FetchOptions = { body, method, params, headers }

		try {
			setFetchIsLoading(state.turnOnLoader)
			await lib.fetching<void>(uri, options)
			onSave()
		} catch (error: unknown) {
			// TODO: pretty notification alert
			console.error(error)
		} finally {
			setFetchIsLoading(state.turnOffLoader)
		}
	}

	return <div
		className="modal show"
		style={{ display: 'block', position: 'initial' }}
	>
		<Modal show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Edit offer scope #{item?.id}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label htmlFor={'input-name'}>Name</Form.Label>
						<Form.Control
							id={'input-name'}
							placeholder="Name"
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<br/>
						<Form.Label htmlFor={'input-cap'}>Cap</Form.Label>
						<Form.Control
							id={'input-cap'}
							placeholder="Cap"
							type="text"
							value={cap}
							onChange={(e) => setCap(e.target.value)}
						/>
						<br/>
						<Form.Label htmlFor={'select-capType'}>Cap Type</Form.Label>
						<Select
							inputId={'select-capType'}
							className="react-select-container"
							options={CAP_TYPE_OPTIONS}
							value={capType}
							isSearchable={false}
							onChange={(selectedItem) => {
								setCapType(selectedItem)
							}}
						/>
						<br/>
						<Form.Label htmlFor={'select-status'}>Status</Form.Label>
						<Select
							inputId={'select-status'}
							className="react-select-container"
							options={STATUS_OPTIONS}
							value={status}
							isSearchable={false}
							onChange={(selectedItem) => {
								setStatus(selectedItem)
							}}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="primary" onClick={onUpdate}>Save</Button>
				<Button variant="secondary" onClick={onClose}>Close</Button>
			</Modal.Footer>
		</Modal>
	</div>
}
