export enum ERoleAccess {
	any = 'any',
	authLogin = 'auth:login',
	authProfile = 'auth:profile',

	// role
	roleCreate = 'role:create',
	roleList = 'role:list',
	roleModify = 'role:update',
	roleRead = 'role:read',
	roleRemove = 'role:remove',

	// account
	accountCreate = 'account:create',
	accountList = 'account:list',
	accountModify = 'account:update',
	accountRead = 'account:read',
	accountRemove = 'account:remove',

	// offerScope
	offerScopeCreate = 'offer.scope:create',
	offerScopeList = 'offer.scope:list',
	offerScopeModify = 'offer.scope:update',
	offerScopeRead = 'offer.scope:read',
	offerScopeRemove = 'offer.scope:remove',

	// offer
	offerBind = 'offer:bind',
	offerList = 'offer:list',
	offerUnbind = 'offer:unbind',
}