import { IFilterConfig } from '../../types'
import { EFilterType, EFilterSpecOperator } from '../../types/enum'

export function constructUserFiltersFromUrl<T>(
	urlFilter: [string, any][],
	config: IFilterConfig<keyof T>[],
	filtersDefaultValue: T
): T {
	const newUserFilters: T = { ...filtersDefaultValue }

	if (!!urlFilter && urlFilter.length > 0) {
		urlFilter.forEach(([itemNameWithOperator, itemValue]) => {
			const foundItem = config.find(configItem => {
				return `${String(configItem.name)}${configItem.operator}` === itemNameWithOperator
			})

			if (!!foundItem && itemValue?.length > 0) {
				const fieldName = foundItem.name
				newUserFilters[fieldName] = foundItem?.specOperator === EFilterSpecOperator.everywhere ?
					itemValue.slice(1, -1) : itemValue
			}
		})
	}
	//console.log('++++++++++++ constructUserFiltersFromUrl:: ', { urlFilter, newUserFilters })
	return newUserFilters
}

export function constructNewFilterFromConfig<T>(
	userFiltersState: T, config: IFilterConfig<keyof T>[]
): {
	newFilter: [string, any][];
	activeFiltersCnt: number;
} {
	const newFilter: [string, any][] = []
	let activeFiltersCnt = 0

	config.forEach(configItem => {
		const configItemType = configItem.type
		const configItemName = configItem.name
		const configItemNameStr = String(configItemName)
		const stateValue = userFiltersState[configItemName]

		switch (configItemType) {
			case EFilterType.input: {
				if (stateValue) {
					// TODO: check экранирование для '*' и '%' символов введенных пользователем
					const stateValueWithSpecOperator =
						configItem?.specOperator === EFilterSpecOperator.everywhere ?
							`%${stateValue}%` : stateValue
					newFilter.push([`${configItemNameStr}${configItem.operator}`, stateValueWithSpecOperator])
					activeFiltersCnt++
				}
				break
			}
			case EFilterType.selectSingle: {
				if (stateValue) {
					newFilter.push([`${configItemNameStr}${configItem.operator}`, stateValue])
					activeFiltersCnt++
				}
				break
			}
			case EFilterType.selectMultiple: {
				if (stateValue && Array.isArray(stateValue) && stateValue?.length > 0) {
					newFilter.push([`${configItemNameStr}${configItem.operator}`, stateValue])
					activeFiltersCnt++
				}
				break
			}
			default: {
				throw new Error(`filtersConfig for name=${configItemNameStr} with type=${configItemType} is NOT valid`)
			}
		}
	})

	return {
		newFilter,
		activeFiltersCnt
	}
}