import { useState, useEffect, useRef } from 'react'
import { IRoleFilters, IFilterConfig } from '../types'
import { constructNewFilterFromConfig, constructUserFiltersFromUrl } from '../components/filters'
import * as lib from '../lib'
import { EFilterOperator, EFilterType, EFilterSpecOperator, EFilterFetchConvertType } from '../types/enum'
import { useRecoilValue } from 'recoil'
import * as state from '../states'
import { IS_SYSTEM_OPTIONS } from '../constants'
import { useSearchParams } from 'react-router'

type IUseFiltersRolePage = {
	filter: [string, any][];
	send: boolean;
	activeFiltersCount: number;
	filterFillFactor: number;
	isFiltersPanelOpen: boolean;
	setIsFiltersPanelOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void;
	roleFiltersConfig: IFilterConfig<keyof IRoleFilters>[];
	defaultFiltersValue: IRoleFilters;
	initialFiltersValue: IRoleFilters;
	clearChildFilters: number;
	setUserFilters: (value: (((prevState: (IRoleFilters | null)) => (IRoleFilters | null)) | IRoleFilters | null)) => void;
}

const ROLE_FILTERS_DEFAULT: IRoleFilters = {
	code: '',
	name: '',
	isSystem: null,
	notes: ''
}


export const useFiltersRolePage = (): IUseFiltersRolePage => {
	const isFirstRender = useRef<boolean>(true)

	const profile = useRecoilValue(state.profile)

	const [searchParams, setSearchParams] = useSearchParams()

	const urlFilter = (() => {
		try {
			return lib.filterSearchParams(searchParams.get('f')) ?? []
		} catch (error) {
			console.error(error)
			return []
		}
	})()

	const [filter, setFilter] = useState<[string, any][]>([...urlFilter]) // main filter with mixins
	const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState<boolean>(false)
	const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0)

	const [clearChildFilters, setClearChildFilters] = useState<number>(0)
	const [send, setSend] = useState<boolean>(false)
	const [userFilters, setUserFilters] = useState<IRoleFilters | null>(null)

	const filterFillFactor = !!userFilters ? (100 * activeFiltersCount / Object.keys(userFilters).length) : 0

	const roleFiltersConfig: IFilterConfig<keyof IRoleFilters>[] = [
		{
			name: 'code',
			label: 'Code',
			type: EFilterType.input,
			operator: EFilterOperator.isLike,
			specOperator: EFilterSpecOperator.everywhere,
			styles: { width: '200px' }
		},
		{
			name: 'name',
			label: 'Name',
			type: EFilterType.input,
			operator: EFilterOperator.isLike,
			specOperator: EFilterSpecOperator.everywhere,
			styles: { width: '200px' }
		},
		// {
		// 	name: 'isSystem',
		// 	label: 'IsSystem',
		// 	type: EFilterType.selectSingle,
		// 	operator: EFilterOperator.isEqual,
		// 	options: IS_SYSTEM_OPTIONS,
		// 	styles: { minWidth: '160px' }
		// },
		// {
		// 	name: 'notes',
		// 	label: 'Notes',
		// 	type: EFilterType.input,
		// 	operator: EFilterOperator.isLike,
		// 	specOperator: EFilterSpecOperator.everywhere,
		// 	styles: { width: '200px' }
		// },
	]

	const initialUserFilters = constructUserFiltersFromUrl(
		urlFilter,
		roleFiltersConfig,
		ROLE_FILTERS_DEFAULT
	)

	// reset filters onGroup (menu tabs) changed
	useEffect(() => {
		if (send) {
			setFilter([]) // clear main filter --> update in useEffect
			setUserFilters(ROLE_FILTERS_DEFAULT)
			setClearChildFilters(prev => prev + 1)
		}
	}, [])

	useEffect(() => {
		//console.log('hook.useFiltersRolePage useEffect::START:: ', { userFilters, filter })
		if (!!userFilters) {
			setSend(true)

			const { newFilter, activeFiltersCnt } = constructNewFilterFromConfig(
				userFilters,
				roleFiltersConfig
			)
			const newFilterStr = lib.filterSetup([...newFilter ?? []])

			setSearchParams((prev) => {
				if (newFilterStr) {
					prev.set('f', newFilterStr)
				} else {
					prev.delete('f')
				}

				// after first pageRefresh resetting is not needed
				if (!isFirstRender.current) {
					prev.delete('page')
				} else {
					isFirstRender.current = false
				}

				return prev
			})

			setFilter(newFilter)
			setActiveFiltersCount(activeFiltersCnt)

			// to open FiltersPanel if there were previously active filters on the page [onRefreshPage case]
			if (activeFiltersCnt > 0) {
				setIsFiltersPanelOpen(true)
			}
			console.log('hook.useFiltersRolePage:: useEffect::END:: ', { userFilters, filter, newFilter, newFilterStr })
		}
	}, [userFilters])

	return {
		filter,
		send,
		activeFiltersCount,
		filterFillFactor,
		isFiltersPanelOpen,
		setIsFiltersPanelOpen,
		roleFiltersConfig,
		defaultFiltersValue: ROLE_FILTERS_DEFAULT,
		initialFiltersValue: initialUserFilters,
		clearChildFilters,
		setUserFilters
	}
}