import { INoFetch, INoFetchAbort, INoFetchError, INoFetchUnknown } from '../types'
import { url as myUrl } from './lib.url'
import { toast } from 'react-toastify'

export type FetchParamScalar = number | string | boolean
export type FetchParam = null | FetchParamScalar | Array<FetchParamScalar>
export type FetchOptions = RequestInit & {
	timeout?: number;
	params?: Record<string, FetchParam> | null;
}

const fetchOptionsInit: RequestInit = {
	cache: 'no-store',
	keepalive: true,
	mode: 'cors',
	redirect: 'follow',
	referrer: '/',
	// referrerPolicy: 'origin', //
}

let toastId = '0'

export const fetching = async <T = any>(
	uri: string,
	options: FetchOptions,
): Promise < T | null > => {
	const method: string = options?.method?.toUpperCase() ?? 'GET'
	const signal: AbortSignal | null = options?.signal ?? null
	const timeout: number = options?.timeout ?? 5000

	let body: BodyInit | null = null
	if (typeof options?.body === 'string') {
		body = options.body
	} else if (
		options?.body instanceof FormData ||
		options?.body instanceof ReadableStream ||
		options?.body instanceof Blob ||
		options?.body instanceof ArrayBuffer ||
		options?.body instanceof URLSearchParams
	) {
		body = options.body
	}

	const headers: [string, string][] = []
	if (method === 'POST' && body instanceof FormData) {
		const entries: [string, string] = ['Content-Type', 'multipart/form-data']
		headers.push(entries)
	} else if (['POST', 'PUT', 'PATCH', 'DELETE', 'GET'].includes(method)) {
		const entries: [string, string] = ['Content-Type', 'application/json']
		headers.push(entries)
	}
	if (Array.isArray(options?.headers)) {
		const entries: [string, string][] = [...options?.headers]
		headers.push(...entries)
	} else if (options?.headers instanceof Headers) {
		const entries: [string, string][] = Array.from(options?.headers.entries())
		headers.push(...entries)
	} else if (!!options?.headers) {
		const entries: [string, string][] = Array.from(Object.entries(options?.headers))
		headers.push(...entries)
	}

	const controller = new AbortController()
	const abortTimer: NodeJS.Timeout = setTimeout(() => controller.abort(), timeout)
	signal?.addEventListener('abort', () => controller.abort())

	const url: URL = myUrl(uri, options?.params)
	const opt: RequestInit = {
		...fetchOptionsInit,
		...options as RequestInit,
		body,
		headers,
		method,
		signal: controller.signal,
	}
	const req = new Request(url, opt)
	// console.log({ req })

	try {
		// TODO check the logic
		// или выше делать - чекнуть выше
		// если с одним access токеном получил 401 'Unauthorized'
		// попробовать получить новый акксесс с refresh токеном (сходить на "/auth/refresh")
		// если получил - повторить прошлый запрос с новым акксесс токеном
		// а если и рефреш протух - дропать оба --- и редирект на логин
		const res: Response = await fetch(req)
		// console.log({ res })
		if (res.ok) {
			const data: T = await res.json()
			return data
		}
		const body = await res.json()
		throw new INoFetchError(req, res, timeout, body)
	} catch (error: unknown) {
		if ((error as any)?.options?.res?.status === 401) {
			const requestUrl = (error as any)?.options?.req?.url
			// TODO: check condition for multiple similar notifications are active (~ when 2 requests are send in parallel)
			if (!requestUrl?.includes('/auth/profile') && !toast.isActive(toastId)) {
				const textError = `${(error as any)?.options?.body?.message ?? 'Something went wrong!'}.`
				const textAction = 'Please reload the page or re-login!'
				toastId = toast.error(
					(<div>
						<div>{textError}</div>
						<div>{textAction}</div>
					</div>),
					{
						autoClose: 5000,
						hideProgressBar: false,
					}
				).toString()
			}
		}

		if (error instanceof INoFetch) {
			throw error
		} else if (error instanceof INoFetchError) {
			throw error
		} else if ((error as Error).name === 'AbortError') {
			throw new INoFetchAbort(req, timeout)
		}
		throw new INoFetchUnknown(error as Error, req, timeout)
	} finally {
		clearTimeout(abortTimer)
	}
}