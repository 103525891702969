import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faLinkSlash } from '@fortawesome/free-solid-svg-icons'
import { useRecoilValue } from 'recoil'

import { OFFER_LIST_MODAL_SORT_FIELDS, IOfferListModalSortField } from '../../hooks'
import { Access } from '../../lib'
import * as state from '../../states'
import { IOffer } from '../../types'
import { IUiTableConfig, UiTable, UiMoreTextInfo, headCellFormatterSortable, ESortOrder } from '../ui'

type IOfferListTableProps = {
	items: IOffer[];
	skip: number;
	sortBy: IOfferListModalSortField;
	setSortBy: (sortBy: IOfferListModalSortField) => void;
	order: ESortOrder;
	setOrder: (order: ESortOrder) => void;
	isViewMode?: boolean;
	onBind?: (item: IOffer, e: React.MouseEvent) => void;
	onUnBind?: (item: IOffer, e: React.MouseEvent) => void;
}

export const OfferListTable = (props: IOfferListTableProps) => {
	const {
		items = [],
		skip = 0,
		sortBy,
		setSortBy,
		order,
		setOrder,
		isViewMode = false,
		onBind,
		onUnBind
	} = props
	const profile = useRecoilValue(state.profile)
	const $access = profile ? new Access(profile) : null

	const offerListTableConfig: IUiTableConfig<IOffer>[] = [
		{
			key: 'id',
			title: 'ID'
		},
		{
			key: 'name',
			title: 'Name',
			classesToAdd: {
				td: 'white-space-wrap'
			}
		},
		//TODO: check if group column is necessary
		// {
		// 	key: 'group',
		// 	title: 'Group'
		// },
		{
			key: 'affiliateNetwork',
			title: 'Affiliate Network'
		},
		{
			key: 'country',
			title: 'Country',
			rowCellFormatter: ({ value, rowData }) => {
				const valueFormat: Array<string> = value ?? []

				return <UiMoreTextInfo value={valueFormat}/>
			}
		}
	]

	if (!isViewMode && (!!onBind || !!onUnBind)) {
		offerListTableConfig.push({
			key: 'ACTIONS',
			title: 'Actions',
			headCellFormatter: ({ configItem }) => {
				return <div>{''}</div>
			},
			rowCellFormatter: ({ rowData }) => {
				return <>
					<div
						style={{
							margin: '0 auto',
							textAlign: 'center'
						}}
					>
						{
							!!onBind && !!$access?.offerBind
								? (
									<Button
										className="icon"
										variant="outline-primary"
										style={{
											borderRadius: '100%',
											aspectRatio: 1,
											padding: '5px 10px'
										}}
										onClick={(e) => onBind && onBind(rowData, e)}
									>
										<FontAwesomeIcon icon={faLink} style={{ fontSize: '14px' }}/>
									</Button>
								)
								: null
						}

						{
							!!onUnBind && !!$access?.offerUnbind
								? (
									<Button
										className="icon"
										variant="outline-primary"
										style={{
											borderRadius: '100%',
											aspectRatio: 1,
											padding: '5px 10px'
										}}
										onClick={(e) => onUnBind && onUnBind(rowData, e)}
									>
										<FontAwesomeIcon icon={faLinkSlash} style={{ fontSize: '14px' }}/>
									</Button>
								)
								: null
						}
					</div>
				</>
			}
		})
	}

	const offerListTableConfigSortable: IUiTableConfig<IOffer>[] = offerListTableConfig.map(
		(configItem) => {
			const configItemIsSortable = OFFER_LIST_MODAL_SORT_FIELDS.includes(configItem.key as IOfferListModalSortField)

			if (!configItemIsSortable) {
				return configItem
			} else {
				return {
					...configItem,
					isSortable: true,
					headCellFormatter: ({
						value,
						configItem,
						cellIndex
					}) => {

						return headCellFormatterSortable({
							value,
							configItem,
							cellIndex,
							sortBy,
							setSortBy,
							order,
							setOrder
						})
					}
				}
			}
		})

	return <>
		<UiTable
			data={items}
			config={offerListTableConfigSortable}
			// TODO: uncomment if necessary
			// classesToAdd={{
			// 	wrapper: 'table-sticky-wrapper table-sticky-wrapper--top-60',
			// }}
		/>
	</>
}