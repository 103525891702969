import * as lib from '../lib'
import { IFetchAuthLogin } from '../types'


export const loginAuth = async (
	login: string,
	password: string,
	signal: AbortSignal,
	token?: string | null,
): Promise<IFetchAuthLogin | null> => {
	const body: string = JSON.stringify({ login, password })
	const headers = lib.bearer(token)
	const method: string = 'POST'
	const params: lib.FetchOptions['params'] = {}
	const uri: string = 'auth/login'

	const options: lib.FetchOptions = {
		body,
		headers,
		method,
		params,
		signal,
	}
	const data = await lib.fetching<IFetchAuthLogin>(uri, options)

	if (data === null) {
		return null
	}

	const { ip, profile, token: tokens } = data

	return {
		ip,
		profile,
		token: tokens
	}
}
