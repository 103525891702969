import { useState, useEffect } from 'react'
import { ESortOrder } from '../components'
import { useSearchParams } from 'react-router'
import * as lib from '../lib'

export const ROLE_PAGE_SORT_FIELDS = ['id', 'code', 'name', 'isSystem'] as const

export type IRoleSortField = typeof ROLE_PAGE_SORT_FIELDS[number]

type IUseSortRolePage = {
	sortBy: IRoleSortField;
	setSortBy: (value: (((prevState: IRoleSortField) => IRoleSortField) | IRoleSortField)) => void;
	order: ESortOrder;
	setOrder: (value: (((prevState: ESortOrder) => ESortOrder) | ESortOrder)) => void;
}

const DEFAULT_SORT_BY: IRoleSortField = 'id'
const DEFAULT_SORT_ORDER: ESortOrder = ESortOrder.desc
export const useSortRolePage = (): IUseSortRolePage => {
	const [searchParams, setSearchParams] = useSearchParams()

	const urlSort = (() => {
		try {
			return lib.sortSearchParams(searchParams.get('s')) ?? []
		} catch (error) {
			console.error(error)
			return []
		}
	})().filter((item) => {
		const sortOrderValues = Object.values(ESortOrder)

		return ROLE_PAGE_SORT_FIELDS.includes(item[0] as IRoleSortField) && sortOrderValues.includes(item[1] as ESortOrder)
	})

	const [sortBy, setSortBy] = useState<IRoleSortField>(urlSort?.[0]?.[0] as IRoleSortField ?? DEFAULT_SORT_BY)
	const [order, setOrder] = useState<ESortOrder>(urlSort?.[0]?.[1] as ESortOrder ?? DEFAULT_SORT_ORDER)
	const [send, setSend] = useState<boolean>(false)
	//console.log('useSortRolePage:: ', { sortUrl: urlSort })

	//TODO: check the logic [and in useFiltersRolePage too] !!!
	useEffect(() => {
		if (send) {
			setSortBy(DEFAULT_SORT_BY)
			setOrder(DEFAULT_SORT_ORDER)
		}
	}, [])

	useEffect(() => {
		if (sortBy && order) {
			setSend(true)

			const newSortStr = lib.sortSetup([[sortBy, order]])

			setSearchParams((prev) => {
				if (order !== ESortOrder.unset) {
					prev.set('s', newSortStr)
				} else {
					prev.delete('s')
				}

				return prev
			})
		}
	}, [sortBy, order])

	return {
		// sort: order !== ESortOrder.unset ? [[sortBy, order]] : [],
		sortBy,
		setSortBy,
		order,
		setOrder
	}
}