import { atom, selector } from 'recoil'
import uniqid from 'uniqid'

import { IAccount } from '../types'

const key = uniqid()
const profileAtom = atom<IAccount | null>({
	key: `profile:atom:${key}`,
	default: null,
})

export const profile = selector<IAccount | null>({
	key: `profile:selector:${key}`,
	get: ({ get }) => {
		return get(profileAtom)
	},
	set: ({ set }, value) => {
		return set(profileAtom, value)
	},
})
