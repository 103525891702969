import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

import { TRoleAccess } from '../../types'
import { ERoleAccess } from '../../types/enum'

type IRoleAccessFieldsProps = {
	rules: TRoleAccess[];
	setRules: React.Dispatch<React.SetStateAction<TRoleAccess[]>>;
}

const AccessRule = styled.div`
	padding: 0px 12px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 6px;
`

const MyControl = styled(Form.Control)`
	display: inline-flex;
	height: unset;
	padding: 2px 4px !important;
	width: 16em;
`

const MyCheck = styled(Form.Check)`
	display: inline-flex;
`

export const RoleAccessFields = (
	props: IRoleAccessFieldsProps
) => {
	const { rules, setRules } = props

	return (
		<>
			<AccessRule>
				<MyCheck
					id={'access-any'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.any)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.any
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-any'}>Any</Form.Label>
			</AccessRule>

			<br />

			<AccessRule>
				<MyCheck
					id={'access-login'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.authLogin)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.authLogin
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-login'}>Login</Form.Label>
			</AccessRule>
			<AccessRule>
				<MyCheck
					id={'access-profile'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.authProfile)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.authProfile
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-profile'}>Profile</Form.Label>
			</AccessRule>

			<br />

			<AccessRule>
				<MyCheck
					id={'access-role-create'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.roleCreate)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.roleCreate
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-role-create'}>Role create</Form.Label>
			</AccessRule>
			<AccessRule>
				<MyCheck
					id={'access-role-list'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.roleList)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.roleList
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-role-list'}>Role list</Form.Label>
			</AccessRule>
			<AccessRule>
				<MyCheck
					id={'access-role-modify'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.roleModify)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.roleModify
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-role-modify'}>Role modify</Form.Label>
			</AccessRule>
			{/* <AccessRule>
				<MyCheck
					id={'access-role-read'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.roleRead)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.roleRead
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-role-read'}>Role read</Form.Label>
			</AccessRule> */}
			<AccessRule>
				<MyCheck
					id={'access-role-remove'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.roleRemove)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.roleRemove
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-role-remove'}>Role remove</Form.Label>
			</AccessRule>

			<br />

			<AccessRule>
				<MyCheck
					id={'access-account-create'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.accountCreate)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.accountCreate
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-account-create'}>Account create</Form.Label>
			</AccessRule>
			<AccessRule>
				<MyCheck
					id={'access-account-list'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.accountList)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.accountList
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-account-list'}>Account list</Form.Label>
			</AccessRule>
			<AccessRule>
				<MyCheck
					id={'access-account-modify'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.accountModify)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.accountModify
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-account-modify'}>Account modify</Form.Label>
			</AccessRule>
			{/* <AccessRule>
				<MyCheck
					id={'access-account-read'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.accountRead)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.accountRead
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-account-read'}>Account read</Form.Label>
			</AccessRule> */}
			<AccessRule>
				<MyCheck
					id={'access-account-remove'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.accountRemove)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.accountRemove
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-account-remove'}>Account remove</Form.Label>
			</AccessRule>

			<br />

			<AccessRule>
				<MyCheck
					id={'access-offer-bind'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.offerBind)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.offerBind
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-offer-bind'}>Offer bind</Form.Label>
			</AccessRule>
			<AccessRule>
				<MyCheck
					id={'access-offer-list'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.offerList)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.offerList
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-offer-list'}>Offer list</Form.Label>
				<MyControl
					id={'access-offer-list-group'}
					type="text"
					placeholder="Group"
					value={(rules.find((rule) => rule.$key === ERoleAccess.offerList) as Extract<TRoleAccess, { $key: ERoleAccess.offerList }> | undefined)?.group ?? ''}
					onChange={(e) => {
						const $key = ERoleAccess.offerList
						const value: string = e.target.value
						const rule: TRoleAccess | null = rules.find((rule) => rule.$key === $key) ?? null
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const ruleNew: Extract<TRoleAccess, { $key: ERoleAccess.offerList }> = { $key, $value: rule?.$value ?? false, ...value && { group: value } }
						setRules([ruleNew, ...rulesTail])
					}}
				/>
			</AccessRule>
			<AccessRule>
				<MyCheck
					id={'access-offer-unbind'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.offerUnbind)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.offerUnbind
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-offer-unbind'}>Offer unbind</Form.Label>
			</AccessRule>

			<br />

			<AccessRule>
				<MyCheck
					id={'access-offer-scope-create'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.offerScopeCreate)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.offerScopeCreate
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-offer-scope-create'}>Offer scope create</Form.Label>
			</AccessRule>
			<AccessRule>
				<MyCheck
					id={'access-offer-scope-list'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.offerScopeList)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.offerScopeList
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-offer-scope-list'}>Offer scope list</Form.Label>
				<MyControl
					id={'access-offer-scope-list-group'}
					type="text"
					placeholder="Group"
					value={(rules.find((rule) => rule.$key === ERoleAccess.offerScopeList) as Extract<TRoleAccess, { $key: ERoleAccess.offerScopeList }> | undefined)?.group ?? ''}
					onChange={(e) => {
						const $key = ERoleAccess.offerScopeList
						const value: string = e.target.value
						const rule: TRoleAccess | null = rules.find((rule) => rule.$key === $key) ?? null
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const ruleNew: Extract<TRoleAccess, { $key: ERoleAccess.offerScopeList }> = { $key, $value: rule?.$value ?? false, ...value && { group: value } }
						setRules([ruleNew, ...rulesTail])
					}}
				/>
			</AccessRule>
			<AccessRule>
				<MyCheck
					id={'access-offer-scope-modify'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.offerScopeModify)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.offerScopeModify
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-offer-scope-modify'}>Offer scope modify</Form.Label>
			</AccessRule>
			{/* <AccessRule>
				<MyCheck
					id={'access-offer-scope-read'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.offerScopeRead)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.offerScopeRead
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-offer-scope-read'}>Offer scope read</Form.Label>
			</AccessRule> */}
			<AccessRule>
				<MyCheck
					id={'access-offer-scope-remove'}
					type="checkbox"
					checked={rules.find((rule) => rule.$key === ERoleAccess.offerScopeRemove)?.$value ?? false}
					onChange={(e) => {
						const $key = ERoleAccess.offerScopeRemove
						const checked: boolean = e.target.checked
						const rulesTail: TRoleAccess[] = rules.filter((rule) => rule.$key !== $key)
						const rule: TRoleAccess = { $key, $value: checked }
						setRules([rule, ...rulesTail])
					}}
				/>
				<Form.Label style={{ marginBottom: 0 }} htmlFor={'access-offer-scope-remove'}>Offer scope remove</Form.Label>
			</AccessRule>
		</>
	)
}
