import { atom, selector } from 'recoil'
import uniqid from 'uniqid'
import { ICommonCountry } from '../types'

const key = uniqid()
const countriesAtom = atom<Array<ICommonCountry> | null>({
	key: `countries:atom:${key}`,
	default: null
})
export const countryOptions = selector<Array<ICommonCountry> | null>({
	key: `countries:selector:${key}`,
	get: ({ get }) => {
		return get(countriesAtom)
	},
	set: ({ set }, value) => {
		return set(countriesAtom, value)
	}
})