import React, { useState } from 'react'
import styled from 'styled-components'
import { IconMoreLine, IconSpace } from './UiIcons'

const Agg = styled.div`
    box-sizing: border-box;
    margin: 0;
    padding: 3px 0 !important;

    &:not(:last-child)::after {
        content: ', ';
    }
`

export const UiMoreTextInfo = ({ value }: { value: Array<string | null> }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const limit = 2

	if (!(value && value.length)) {
		return null
	}

	const valueFiltered = value.filter((item) => !!item?.trim())

	return <div>
		{
			valueFiltered.slice(0, limit).map((_, i) => {
				return !!_ && <Agg key={i}>{_}</Agg>
			})
		}

		{
			valueFiltered.length > limit && !isOpen ? (
				<>
					<button
						//className="icon remix-icon border-0 rounded-circle text-center bg-primary-transparent"
						className="more-text-button remix-icon border-0 text-center bg-transparent"
						onClick={(event: React.MouseEvent) => setIsOpen(true)}
					>
						<IconMoreLine/>
					</button>
				</>
			) : null
		}

		{
			valueFiltered.length > limit && isOpen ? (
				valueFiltered.slice(limit, valueFiltered.length).map((_, i) => {
					return !!_ && <Agg key={i}>{_}</Agg>
				})
			) : null
		}

		{
			valueFiltered.length > limit && isOpen ? (
				<button className="more-text-button remix-icon border-0 text-center bg-transparent"
				        onClick={(event: React.MouseEvent) => setIsOpen(false)}
				>
					<IconSpace/>
				</button>
			) : null
		}
	</div>
}