

export const OfferNameGenerationPage = () => {
	return (
		<>
			OfferNameGenerationPage
		</>
	)

}
