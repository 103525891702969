import React, { useState, useEffect, useRef } from 'react'
import { IconNotes } from '../ui'
import { IOfferScope } from '../../types'
import styled from 'styled-components'
import * as lib from '../../lib'
import * as state from '../../states'
import { useRecoilValue, useSetRecoilState } from 'recoil'

export type IPopUpPosition = 'top' | 'middle' | 'bottom';

type IOfferScopeNotesField = {
	value: string | null;
	rowData: IOfferScope;
	popUpPosition?: IPopUpPosition;
	isViewMode?: boolean
	onClose?: () => void;
	onSave?: () => void;
}

const NotesFieldStyled = styled.div`
    &.offer-scope-notes-field {
        text-align: center;
        position: relative;

        .icon-notes {
            cursor: pointer;
            padding: 7px;
        }

        .pop-up {
            position: absolute;
            z-index: 1;
            left: 100%;

            background-color: #fff;
            padding: 10px 20px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            border-radius: var(--borderRadius);

            p {
                margin-bottom: 15px;
            }

            .textarea-field {
                width: 300px;
                height: 150px !important;
                resize: none;
            }

            &:before {
                --triangle-size: 10px;

                content: '';
                position: absolute;
                right: 100%;

                width: 0;
                height: 0;
                border-top: var(--triangle-size) solid transparent;
                border-bottom: var(--triangle-size) solid transparent;

                border-right: var(--triangle-size) solid rgba(0, 0, 0, 0.2);
            }
        }

        .pop-up--top {
            top: -50%;
            transform: translate(5px, 0);

            &:before {
                top: 0;
                transform: translateY(10px);
            }
        }

        .pop-up--middle {
            top: 50%;
            transform: translate(5px, -50%);

            &:before {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .pop-up--bottom {
            bottom: -50%;
            transform: translate(5px, 0);

            &:before {
                bottom: 0;
                transform: translateY(-10px);
            }
        }

        .pop-up-buttons {
            .btn {
                margin: 15px 15px 5px 15px;
                padding: 7px 15px;
                font-size: var(--fontSize14);
                font-weight: 500;
                line-height: 1;
            }

            .btn:disabled {
                opacity: 0.35;
            }
        }
`

export const OfferScopeNotesField = (props: IOfferScopeNotesField) => {
	const {
		value,
		rowData,
		isViewMode = false,
		popUpPosition,
		onClose,
		onSave
	} = props
	const [prevInnerVal, setPrevInnerVal] = useState<string>(value ?? '')
	const [innerVal, setInnerVal] = useState<string>(value ?? '')
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)
	const tokens = useRecoilValue(state.tokens)
	const access = tokens?.access ?? null

	const onUpdateHandler = async () => {
		const uri: string = `offer/scope/${rowData?.id}`
		const method: string = 'PATCH'
		const params: lib.FetchOptions['params'] = {}
		const headers = lib.bearer(access)
		const body: string = JSON.stringify({
			notes: innerVal
		})
		const options: lib.FetchOptions = { body, method, params, headers }

		try {
			setFetchIsLoading(state.turnOnLoader)
			await lib.fetching<void>(uri, options)
			setPrevInnerVal(innerVal)
			setIsOpen(false)
			onSave?.() //TODO: check
		} catch (error: unknown) {
			// TODO: pretty notification alert
			console.error(error)
		} finally {
			setFetchIsLoading(state.turnOffLoader)
		}
	}

	const onCloseHandler = () => {
		setInnerVal(prevInnerVal)
		setIsOpen(false)
		onClose?.()  //TODO: check
	}

	return <NotesFieldStyled className={'offer-scope-notes-field'}>
		<i className={'icon-notes'} style={{ color: prevInnerVal.length > 0 ? '#5d87ff' : 'black' }}
		   onClick={() => setIsOpen(true)}
		>
			<IconNotes/>
		</i>

		{isOpen ? (
			<NotesPopUp rowData={rowData}
			            textAreaVal={innerVal}
			            setTextAreaVal={setInnerVal}
			            onClose={onCloseHandler}
			            onUpdate={onUpdateHandler}
			            isTextAreaDisabled={prevInnerVal === innerVal}
			            isViewMode={isViewMode}
			            position={popUpPosition}
			/>
		) : null}

	</NotesFieldStyled>
}

type INotesPopUp = {
	rowData: IOfferScope;
	textAreaVal: string;
	setTextAreaVal: (value: string) => void;
	onClose: () => void;
	onUpdate: () => void;
	isTextAreaDisabled: boolean;
	isViewMode: boolean;
	position?: IPopUpPosition;
}
const NotesPopUp = (props: INotesPopUp) => {
	const {
		rowData,
		textAreaVal,
		setTextAreaVal,
		onClose,
		onUpdate,
		isTextAreaDisabled,
		isViewMode,
		position = 'middle'
	} = props

	const popUpRef = useRef<HTMLDivElement>(null)
	const [isFirstRenderFlag, setIsFirstRenderFlag] = useState<boolean>(true)

	const onOutsideClickHandler = (event: MouseEvent) => {
		if (popUpRef.current && popUpRef.current.contains(event.target as HTMLDivElement)) {
			// in popUpRefTarget - return
			return
		}
		onClose()
	}

	useEffect(() => {
		if (!isFirstRenderFlag) {
			document.addEventListener('click', onOutsideClickHandler)

			return () => {
				document.removeEventListener('click', onOutsideClickHandler)
			}
		} else {
			setIsFirstRenderFlag(false)
		}
	}, [isFirstRenderFlag])

	return (
		<div className={`pop-up pop-up--${position}`} ref={popUpRef}>
			<p className={'h6'}>{`Notes for scope #${rowData?.id}`}</p>

			<textarea name="notes"
			          id={`scope-notes-${rowData?.id}`}
			          className={'form-control textarea-field'}
			          value={textAreaVal}
			          disabled={isViewMode}
			          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setTextAreaVal(e.target.value)}
			/>

			<div className="pop-up-buttons">
				<button type="button"
				        className="btn btn-secondary"
				        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClose()}
				>
					Close
				</button>

				{
					!isViewMode ? (
						<button type="button"
						        className="btn btn-primary"
						        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onUpdate()}
						        disabled={isTextAreaDisabled}
						>
							Save
						</button>
					) : null
				}

			</div>
		</div>
	)
}