import { atom, selector } from 'recoil'
import uniqid from 'uniqid'

const key: string = uniqid()

type IFetchState = number

const fetchAtom = atom<IFetchState>({
	key: `fetch:atom:${key}`,
	default: 0
})

export const fetchIsLoading = selector<IFetchState>({
	key: `fetch:isLoading:selector:${key}`,
	get: ({ get }) => {
		return get(fetchAtom)
	},
	set: ({ set }, value) => {
		if (+value >= 0) {
			return set(fetchAtom, value)
		}
		throw new Error('Incorrect value set')
	}
})

// smth like 'action-creators' from redux
export const turnOnLoader = (currVal: number) => currVal + 1
export const turnOffLoader = (currVal: number) => currVal - 1