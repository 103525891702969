import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import * as fetchs from '../fetchs'
import * as state from '../states'
import { INoFetch, IFetchAuthLogin } from '../types'
import { setStorageToken } from './hook.useAppInit'
import { toast } from 'react-toastify'

type IUseAuthLogin = {
	error: INoFetch | null;
	profile: IFetchAuthLogin['profile'] | null;
	tokens: IFetchAuthLogin['token'] | null;
	ip: string | null;
	warmUp: () => void;
}

export const useAuthLogin = (
	login: string,
	password: string,
	send: boolean
): IUseAuthLogin => {
	const setTokens = useSetRecoilState(state.tokens)
	const setProfile = useSetRecoilState(state.profile)
	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)

	const [error, setError] = useState<INoFetch | null>(null)
	const [data, setData] = useState<IFetchAuthLogin | null>(null)
	const [warmUpIt, setWarmUpIt] = useState<number>(0)

	const warmUp = () => setWarmUpIt(warmUpIt + 1)

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal

		const call = async () => {
			try {
				setFetchIsLoading(state.turnOnLoader)
				const data: IFetchAuthLogin | null = await fetchs.loginAuth(login, password, signal)
				setError(null)
				setData(data ? data : null)
				data && setProfile(data.profile)
				data && setTokens(data.token)
				data && setStorageToken(data.token)
				toast.success(
					`Welcome back, ${data?.profile?.email ?? login}!`,
					{
						autoClose: 5000,
						hideProgressBar: false
					}
				)
			} catch (error: unknown) {
				console.error(error)
				setError(error as INoFetch)
				setData(null)
				setProfile(null)
				setTokens({})
				setStorageToken(null)
			} finally {
				setFetchIsLoading(state.turnOffLoader)
			}
		}

		send && call()

		return () => {
			controller.abort()
		}
	}, [
		send,
		warmUpIt,
		setProfile,
		setTokens,
		setError,
		setData
	])

	return {
		error,
		profile: data?.profile ?? null,
		tokens: data?.token ?? null,
		ip: data?.ip ?? null,
		warmUp
	}
}
