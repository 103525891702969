import { Outlet } from 'react-router'

import { SideMenu, Header } from '../../components'
import { useCommonFetchs } from '../../hooks'

export const AuthLayout = () => {
	const { errorCountry, errorAffiliateNetwork, warmUp } = useCommonFetchs()

	return <>
		<SideMenu/>

		<div className="main-content-area" style={{
			justifyContent: 'flex-start'
		}}>
			<div className="container-fluid">
				{/*TODO: comment now Header before push to the DEV/MAIN/PROD !*/}
				{/*<Header />*/}

				<Outlet/>
			</div>
		</div>
	</>
}
