import { atom, selector } from 'recoil'
import uniqid from 'uniqid'
import { IFetchListOfferGroup } from '../types'

const key = uniqid()
const groupsAtom = atom<IFetchListOfferGroup['items'] | null>({
	key: `groups:atom:${key}`,
	default: null
})

export const offerGroups = selector<IFetchListOfferGroup['items'] | null>({
	key: `groups:selector:${key}`,
	get: ({ get }) => {
		return get(groupsAtom)
	},
	set: ({ set }, value) => {
		return set(groupsAtom, value)
	}
})