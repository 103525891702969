import * as lib from '../lib'
import { IFetchListOfferGroup } from '../types'

export const listOfferGroup = async (
	signal: AbortSignal,
	token?: string | null,
): Promise<IFetchListOfferGroup> => {
	const headers = lib.bearer(token)
	const method: string = 'GET'
	const params: lib.FetchOptions['params'] = {}
	const uri: string = 'offer/group?take=all'

	const options: lib.FetchOptions = {
		headers,
		method,
		params,
		signal,
	}
	const data = await lib.fetching<IFetchListOfferGroup>(uri, options)

	return {
		items: data?.items ?? [],
		total: data?.items?.length ?? 0
	}
}
