import {ISelectOption} from '../types/ui'
import {EAccountIsActive, EAccountIsEmailVerified, EOfferScopeCapType, EOfferScopeStatus, ERoleAccessIsAny, ERoleIsSystem} from '../types/enum'
import {CapTypeIcon} from '../components/ui'
import {STATUS_COLORS_MAP} from './constant.statusColorsMap'
import {IS_SYSTEM_COLORS_MAP} from './constant.isSystemColorsMap'
import {ACCESS_IS_ANY_COLORS_MAP} from './constant.accessIsAnyColorsMap'
import {IS_EMAIL_VERIFIED_COLORS_MAP} from './constant.isEmailVerifiedColorsMap'
import {IS_ACTIVE_COLORS_MAP} from './constant.isActiveColorsMap'

export const CAP_TYPE_OPTIONS: ISelectOption<EOfferScopeCapType>[] = Object.entries(EOfferScopeCapType).map(([key, value]) => {
	return (
		{
			value,
			label: (
				<div><CapTypeIcon value={value}/> {value}</div>
			)
		}
	)
})

export const STATUS_OPTIONS: ISelectOption<EOfferScopeStatus>[] = Object.entries(EOfferScopeStatus).map(([key, value]) => {
	return (
		{
			value,
			label: (
				<span
					className={`badge text-white fw-normal py-1 px-2 fs-12 rounded-1 edit ${STATUS_COLORS_MAP?.[value]}`}
				>
					{value}
				</span>
			)
		}
	)
})

export const IS_SYSTEM_OPTIONS: ISelectOption<ERoleIsSystem>[] = Object.entries(ERoleIsSystem).map(([key, value]) => {
	return (
		{
			value,
			label: (
				<span
					className={`badge text-white fw-normal py-1 px-2 fs-12 rounded-1 edit ${IS_SYSTEM_COLORS_MAP?.[value]}`}
				>
					{value}
				</span>
			)
		}
	)
})

export const ACCESS_IS_ANY_OPTIONS: ISelectOption<ERoleAccessIsAny>[] = Object.entries(ERoleAccessIsAny).map(([key, value]) => {
	return (
		{
			value,
			label: (
				<span
					className={`badge text-white fw-normal py-1 px-2 fs-12 rounded-1 edit ${ACCESS_IS_ANY_COLORS_MAP?.[value]}`}
				>
					{value}
				</span>
			)
		}
	)
})

export const IS_EMAIL_VERIFIED_OPTIONS: ISelectOption<EAccountIsEmailVerified>[] = Object.entries(EAccountIsEmailVerified).map(([key, value]) => {
	return (
		{
			value,
			label: (
				<span
					className={`badge text-white fw-normal py-1 px-2 fs-12 rounded-1 edit ${IS_EMAIL_VERIFIED_COLORS_MAP?.[value]}`}
				>
					{value}
				</span>
			)
		}
	)
})

export const IS_ACTIVE_OPTIONS: ISelectOption<EAccountIsActive>[] = Object.entries(EAccountIsActive).map(([key, value]) => {
	return (
		{
			value,
			label: (
				<span
					className={`badge text-white fw-normal py-1 px-2 fs-12 rounded-1 edit ${IS_ACTIVE_COLORS_MAP?.[value]}`}
				>
					{value}
				</span>
			)
		}
	)
})
