import React, { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router'

import {
	OfferScopeCreateModal,
	OfferViewListModal,
	OfferScopeListTable,
	OfferScopeRemoveModal,
	OfferScopeEditModal,
	OfferScopeEditNotesModal,
	UiPagination,
	ToolPanel,
	OfferUnscopedListModal,
	OfferUnbindListModal,
	OfferBindListModal,
	IconPlus,
	UiNotification,
	ESortOrder
} from '../components'
import { useOfferList, useOfferScopeList, useFiltersOfferScopePage, useSortOfferScopePage } from '../hooks'
import * as lib from '../lib'
import { IFetchItemsSetup, IOfferScope } from '../types'
import { TAKE } from '../constants'
import { Filters, FiltersButtonIcon } from '../components/filters'
import { EFilterOperator } from '../types/enum'
import { useRecoilValue } from 'recoil'
import * as state from '../states'
import { Access } from '../lib'


export const OfferScopePage = (): JSX.Element => {
	const profile = useRecoilValue(state.profile)
	const $access = profile ? new Access(profile) : null

	//TODO: REMOVE CRUTCH - ACCESS FOR TEAM-X-USERS'
	const isTeam = useRecoilValue(state.isTeam)
	const profileRole = profile?.role

	const location = useLocation()
	const href = lib.pageHref(location, TAKE)
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()

	const page = lib.skipSearchParams(searchParams.get('page')) ?? 0
	const take = lib.takeSearchParams(searchParams.get('take')) ?? TAKE
	const skip = (take === 'all') ? 0 : page > 0 ? page * take : 0
	//TODO: REMOVE CRUTCH - ACCESS FOR TEAM-X-USERS'
	//const group = lib.groupSearchParams(searchParams.get('group') ?? '')
	const groups = searchParams.get('group')?.split(',')?.filter(_ => !!_)
	const group = isTeam.length && isTeam.length === 1 ?
		(isTeam.at(0) ?? '') :
		lib.groupSearchParams(searchParams.get('group') ?? '')
	//console.log('OfferScopePage:: ', {group, isTeam, profileRole})

	const isOfferScopePageIndex = !group

	const {
		filter,
		send,
		activeFiltersCount,
		filterFillFactor,
		isFiltersPanelOpen,
		setIsFiltersPanelOpen,
		offerScopeFiltersConfig,
		defaultFiltersValue,
		initialFiltersValue,
		clearChildFilters,
		setUserFilters
	} = useFiltersOfferScopePage(group)

	//TODO: check sort output var type ?
	const {
		// sort: sortTmp,
		sortBy,
		setSortBy,
		order,
		setOrder
	} = useSortOfferScopePage(group)

	const setup: IFetchItemsSetup = {
		filter,
		sort: order !== ESortOrder.unset ? [[sortBy, order]] : [],
		skip,
		take
	}
	const { error, items, total, warmUp } = useOfferScopeList(setup, send)

	const setup2: IFetchItemsSetup = {
		filter: [[`scopeId${EFilterOperator.isNull}`, null]],
		sort: [],
		skip: 0,
		take: 0
	}

	if (group) {
		setup2.filter.push([`group${EFilterOperator.isEqual}`, group])
	}

	const { total: total2, warmUp: warmUp2 } = useOfferList(setup2, true)

	const [viewUnscopedOffers, setViewUnscopedOffers] = useState<boolean>(false)
	const [createScope, setCreateScope] = useState<boolean>(false)
	const [editScope, setEditScope] = useState<IOfferScope | null>(null)
	const [editNotesScope, setEditNotesScope] = useState<IOfferScope | null>(null)
	const [removeScope, setRemoveScope] = useState<IOfferScope | null>(null)
	const [unbindScope, setUnbindScope] = useState<IOfferScope | null>(null)
	const [bindScope, setBindScope] = useState<IOfferScope | null>(null)
	const [viewScope, setViewScope] = useState<IOfferScope | null>(null)

	const onViewUnscopedOffers = (e: React.MouseEvent) => {
		e.preventDefault()
		setViewUnscopedOffers(true)
	}
	const onCreate = (e: React.MouseEvent) => {
		e.preventDefault()
		setCreateScope(true)
	}
	const onUnbind = (item: IOfferScope, e: React.MouseEvent) => {
		e.preventDefault()
		// navigate(href(0, `/offer?scope=${item.id}`).tail)
		setUnbindScope(item)
	}
	const onBind = (item: IOfferScope, e: React.MouseEvent) => {
		e.preventDefault()
		setBindScope(item)
	}
	const onEdit = (item: IOfferScope, e: React.MouseEvent) => {
		e.preventDefault()
		setEditScope(item)
	}

	const onEditNotes = (item: IOfferScope, e: React.MouseEvent) => {
		e.preventDefault()
		setEditNotesScope(item)
	}
	const onRemove = (item: IOfferScope, e: React.MouseEvent) => {
		e.preventDefault()
		setRemoveScope(item)
	}

	const onViewScope = (item: IOfferScope, e: React.MouseEvent) => {
		e.preventDefault()
		setViewScope(item)
	}

	const UnscopedNotification = () => {
		if (total2 <= 0) return null

		const notification = () => {
			return (
				<>
				<span className={'unscoped-offers-link'}
				      onClick={onViewUnscopedOffers}
				>
					{total2} offers
				</span>
					&nbsp;
					are unscoped
				</>
			)
		}

		return <UiNotification text={notification()}/>
	}

	return (
		<>
			<div className="section-title mt-25 mb-24 text-center">
				<UnscopedNotification/>
			</div>

			<div className="card rounded-3 border-0 mb-24">
				<div className="card-body p-25">
					{/* remove CREATE button for 'offer/scope' main page (without pre-selected group) */}
					<div className="d-flex align-items-center justify-content-between pb-20 border-bottom border-color">
						<FiltersButtonIcon
							activeFiltersCount={activeFiltersCount}
							filterFillFactor={filterFillFactor}
							onClick={() => setIsFiltersPanelOpen((val) => !val)}
						/>

						{
							!!onCreate && !!$access?.offerScopeCreate && !isOfferScopePageIndex // !!! && !isTeam.length
								? (
									<button
										type="button"
										className="btn btn-primary d-flex align-items-center"
										onClick={onCreate}
									>
										<IconPlus/>
										<span className="d-none d-lg-block">Add New</span>
									</button>
								)
								: null
						}
					</div>

					<Filters
						config={offerScopeFiltersConfig}
						initialFiltersValue={initialFiltersValue}
						resetFiltersValue={defaultFiltersValue}
						isAutoModeWithDebounce={false}
						isFiltersPanelOpen={isFiltersPanelOpen}
						isFiltersToClear={clearChildFilters}
						setFiltersCB={(value) => {
							//console.log('page.OfferScopePage::setFiltersCB::', { value })
							setUserFilters(value)
						}}
					/>

					<OfferScopeListTable
						items={items}
						skip={skip}
						sortBy={sortBy}
						setSortBy={setSortBy}
						order={order}
						setOrder={setOrder}
						onView={onViewScope}
						onBind={onBind}
						onUnbind={onUnbind}
						onEdit={onEdit}
						onEditNotesScope={onEditNotes}
						onRemove={onRemove}
					/>

					<ToolPanel flow="<-">
						<UiPagination
							classesToAdd={{
								wrapper: 'mt-25'
							}}
							page={page}
							take={take}
							total={total}
							href={(page: number) => href(page).tail}
						/>
					</ToolPanel>
				</div>
			</div>

			{
				viewUnscopedOffers && !!$access?.offerList && <OfferUnscopedListModal
					group={group}
					show={viewUnscopedOffers}
					take={take}
					onClose={() => {
						setViewUnscopedOffers(false)
						// navigate(href(0).tail)
						warmUp()
						warmUp2()
					}}
				/>
			}

			{
				createScope && !!$access?.offerScopeCreate && <OfferScopeCreateModal
					show={createScope}
					onClose={() => setCreateScope(false)}
					onSave={() => {
						setCreateScope(false)
						navigate(href(0).tail)
						warmUp()
						warmUp2()
					}}
				/>
			}

			{
				!!viewScope && !!$access?.offerList && <OfferViewListModal
					scope={viewScope}
					show={!!viewScope}
					take={take}
					onClose={() => {
						setViewScope(null)
						// navigate(href(0).tail)
						warmUp()
						warmUp2()
					}}
				/>
			}

			{
				!!editScope && !!$access?.offerScopeModify && <OfferScopeEditModal
					item={editScope}
					show={!!editScope}
					onClose={() => setEditScope(null)}
					onSave={() => {
						setEditScope(null)
						navigate(href(0).tail)
						warmUp()
						warmUp2()
					}}
				/>
			}

			{
				!!removeScope && !!$access?.offerScopeRemove && <OfferScopeRemoveModal
					item={removeScope}
					show={!!removeScope}
					onClose={() => setRemoveScope(null)}
					onSave={() => {
						setRemoveScope(null)
						navigate(href(0).tail)
						warmUp()
						warmUp2()
					}}
				/>
			}

			{
				!!bindScope && !!$access?.offerBind && <OfferBindListModal
					scope={bindScope}
					show={!!bindScope}
					take={take}
					onClose={() => {
						setBindScope(null)
						// navigate(href(0).tail)
						warmUp()
						warmUp2()
					}}
				/>
			}

			{
				!!unbindScope && !!$access?.offerUnbind && <OfferUnbindListModal
					scope={unbindScope}
					show={!!unbindScope}
					take={take}
					onClose={() => {
						setUnbindScope(null)
						// navigate(href(0).tail)
						warmUp()
						warmUp2()
					}}
				/>
			}

			{
				!!editNotesScope && !!$access?.offerScopeModify && <OfferScopeEditNotesModal
					isViewMode={isTeam.length > 0}
					item={editNotesScope}
					show={!!editNotesScope}
					onClose={() => setEditNotesScope(null)}
					onSave={() => {
						setEditNotesScope(null)
						//navigate(href(0).tail)
						warmUp()
						warmUp2()
					}}
				/>
			}
		</>
	)
}
