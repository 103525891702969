import React, { useState, useEffect, useRef } from 'react'

import { DEBOUNCE_TIME_PROTECTION } from '../../constants'
import { FilterInput } from './FilterInput'
import { FilterSelectMultiple } from './FilterSelectMultiple'
import { FilterSelectSingle } from './FilterSelectSingle'
import { IconFilter, IconCross } from '../ui'

import { IFilterProps, IFilterConfig } from '../../types'
import { EFilterType, EFilterFetchConvertType } from '../../types/enum'


export function Filters<T>(props: IFilterProps<T>) {
	const {
		config,
		initialFiltersValue,
		resetFiltersValue,
		classesToAdd,
		isAutoModeWithDebounce = false,
		isFiltersPanelOpen,
		isFiltersToClear,
		setFiltersCB
	} = props

	const [filter, setFilter] = useState<T>(initialFiltersValue)

	const clearAllFilters = () => {
		setFilter(resetFiltersValue)
		// 'auto' mode onEachFilterItem is changed --- without 'applyBtnClick' ???
		// unnecessary if ['auto' mode - with debounce logic] is active
		/* ['simple-manual' mode - without debounce logic] */
		if (!isAutoModeWithDebounce) {
			setFiltersCB(resetFiltersValue)
		}
	}

	const updateParentFiltersState = () => {
		setFiltersCB(filter)
	}

	// clearAllFilters if isFiltersToClear command-flag is changed (from parent props)
	useEffect(() => {
		if (isFiltersToClear) {
			clearAllFilters()
		}
	}, [isFiltersToClear])

	/* ['simple-manual' mode - without debounce logic] */
	useEffect(() => {
		// initial filter data pass to the UP component
		if (!isAutoModeWithDebounce) {
			updateParentFiltersState()
		}
	}, [])

	/* ['auto' mode - with debounce logic] */
	const debounceFiltersTimer = useRef(0)
	useEffect(() => {
		if (isAutoModeWithDebounce) {
			if (!debounceFiltersTimer.current) {
				// initial filter data pass to the UP component
				updateParentFiltersState()
				debounceFiltersTimer.current++
			} else {
				debounceFiltersTimer.current = setTimeout((() => {
					updateParentFiltersState()
				}) as TimerHandler, DEBOUNCE_TIME_PROTECTION)
			}
			return () => {
				return clearTimeout(debounceFiltersTimer.current)
			}
		}
	}, [filter])

	const getFilterComponentByConfig = (configItem: IFilterConfig<keyof T>) => {
		const configItemName = configItem.name
		const configItemNameStr = String(configItemName)
		const configItemLabel = configItem.label
		const configItemType = configItem.type
		const configItemStyles = configItem?.styles ?? {}
		const configItemIsClearable = configItem?.isClearable ?? true
		const configItemIsDisabled = configItem?.isDisabled ?? false
		const configItemIsSearchable = configItem?.isSearchable
		const configItemFetchConvertType = configItem?.fetchConvertType

		switch (configItemType) {
			case EFilterType.input: {
				return (
					<FilterInput
						label={configItemLabel}
						styles={{ ...configItemStyles }}
						isClearable={configItemIsClearable}
						isDisabled={configItemIsDisabled}
						isIconSearchVisible={true}
						value={filter[configItemName] as string}
						setValue={(value) => {
							let convertValue: string | number | number[] | null = value
							//TODO: rewrite - implement separate [via configItem] validation for each FilterType or field
							if (configItemFetchConvertType === EFilterFetchConvertType.number) {
								convertValue = parseInt(value) > 0 ? parseInt(value) : null
							} else if (configItemFetchConvertType === EFilterFetchConvertType.numberArray) {
								convertValue = parseInt(value) > 0 ? [parseInt(value)] : null
							}

							setFilter((prev) => ({
								...prev,
								[configItemName]: convertValue
							}))
						}}
					/>
				)
			}
			case EFilterType.selectSingle: {
				return (
					<FilterSelectSingle
						label={configItemLabel}
						styles={{ minWidth: '200px', ...configItemStyles }}
						isClearable={configItemIsClearable}
						isSearchable={configItemIsSearchable ?? false}
						isDisabled={configItemIsDisabled}
						value={filter[configItemName] as string | null}
						options={configItem?.options ?? []}
						setValue={(value) => {
							setFilter((prev) => ({
								...prev,
								[configItemName]: value
							}))
						}}
					/>
				)
			}
			case EFilterType.selectMultiple: {
				return (
					<FilterSelectMultiple
						label={configItemLabel}
						styles={{ minWidth: '200px', ...configItemStyles }}
						isClearable={configItemIsClearable}
						isSearchable={configItemIsSearchable ?? true}
						isDisabled={configItemIsDisabled}
						value={filter[configItemName] as string[] | null}
						options={configItem?.options ?? []}
						setValue={(value) => {
							setFilter((prev) => ({
								...prev,
								[configItemName]: value
							}))
						}}
					/>
				)
			}
			default: {
				throw new Error(`getFilterComponent for name='${configItemNameStr}' with type='${configItemType}' is NOT valid`)
			}
		}
	}

	const renderFiltersButtons = () => {
		return (
			<div className={`filters-button-cont ${classesToAdd?.filtersButton ?? ''}`}>
				<button
					type="button"
					className="clear-filter-button btn btn-secondary d-flex align-items-center"
					onClick={(event) => {
						clearAllFilters()
					}}
				>
					<IconCross/>
					{/*<span className="d-none d-lg-block" style={{ whiteSpace: 'nowrap' }}>Clear all</span>*/}
				</button>

				{
					!isAutoModeWithDebounce ? (
						<button
							type="button"
							className="apply-filter-button btn btn-primary d-flex align-items-center"
							onClick={(event) => {
								updateParentFiltersState()
							}}
						>
							<IconFilter/>
							<span className="d-none d-lg-block">Apply</span>
						</button>
					) : null
				}
			</div>
		)
	}

	return (
		<div className={`filters ${classesToAdd?.filters ?? ''}`}>
			<div className={`filters-panel ${isFiltersPanelOpen ? 'active' : ''} ${classesToAdd?.filtersPanel ?? ''}`}>
				<div className={`d-flex align-items-end pb-30 border-bottom border-color`}
				     style={{ paddingTop: '8px' }}
				>
					<div
						className={`filters-items-cont d-flex flex-wrap align-items-end justify-content-end`}
					>
						{config.map(configItem => {
							return <React.Fragment key={String(configItem.name)}>
								{getFilterComponentByConfig(configItem)}
							</React.Fragment>
						})}

						{renderFiltersButtons()}
					</div>
				</div>
			</div>
		</div>
	)
}