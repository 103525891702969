import { IAccount, IRole, Part, TRoleAccess } from '../types'
import { ERoleAccess } from '../types/enum'
import { IAccountAuthLocal } from '../types/interface.IAccountAuthLocal'

class Rule {
	constructor(
		private readonly access: Access
	) {}

	private $($key: ERoleAccess): TRoleAccess | null {
		return this.access.$($key)
	}

	get any(): Extract<TRoleAccess, { $key: ERoleAccess.any }> | null {
		return this.$(ERoleAccess.any) as Extract<TRoleAccess, { $key: ERoleAccess.any }> | null
	}

	get authLogin(): Extract<TRoleAccess, { $key: ERoleAccess.authLogin }> | null {
		return this.$(ERoleAccess.authLogin) as Extract<TRoleAccess, { $key: ERoleAccess.authLogin }> | null
	}
	
	get authProfile(): Extract<TRoleAccess, { $key: ERoleAccess.authProfile }> | null {
		return this.$(ERoleAccess.authProfile) as Extract<TRoleAccess, { $key: ERoleAccess.authProfile }> | null
	}

	// Role

	get roleCreate(): Extract<TRoleAccess, { $key: ERoleAccess.roleCreate }> | null {
		return this.$(ERoleAccess.roleCreate) as Extract<TRoleAccess, { $key: ERoleAccess.roleCreate }> | null
	}

	get roleList(): Extract<TRoleAccess, { $key: ERoleAccess.roleList }> | null {
		return this.$(ERoleAccess.roleList) as Extract<TRoleAccess, { $key: ERoleAccess.roleList }> | null
	}

	get roleModify(): Extract<TRoleAccess, { $key: ERoleAccess.roleModify }> | null {
		return this.$(ERoleAccess.roleModify) as Extract<TRoleAccess, { $key: ERoleAccess.roleModify }> | null
	}

	get roleRead(): Extract<TRoleAccess, { $key: ERoleAccess.roleRead }> | null {
		return this.$(ERoleAccess.roleRead) as Extract<TRoleAccess, { $key: ERoleAccess.roleRead }> | null
	}

	get roleRemove(): Extract<TRoleAccess, { $key: ERoleAccess.roleRemove }> | null {
		return this.$(ERoleAccess.roleRemove) as Extract<TRoleAccess, { $key: ERoleAccess.roleRemove }> | null
	}

	// Account

	get accountCreate(): Extract<TRoleAccess, { $key: ERoleAccess.accountCreate }> | null {
		return this.$(ERoleAccess.accountCreate) as Extract<TRoleAccess, { $key: ERoleAccess.accountCreate }> | null
	}

	get accountList(): Extract<TRoleAccess, { $key: ERoleAccess.accountList }> | null {
		return this.$(ERoleAccess.accountList) as Extract<TRoleAccess, { $key: ERoleAccess.accountList }> | null
	}

	get accountModify(): Extract<TRoleAccess, { $key: ERoleAccess.accountModify }> | null {
		return this.$(ERoleAccess.accountModify) as Extract<TRoleAccess, { $key: ERoleAccess.accountModify }> | null
	}

	get accountRead(): Extract<TRoleAccess, { $key: ERoleAccess.accountRead }> | null {
		return this.$(ERoleAccess.accountRead) as Extract<TRoleAccess, { $key: ERoleAccess.accountRead }> | null
	}

	get accountRemove(): Extract<TRoleAccess, { $key: ERoleAccess.accountRemove }> | null {
		return this.$(ERoleAccess.accountRemove) as Extract<TRoleAccess, { $key: ERoleAccess.accountRemove }> | null
	}

	// Offer scope

	get offerScopeCreate(): Extract<TRoleAccess, { $key: ERoleAccess.offerScopeCreate }> | null {
		return this.$(ERoleAccess.offerScopeCreate) as Extract<TRoleAccess, { $key: ERoleAccess.offerScopeCreate }> | null
	}

	get offerScopeList(): Extract<TRoleAccess, { $key: ERoleAccess.offerScopeList }> | null {
		return this.$(ERoleAccess.offerScopeList) as Extract<TRoleAccess, { $key: ERoleAccess.offerScopeList }> | null
	}

	get offerScopeModify(): Extract<TRoleAccess, { $key: ERoleAccess.offerScopeModify }> | null {
		return this.$(ERoleAccess.offerScopeModify) as Extract<TRoleAccess, { $key: ERoleAccess.offerScopeModify }> | null
	}

	get offerScopeRead(): Extract<TRoleAccess, { $key: ERoleAccess.offerScopeRead }> | null {
		return this.$(ERoleAccess.offerScopeRead) as Extract<TRoleAccess, { $key: ERoleAccess.offerScopeRead }> | null
	}

	get offerScopeRemove(): Extract<TRoleAccess, { $key: ERoleAccess.offerScopeRemove }> | null {
		return this.$(ERoleAccess.offerScopeRemove) as Extract<TRoleAccess, { $key: ERoleAccess.offerScopeRemove }> | null
	}

	// Offer

	get offerBind(): Extract<TRoleAccess, { $key: ERoleAccess.offerBind }> | null {
		return this.$(ERoleAccess.offerBind) as Extract<TRoleAccess, { $key: ERoleAccess.offerBind }> | null
	}

	get offerList(): Extract<TRoleAccess, { $key: ERoleAccess.offerList }> | null {
		return this.$(ERoleAccess.offerList) as Extract<TRoleAccess, { $key: ERoleAccess.offerList }> | null
	}

	get offerUnbind(): Extract<TRoleAccess, { $key: ERoleAccess.offerUnbind }> | null {
		return this.$(ERoleAccess.offerUnbind) as Extract<TRoleAccess, { $key: ERoleAccess.offerUnbind }> | null
	}
}

export class Access {
	constructor(
		private readonly account: IAccount
	) {}

	get $account(): IAccount {
		return this.account
	}

	get $role(): Part<IRole, 'createdAt' | 'updatedAt'> | null {
		return this.$account.role ?? null
	}

	get $auth(): Part<IAccountAuthLocal, 'updatedAt'> | null {
		return this.$account.auth ?? null
	}

	get $access(): TRoleAccess[] {
		return this.$role?.access ?? []
	}

	get $rule(): Rule {
		return new Rule(this)
	}

	$($key: ERoleAccess): TRoleAccess | null {
		return this.$access.find((rule: TRoleAccess) => rule.$key === $key) ?? null
	}

	$$(...$keys: ERoleAccess[]): TRoleAccess | null {
		for (const $key of $keys) {
			const rule = this.$access.find((rule: TRoleAccess) => rule.$key === $key) ?? null
			if (!rule) continue
			return rule
		}
		return null
	}

	// RULES

	get any(): boolean {
		return this.$rule.any?.$value ?? false
	}

	get authLogin(): boolean {
		return this.$rule.authLogin?.$value ?? this.any
	}
	
	get authProfile(): boolean {
		return this.$rule.authProfile?.$value ?? this.any
	}

	// Role 

	get roleCreate(): boolean {
		return this.$rule.roleCreate?.$value ?? this.any
	}

	get roleList(): boolean {
		return this.$rule.roleList?.$value ?? this.any
	}

	get roleModify(): boolean {
		return this.$rule.roleModify?.$value ?? this.any
	}

	get roleRead(): boolean {
		return this.$rule.roleRead?.$value ?? this.any
	}

	get roleRemove(): boolean {
		return this.$rule.roleRemove?.$value ?? this.any
	}

	// Account

	get accountCreate(): boolean {
		return this.$rule.accountCreate?.$value ?? this.any
	}

	get accountList(): boolean {
		return this.$rule.accountList?.$value ?? this.any
	}

	get accountModify(): boolean {
		return this.$rule.accountModify?.$value ?? this.any
	}

	get accountRead(): boolean {
		return this.$rule.accountRead?.$value ?? this.any
	}

	get accountRemove(): boolean {
		return this.$rule.accountRemove?.$value ?? this.any
	}

	// Offer scope

	get offerScopeCreate(): boolean {
		return this.$rule.offerScopeCreate?.$value ?? this.any
	}

	get offerScopeList(): boolean {
		return this.$rule.offerScopeList?.$value ?? this.any
	}

	get offerScopeModify(): boolean {
		return this.$rule.offerScopeModify?.$value ?? this.any
	}

	get offerScopeRead(): boolean {
		return this.$rule.offerScopeRead?.$value ?? this.any
	}

	get offerScopeRemove(): boolean {
		return this.$rule.offerScopeRemove?.$value ?? this.any
	}

	// Offer

	get offerBind(): boolean {
		return this.$rule.offerBind?.$value ?? this.any
	}

	get offerList(): boolean {
		return this.$rule.offerList?.$value ?? this.any
	}

	get offerUnbind(): boolean {
		return this.$rule.offerUnbind?.$value ?? this.any
	}

}