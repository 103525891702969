import { useEffect, useState, useRef } from 'react'
import { useRecoilState, useSetRecoilState, DefaultValue } from 'recoil'

import * as fetchs from '../fetchs'
import * as state from '../states'
import * as lib from '../lib'
import {
	INoFetch,
	IFetchAuthProfile,
	Part,
	IAccountToken
} from '../types'
import { useNavigate } from 'react-router'


type IUseAppInitOutProps = {
	error: INoFetch | null;
	profile: IFetchAuthProfile | null;
	warmUp: () => void;
}

const keyAccess: string = 'access-token'
const keyRefresh: string = 'refresh-token'

const getTokenStorage = async () => {
	const access = await lib.store.read<string>(keyAccess)
	const refresh = await lib.store.read<string>(keyRefresh)
	return !!access && !!refresh ? { access, refresh } : !!refresh ? { refresh } : null
}

export const setStorageToken = (
	newValue: DefaultValue | Part<IAccountToken, 'access' | 'refresh'> | null
) => {
	if (!!newValue && newValue instanceof Object) {
		const { access, refresh } = newValue as Exclude<typeof newValue, DefaultValue | null>
		if (access) {
			lib.store.save<string>(keyAccess, access)
		} else if (newValue.hasOwnProperty('access') && access === undefined) {
			lib.store.remove(keyAccess)
		}
		if (refresh) {
			lib.store.save<string>(keyRefresh, refresh)
		} else if (newValue.hasOwnProperty('refresh') && refresh === undefined) {
			lib.store.remove(keyRefresh)
		}
	} else if (newValue === null) {
		lib.store.remove(keyAccess)
		lib.store.remove(keyRefresh)
	}
}

export const useAppInit = (send: boolean): IUseAppInitOutProps => {
	const hookLaunchCount = useRef<number>(0)
	const navigate = useNavigate()

	const setRecoilTokens = useSetRecoilState(state.tokens)
	const [_profile, setProfile] = useRecoilState(state.profile)
	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)

	const [error, setError] = useState<INoFetch | null>(null)
	const [data, setData] = useState<IFetchAuthProfile | null>(null)

	const [warmUpIt, setWarmUpIt] = useState<number>(0)

	const warmUp = () => setWarmUpIt(warmUpIt + 1)

	type IFetchAuthFunc = {
		signal: AbortSignal,
		token?: string | null,
		onSuccessCb?: (newTokenAccess?: string) => void
		onErrorCb?: () => void
	}

	const fetchAuthProfileFunc = async (
		{ signal, token, onSuccessCb, onErrorCb }: IFetchAuthFunc
	) => {
		try {
			setFetchIsLoading(state.turnOnLoader)
			const data: IFetchAuthProfile | null = await fetchs.authProfile(signal, token)
			setData(data ? data : null)
			data && setProfile(data.profile) // save profile in the recoil state

			onSuccessCb?.()
		} catch (error: unknown) {
			setError(error as INoFetch)
			setData(null)
			setProfile(null)

			onErrorCb?.()
		} finally {
			setFetchIsLoading(state.turnOffLoader)
		}
	}

	const fetchAuthRefreshFunc = async (
		{ signal, token, onSuccessCb, onErrorCb }: IFetchAuthFunc
	) => {
		try {
			setFetchIsLoading(state.turnOnLoader)
			// try to get new access via refresh token
			const newToken = await fetchs.authRefresh(signal, token)
			setStorageToken({ access: newToken?.token?.access }) // save NEW access token to Storage
			setRecoilTokens(
				(oldRecoilToken) => (
					{
						...oldRecoilToken,
						access: newToken?.token?.access
					})
			)

			onSuccessCb?.(newToken?.token?.access)
		} catch (error: unknown) {
			setStorageToken({ refresh: undefined }) // delete refresh token from Storage
			setRecoilTokens(
				(oldRecoilToken) => (
					{
						...oldRecoilToken,
						refresh: undefined
					})
			)
			onErrorCb?.()

			navigate('/login') // Navigate to login route onError
		} finally {
			setFetchIsLoading(state.turnOffLoader)
		}
	}

	const onErrorDefaultActions = () => {
		setStorageToken(null) // delete all tokens from Storage
		setRecoilTokens({})

		navigate('/login') // Navigate to login route onError
	}

	useEffect(() => {
		// TODO: check hookLaunchCount flag detection-protection from extra hook trigger
		if (hookLaunchCount.current > 0) {
			console.warn(`extra hook.useAppInit---useEffect detected: `, { hookLaunchCount, send })
		}
		hookLaunchCount.current++

		const controller = new AbortController()
		const signal = controller.signal

		const call = async () => {
			let tokens: (Part<IAccountToken, 'access' | 'refresh'> | null) = await getTokenStorage()

			const access = tokens?.access ?? null
			const refresh = tokens?.refresh ?? null
			if (!!access && !!refresh) {
				setRecoilTokens(tokens) // set initial tokens from Storage to the recoil state
				// Logic Level 1
				// try to get profile via access token
				await fetchAuthProfileFunc({
					signal,
					token: access,
					onSuccessCb: () => {
						setRecoilTokens(tokens) // save tokens in the recoil state
					},
					onErrorCb: async () => {
						setStorageToken({ access: undefined }) // delete access token from Storage
						setRecoilTokens(
							(oldRecoilToken) => (
								{
									...oldRecoilToken,
									access: undefined
								})
						)
						// Logic Level 2
						// try to get new access via refresh token
						await fetchAuthRefreshFunc({
							signal,
							token: refresh,
							onSuccessCb: async (newTokenAccess) => {
								// LogicLevel 3
								// try to get profile via access NEW token
								await fetchAuthProfileFunc({
									signal,
									token: newTokenAccess,
									onSuccessCb: () => {
										setRecoilTokens((currVal) => (
											{
												...currVal,
												access: newTokenAccess
											}
										)) // save newTokenAccess in the recoil state
									},
									onErrorCb: () => {
										onErrorDefaultActions()
									}
								})
							}
						})
					}
				})
			} else {
				onErrorDefaultActions()
			}
		}

		send && call()

		return () => {
			controller.abort()
		}
	}, [
		send,
		warmUpIt,
		setRecoilTokens,
		setProfile,
		setError,
		setData
	])

	return { error, profile: data, warmUp }
}

