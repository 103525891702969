import dt from 'dayjs'
import 'dayjs/locale/uk'

import * as lib from '../lib'
import { IFetchItems, IFetchOfferScope, IOfferScope } from '../types'

export const listOfferScope = async (
	filter: ReturnType<typeof lib.filterSetup>,
	sort: ReturnType<typeof lib.sortSetup>,
	skip: ReturnType<typeof lib.skipSetup>,
	take: ReturnType<typeof lib.takeSetup>,
	signal: AbortSignal,
	token?: string | null,
): Promise<IFetchItems<IOfferScope>> => {
	const headers = lib.bearer(token)
	const method: string = 'GET'
	const params: lib.FetchOptions['params'] = {
		f: filter,
		s: sort,
		skip,
		take
	}
	const uri: string = 'offer/scope'

	const options: lib.FetchOptions = {
		headers,
		method,
		params,
		signal,
	}
	const data = await lib.fetching<IFetchItems<IFetchOfferScope>>(uri, options)

	const rows = data?.items
		?.map((item: IFetchOfferScope): IOfferScope => {
			const { createdAt, updatedAt, deletedAt, ...row } = item
			return {
				...row,
				createdAt: dt(createdAt).toDate(),
				updatedAt: dt(updatedAt).toDate(),
				deletedAt: deletedAt ? dt(deletedAt).toDate() : null,
			}
		})

	return {
		items: rows ?? [],
		total: data?.total ?? 0,
	}
}
