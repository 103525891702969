import React from 'react'
import * as state from '../../states'

import { IRole, TRoleAccess } from '../../types'
import {
	UiTable,
	IUiTableConfig,
	IconDeleteBin,
	IconEdit,
	UiMoreTextInfo,
	headCellFormatterSortable,
	ESortOrder
} from '../ui'
import { ERoleAccess, ERoleAccessIsAny, ERoleIsSystem } from '../../types/enum'
import { IS_SYSTEM_COLORS_MAP, ACCESS_IS_ANY_COLORS_MAP } from '../../constants'
import { useRecoilValue } from 'recoil'
import { ROLE_PAGE_SORT_FIELDS, IRoleSortField } from '../../hooks'
import { Access } from '../../lib'


type IRoleListTableProps = {
	items: IRole[];
	skip: number;
	sortBy: IRoleSortField;
	setSortBy: (sortBy: IRoleSortField) => void;
	order: ESortOrder;
	setOrder: (order: ESortOrder) => void;
	onEdit?: (item: IRole, e: React.MouseEvent) => void;
	onRemove?: (item: IRole, e: React.MouseEvent) => void;
}

type IRoleIsSystemProps = {
	value: ERoleIsSystem;
	rowData: IRole; // { }
	onClick?: (rowData: IRole, e: React.MouseEvent) => void;
	classNamesToAdd?: string;
}

type IRoleAccessProps = {
	value: Array<TRoleAccess>;
	rowData: IRole; // { }
	onClick?: (rowData: IRole, e: React.MouseEvent) => void;
	classNamesToAdd?: string;
}

const RoleIsSystemField = (props: IRoleIsSystemProps) => {
	const { value, classNamesToAdd, rowData, onClick } = props

	return <>
		<span
			/*style={{cursor: 'pointer'}}*/
			className={`badge text-white fw-normal py-1 px-2 fs-12 rounded-1 edit ${classNamesToAdd ?? ''}`}
			onClick={(e) => onClick && onClick(rowData, e)}
		>
			{value === ERoleIsSystem.system ? 'System' : value === ERoleIsSystem.user ? 'User' : '?'}
		</span>
	</>
}

const RoleAccessIsAnyField = (props: IRoleAccessProps) => {
	const { value, classNamesToAdd, rowData, onClick } = props

	return <>
		<span
			/*style={{cursor: 'pointer'}}*/
			className={`badge text-white fw-normal py-1 px-2 fs-12 rounded-1 edit ${classNamesToAdd ?? ''}`}
			onClick={(e) => onClick && onClick(rowData, e)}
		>
			{value.find(item => item.$key === ERoleAccess.any)?.$value === true ? 'Any' : 'Custom'}
		</span>
	</>
}

export const RoleListTable = (props: IRoleListTableProps) => {
	const {
		items = [],
		skip = 0,
		sortBy,
		setSortBy,
		order,
		setOrder,
		onEdit,
		onRemove
	} = props
	const profile = useRecoilValue(state.profile)
	const $access = profile ? new Access(profile) : null

	const roleListTableConfig: IUiTableConfig<IRole>[] = [
		{
			key: 'id',
			title: 'ID'
		},
		{
			key: 'code',
			title: 'Code'
		},
		{
			key: 'name',
			title: 'Name'
		},
		{
			key: 'isSystem',
			title: 'Is',
			headCellFormatter: ({ value, configItem }) => {
				return <div style={{ textAlign: 'center' }}>{value}</div>
			},
			rowCellFormatter: ({ value, rowData, rowIndex, cellIndex }) => {
				//TODO: formatter for 'isSystem' field in edit mode (dropdown select...?)
				const valueFormat: ERoleIsSystem = value ? ERoleIsSystem.system : ERoleIsSystem.user

				return <div style={{ textAlign: 'center' }}
				>
					<RoleIsSystemField
						value={valueFormat}
						rowData={rowData}
						classNamesToAdd={IS_SYSTEM_COLORS_MAP?.[valueFormat]}
						onClick={(rowData, e) => {
							console.log('Click on isSystem field :::', { value, rowData, rowIndex, cellIndex })
						}}
					/>
				</div>
			}
		},
		/* {
			key: 'notes',
			title: 'Notes'
		}, */
		{
			key: 'access',
			title: 'Access',
			rowCellFormatter: ({ value, rowData, rowIndex, cellIndex }) => {
				const any: boolean = value.find((item: TRoleAccess) => item.$key === ERoleAccess.any)?.$value === true ?? false
				const valueFormat: ERoleAccessIsAny = any ? ERoleAccessIsAny.any : ERoleAccessIsAny.custom

				return <div style={{ textAlign: 'center' }}
				>
					<RoleAccessIsAnyField
						value={value}
						rowData={rowData}
						classNamesToAdd={ACCESS_IS_ANY_COLORS_MAP?.[valueFormat]}
						onClick={(rowData, e) => {
							console.log('Click on access field :::', { value, rowData, rowIndex, cellIndex })
						}}
					/>
				</div>
			}
		},
		{
			key: 'ACTIONS',
			title: 'Actions',
			headCellFormatter: ({ configItem }) => {
				return <div>{''}</div>
			},
			rowCellFormatter: ({ rowData }) => {
				return <>
					<div
						style={{
							margin: '0 auto',
							textAlign: 'center'
						}}
					>
						{
							!!onEdit && !!$access?.roleModify
								? (
									<button name="edit"
									        className="icon border-0 rounded-circle text-center edit bg-success-transparent edit-item"
									        onClick={(e) => onEdit && onEdit(rowData, e)}
									>
										<IconEdit/>
									</button>
								)
								: null
						}

						{
							!!onRemove && !!$access?.roleRemove
								? (
									<button
										name="delete"
										className="icon border-0 rounded-circle text-center trash bg-danger-transparent delete-item"
										onClick={(e) => onRemove && onRemove(rowData, e)}
									>
										<IconDeleteBin/>
									</button>
								)
								: null
						}
					</div>
				</>
			}
		}
	]

	const roleListTableConfigSortable: IUiTableConfig<IRole>[] = roleListTableConfig.map(
		(configItem) => {
			const configItemIsSortable = ROLE_PAGE_SORT_FIELDS.includes(configItem.key as IRoleSortField)

			if (!configItemIsSortable) {
				return configItem
			} else {
				return {
					...configItem,
					isSortable: true,
					headCellFormatter: ({
						value,
						configItem,
						cellIndex
					}) => {

						return headCellFormatterSortable({
							value,
							configItem,
							cellIndex,
							sortBy,
							setSortBy,
							order,
							setOrder
						})
					}
				}
			}
		})

	//console.log('RoleListTable:: ', { roleListTableConfig, roleListTableConfigSortable })

	return <>
		<UiTable
			data={items}
			config={roleListTableConfigSortable}
			// TODO: uncomment if necessary
			// classesToAdd={{
			// 	wrapper: 'table-sticky-wrapper',
			// }}
		/>
	</>
}