import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faLinkSlash } from '@fortawesome/free-solid-svg-icons'
import * as state from '../../states'

import { IOfferScope } from '../../types'
import {
	UiTable,
	IUiTableConfig,
	IconDeleteBin,
	IconEdit,
	CapTypeIcon,
	UiMoreTextInfo,
	IconView,
	IconNotes,
	headCellFormatterSortable,
	ESortOrder
} from '../ui'
import { EOfferScopeStatus } from '../../types/enum'
import { STATUS_COLORS_MAP } from '../../constants'
import { useRecoilValue } from 'recoil'
import { OfferScopeNotesField, IPopUpPosition } from './OfferScopeNotesField'
import { OFFER_SCOPE_PAGE_SORT_FIELDS, IOfferScopeSortField } from '../../hooks'
import { Access } from '../../lib'


type IOfferScopeListTableProps = {
	items: IOfferScope[];
	skip: number;
	sortBy: IOfferScopeSortField;
	setSortBy: (sortBy: IOfferScopeSortField) => void;
	order: ESortOrder;
	setOrder: (order: ESortOrder) => void;
	onView?: (item: IOfferScope, e: React.MouseEvent) => void;
	onBind?: (item: IOfferScope, e: React.MouseEvent) => void;
	onUnbind?: (item: IOfferScope, e: React.MouseEvent) => void;
	onEdit?: (item: IOfferScope, e: React.MouseEvent) => void;
	onEditNotesScope?: (item: IOfferScope, e: React.MouseEvent) => void;
	onRemove?: (item: IOfferScope, e: React.MouseEvent) => void;
}

type IOfferScopeStatusProps = {
	value: string;
	rowData: IOfferScope; // { }
	onClick?: (rowData: IOfferScope, e: React.MouseEvent) => void;
	classNamesToAdd?: string;
}

const OfferScopeStatusField = (props: IOfferScopeStatusProps) => {
	const { value, classNamesToAdd, rowData, onClick } = props

	return <>
		<span
			/*style={{cursor: 'pointer'}}*/
			className={`badge text-white fw-normal py-1 px-2 fs-12 rounded-1 edit ${classNamesToAdd ?? ''}`}
			onClick={(e) => onClick && onClick(rowData, e)}
		>
			{value}
		</span>
	</>
}

export const OfferScopeListTable = (props: IOfferScopeListTableProps) => {
	const {
		items = [],
		skip = 0,
		sortBy,
		setSortBy,
		order,
		setOrder,
		onView,
		onBind,
		onUnbind,
		onEdit,
		onEditNotesScope,
		onRemove
	} = props
	const profile = useRecoilValue(state.profile)
	const $access = profile ? new Access(profile) : null
	//TODO: REMOVE CRUTCH - ACCESS FOR TEAM-X-USERS'
	const isTeam = useRecoilValue(state.isTeam)

	const offerScopeListTableConfig: IUiTableConfig<IOfferScope>[] = [
		{
			key: 'id',
			title: 'ID'
		},
		{
			key: 'name',
			title: 'Name'
		},
		{
			key: 'stats.ids',
			title: 'Offer ID',
			rowCellFormatter: ({ value, rowData }) => {
				const valueFormat: Array<string> = rowData?.stats?.ids?.map(item => String(item)) ?? []

				return <UiMoreTextInfo value={valueFormat}/>
			}
		},
		//TODO: check if group column is necessary
		/*{
			key: 'group',
			title: 'Group'
		},*/
		{
			key: 'notes',
			title: 'Notes',
			//TODO: check if OfferScopeNotesField with pop-up is necessary
			/*rowCellFormatter: ({ value, rowData, rowsData, rowIndex, cellIndex }) => {
				const notesProps = { value, rowData, isViewMode: isTeam }
				const rowsLength = rowsData.length
				let popUpPosition: IPopUpPosition

				if ([0, 1, 2].includes(rowIndex)) {
					popUpPosition = 'top'
				} else if ([rowsLength - 1, rowsLength - 2, rowsLength - 3].includes(rowIndex)) {
					popUpPosition = 'bottom'
				} else {
					popUpPosition = 'middle'
				}

				return <OfferScopeNotesField {...notesProps} popUpPosition={popUpPosition}/>
			}*/
			rowCellFormatter: ({ value, rowData, rowsData, rowIndex, cellIndex }) => {
				return (
					<div
						style={{
							margin: '0 auto',
							textAlign: 'center'
						}}
					>
						{
							onEditNotesScope
								? (
									<i className={'icon-notes'} style={{
										color: value?.length > 0 ? '#5d87ff' : 'black',
										padding: '7px',
										cursor: 'pointer'
									}}
									   onClick={(e) => onEditNotesScope && onEditNotesScope(rowData, e)}
									>
										<IconNotes/>
									</i>
								)
								: null
						}
					</div>
				)
			}
		},
		{
			key: 'affiliateNetwork',
			title: 'Affiliate Network',
			rowCellFormatter: ({ value, rowData }) => {
				const valueFormat: Array<string> = value ?? []

				return <UiMoreTextInfo value={valueFormat}/>
			}
		},
		{
			key: 'country',
			title: 'Country',
			rowCellFormatter: ({ value, rowData }) => {
				const valueFormat: Array<string> = value ?? []

				return <UiMoreTextInfo value={valueFormat}/>
			}
		},
		{
			key: 'sales/cap',
			title: 'Sales/Cap',
			headCellFormatter: ({ value, configItem, cellIndex }) => {
				return <div style={{ textAlign: 'center' }}>{value}</div>
			},
			rowCellFormatter: ({ value, rowData }) => {
				return <div style={{ textAlign: 'center' }}>
					{Number(rowData?.sales).toFixed(0)}/{Number(rowData?.cap).toFixed(0)}
				</div>
			}
		},
		{
			key: 'capType',
			title: 'Type',
			rowCellFormatter: ({ value, rowData }) => {
				return (
					<div title={value} style={{ textAlign: 'center' }}>
						<CapTypeIcon value={value}/>
					</div>
				)
			}
		},
		{
			key: 'status',
			title: 'Status',
			headCellFormatter: ({ value, configItem }) => {
				return <div style={{ textAlign: 'center' }}>{value}</div>
			},
			rowCellFormatter: ({ value, rowData, rowIndex, cellIndex }) => {
				//TODO: formatter for 'status' field in edit mode (dropdown select...?)
				const valueFormat: EOfferScopeStatus = value ?? EOfferScopeStatus.none

				return <div style={{ textAlign: 'center' }}
				>
					<OfferScopeStatusField
						value={value}
						rowData={rowData}
						classNamesToAdd={STATUS_COLORS_MAP?.[valueFormat]}
						onClick={(rowData, e) => {
							console.log('Click on Status field :::', { value, rowData, rowIndex, cellIndex })
						}}
					/>
				</div>
			}
		},
		{
			key: 'ACTIONS',
			title: 'Actions',
			headCellFormatter: ({ configItem }) => {
				return <div>{''}</div>
			},
			rowCellFormatter: ({ rowData }) => {
				return <>
					<div
						style={{
							margin: '0 auto',
							textAlign: 'center'
						}}
					>
						{
							!!onView && !!$access?.offerList && !$access.offerUnbind // !!! && !!isTeam.length
								? (
									<button name="view"
									        className="icon border-0 rounded-circle text-center bg-primary-transparent view-item"
									        onClick={(e) => onView && onView(rowData, e)}
									>
										<IconView/>
									</button>
								)
								: null
						}

						{
							!!onBind && !!$access?.offerBind // !!! && !isTeam.length
								? (
									<Button
										className="icon"
										variant="outline-primary"
										style={{
											borderRadius: '100%',
											aspectRatio: 1,
											padding: '5px 10px'
										}}
										onClick={(e) => onBind && onBind(rowData, e)}
									>
										<FontAwesomeIcon icon={faLink} style={{ fontSize: '14px' }}/>
									</Button>
								)
								: null
						}

						{
							!!onUnbind && !!$access?.offerUnbind // !!! && !isTeam.length
								? (
									<Button
										className="icon"
										variant="outline-primary"
										style={{
											borderRadius: '100%',
											aspectRatio: 1,
											padding: '5px 10px'
										}}
										onClick={(e) => onUnbind && onUnbind(rowData, e)}
									>
										<FontAwesomeIcon icon={faLinkSlash} style={{ fontSize: '14px' }}/>
									</Button>
								)
								: null
						}

						{
							!!onEdit && !!$access?.offerScopeModify // !!! && !isTeam.length
								? (
									<button name="edit"
									        className="icon border-0 rounded-circle text-center edit bg-success-transparent edit-item"
									        onClick={(e) => onEdit && onEdit(rowData, e)}
									>
										<IconEdit/>
									</button>
								)
								: null
						}

						{
							!!onRemove && !!$access?.offerScopeRemove // !!! && !isTeam.length
								? (
									<button
										name="delete"
										className="icon border-0 rounded-circle text-center trash bg-danger-transparent delete-item"
										onClick={(e) => onRemove && onRemove(rowData, e)}
									>
										<IconDeleteBin/>
									</button>
								)
								: null
						}
					</div>
				</>
			}
		}
	]

	const offerScopeListTableConfigSortable: IUiTableConfig<IOfferScope>[] = offerScopeListTableConfig.map(
		(configItem) => {
			const configItemIsSortable = OFFER_SCOPE_PAGE_SORT_FIELDS.includes(configItem.key as IOfferScopeSortField)

			if (!configItemIsSortable) {
				return configItem
			} else {
				return {
					...configItem,
					isSortable: true,
					headCellFormatter: ({
						value,
						configItem,
						cellIndex
					}) => {

						return headCellFormatterSortable({
							value,
							configItem,
							cellIndex,
							sortBy,
							setSortBy,
							order,
							setOrder
						})
					}
				}
			}
		})

	//console.log('OfferScopeListTable:: ', { offerScopeListTableConfig, offerScopeListTableConfigSortable })

	return <>
		<UiTable
			data={items}
			config={offerScopeListTableConfigSortable}
			// TODO: uncomment if necessary
			// classesToAdd={{
			// 	wrapper: 'table-sticky-wrapper',
			// }}
		/>
	</>
}