import { selector } from 'recoil'
import uniqid from 'uniqid'

import * as s1 from './state.profile'
import * as s2 from './state.tokens'
import dayjs from 'dayjs'

const key = uniqid()

export const isAuth = selector<boolean | null>({
	key: `auth:selector:${key}`,
	get: ({ get }) => {
		const profile = get(s1.profile)
		const tokens = get(s2.tokens)

		const access = tokens?.access ?? null
		const refresh = tokens?.refresh ?? null
		const isActive = profile?.isActive ?? null
		const deletedAt = profile?.deletedAt ?? null

		const isDeletedAtInFuture = dayjs(deletedAt).diff(Date.now(), 'millisecond', true) > 0
		const isProfileNotDeleted = !deletedAt || isDeletedAtInFuture

		let result = null

		if (access && refresh && isActive === true && isProfileNotDeleted) {
			result = true
		} else if (!access && !refresh) {
			result = false
		}

		return result
	}
})
