import {IFetchAuthLogin} from '../types'
import * as lib from '../lib'

export const authRefresh = async (
	signal: AbortSignal,
	token?: string | null,
): Promise<IFetchAuthLogin | null> => {
	const headers = lib.bearer(token)
	const method: string = 'POST'
	const params: lib.FetchOptions['params'] = {}
	const uri: string = '/auth/refresh'

	const options: lib.FetchOptions = {
		headers,
		method,
		params,
		signal,
	}
	const data = await lib.fetching<IFetchAuthLogin>(uri, options)

	if (data === null) {
		return null
	}

	const { ip, profile, token: tokens } = data

	return {
		ip,
		profile,
		token: tokens
	}
}