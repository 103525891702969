import { INoFetch } from './INoFetch'

export class INoFetchUnknown extends INoFetch {
	constructor(
		error: Error,
		req: Request,
		timeout: number,
	) {
		super('unknown', { error, req, timeout, })
	}

}
