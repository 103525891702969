import { useState } from 'react'
import { ESortOrder } from '../components'

export const OFFER_LIST_MODAL_SORT_FIELDS = ['id', 'name', 'affiliateNetwork'] as const

export type IOfferListModalSortField = typeof OFFER_LIST_MODAL_SORT_FIELDS[number]

type IUseSortOfferListModal = {
	sortBy: IOfferListModalSortField;
	setSortBy: (value: (((prevState: IOfferListModalSortField) => IOfferListModalSortField) | IOfferListModalSortField)) => void;
	order: ESortOrder;
	setOrder: (value: (((prevState: ESortOrder) => ESortOrder) | ESortOrder)) => void;
}

const DEFAULT_SORT_BY: IOfferListModalSortField = 'id'
const DEFAULT_SORT_ORDER: ESortOrder = ESortOrder.desc
export const useSortOfferListModal = (): IUseSortOfferListModal => {
	const [sortBy, setSortBy] = useState<IOfferListModalSortField>(DEFAULT_SORT_BY)
	const [order, setOrder] = useState<ESortOrder>(DEFAULT_SORT_ORDER)
	//console.log('useSortOfferListModal:: ', { sortBy, order })

	return {
		// sort: order !== ESortOrder.unset ? [[sortBy, order]] : [],
		sortBy,
		setSortBy,
		order,
		setOrder
	}
}