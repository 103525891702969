import React, { useState, useEffect, useRef } from 'react'
import { IOfferModalFilters, IFilterConfig } from '../types'
import { useRecoilValue } from 'recoil'
import * as state from '../states'
import { EFilterType, EFilterOperator, EFilterSpecOperator, EFilterFetchConvertType } from '../types/enum'
import { constructNewFilterFromConfig } from '../components/filters'

type IUseFiltersOfferListModal = {
	filter: [string, any][];
	send: boolean;
	activeFiltersCount: number;
	filterFillFactor: number;
	isFiltersPanelOpen: boolean;
	setIsFiltersPanelOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void;
	offerModalFiltersConfig: IFilterConfig<keyof IOfferModalFilters>[];
	defaultFiltersValue: IOfferModalFilters;
	initialFiltersValue: IOfferModalFilters;
	clearChildFilters: number;
	setUserFilters: (value: (((prevState: (IOfferModalFilters | null)) => (IOfferModalFilters | null)) | IOfferModalFilters | null)) => void;
}

const OFFER_MODAL_FILTERS_DEFAULT: IOfferModalFilters = {
	id: null,
	name: '',
	affiliateNetwork: null,
	country: null
}
export const useFiltersOfferListModal = (
	setPage: React.Dispatch<React.SetStateAction<number>>
): IUseFiltersOfferListModal => {
	const isFirstRender = useRef<boolean>(true)

	const [filter, setFilter] = useState<[string, any][]>([]) // main filter with mixins
	const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState<boolean>(false)
	const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0)

	const [clearChildFilters, setClearChildFilters] = useState<number>(0)
	const [send, setSend] = useState<boolean>(false)
	const [userFilters, setUserFilters] = useState<IOfferModalFilters | null>(null)

	const filterFillFactor = !!userFilters ? (100 * activeFiltersCount / Object.keys(userFilters).length) : 0

	const countryOptions = useRecoilValue(state.countryOptions)
	const affiliateNetworkOptions = useRecoilValue(state.affiliateNetworkOptions)

	const offerModalFiltersConfig: IFilterConfig<keyof IOfferModalFilters>[] = [
		{
			name: 'id',
			label: 'ID',
			type: EFilterType.input,
			fetchConvertType: EFilterFetchConvertType.number,
			operator: EFilterOperator.isLike,
			specOperator: EFilterSpecOperator.everywhere,
			styles: { width: '150px' }
		},
		{
			name: 'name',
			label: 'Name',
			type: EFilterType.input,
			operator: EFilterOperator.isLike,
			specOperator: EFilterSpecOperator.everywhere,
			styles: { width: '200px' }
		},
		{
			name: 'affiliateNetwork',
			label: 'Affiliate Network',
			type: EFilterType.selectSingle,
			operator: EFilterOperator.isEqual,
			options: affiliateNetworkOptions ?? [],
			isSearchable: true,
			styles: { minWidth: '200px' }
		},
		{
			name: 'country',
			label: 'Country',
			type: EFilterType.selectMultiple,
			operator: EFilterOperator.isSubSet,
			options: countryOptions ?? [],
			styles: { minWidth: '200px' }
		}
	]

	useEffect(() => {
		//console.log('hook.useFiltersOfferListModal useEffect::START:: ', { userFilters, filter })
		if (!!userFilters) {
			setSend(true)

			const { newFilter, activeFiltersCnt } = constructNewFilterFromConfig(
				userFilters,
				offerModalFiltersConfig
			)

			// while first render page resetting is not needed
			if (!isFirstRender.current) {
				setPage(0)
			} else {
				isFirstRender.current = false
			}

			setFilter(newFilter)
			setActiveFiltersCount(activeFiltersCnt)
			//console.log('hook.useFiltersOfferListModal:: useEffect::END:: ', { userFilters, filter, newFilter })
		}
	}, [userFilters])

	return {
		filter,
		send,
		activeFiltersCount,
		filterFillFactor,
		isFiltersPanelOpen,
		setIsFiltersPanelOpen,
		offerModalFiltersConfig,
		defaultFiltersValue: OFFER_MODAL_FILTERS_DEFAULT,
		initialFiltersValue: OFFER_MODAL_FILTERS_DEFAULT,
		clearChildFilters,
		setUserFilters
	}
}