import { Form } from 'react-bootstrap'
import Select from 'react-select'
import { InterfaceIFilterSelectMultiple } from '../../types'
import { ISelectOption } from '../../types/ui'
import uniqid from 'uniqid'

export const FilterSelectMultiple = (props: InterfaceIFilterSelectMultiple<string>) => {
	const {
		value,
		setValue,
		options,
		label,
		placeholder,
		styles = {},
		isSearchable = false,
		isClearable = false,
		isDisabled = false
	} = props
	const key = uniqid()
	const foundValue: ISelectOption<string>[] = []

	value?.forEach(valueItem => {
		const temp = options?.find(optionItem => optionItem.value === valueItem)
		if (!!temp) {
			foundValue.push(temp)
		}
	})

	return (
		<div className={'filter-input-cont'} style={styles}>
			{label ? <Form.Label htmlFor={`select-multiple-${key}`}>{label}</Form.Label> : null}

			<Select
				inputId={`select-multiple-${key}`}
				className="react-select-container"
				classNamePrefix="react-select"
				options={options}
				value={foundValue}
				placeholder={placeholder ?? 'Select ...'}
				isSearchable={isSearchable}
				isClearable={isClearable}
				isDisabled={isDisabled}
				isMulti={true}
				styles={{
					control: (baseStyles, state) => {
						return ({
							...baseStyles,
							cursor: 'pointer'
						})
					},
					option: (styles, state) => {
						return {
							...styles,
							cursor: 'pointer'
						}
					}
				}}
				onChange={(selectedItems) => {
					const selectedItemsMap = selectedItems?.map(item => item.value)
					setValue(selectedItemsMap ?? null)
				}}
			/>
		</div>
	)
}