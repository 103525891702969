import styled from 'styled-components'

type IProps = {
	flow?: '<-' | '->' | '<-|->' | '>-|-<';
	width?: string;
	children?: JSX.Element | (JSX.Element | null)[] | null;
}

const Panel = styled.div`
	box-sizing: border-box;
	display: flex;
	gap: 12px;
	&[data-flow="<-|->"] {
		justify-content: center;
	}
  &[data-flow=">-|-<"] {
      justify-content: space-between;
  }	
	&[data-flow="->"] {
		justify-content: start;
	}
	&[data-flow="<-"] {
		justify-content: end;
	}
	padding: 6px 12px;
`
export const ToolPanel = (props: IProps) => {
	return <Panel data-flow={props.flow ?? '->'} style={{width : props.width ?? 'auto'}}>{props.children}</Panel>
}
