import React from 'react'
import { Link } from 'react-router'
import { IconMoreLine } from './UiIcons'

type IUiPaginationProps = {
	page: number;
	take: number | 'all';
	total: number;
	classesToAdd?: {
		wrapper?: string;
	};
	href?: (page: number) => string;
	click?: (page: number, e: React.MouseEvent) => void;
}

type IUiPaginationItemProps = {
	pageIndex: number | null;
	title: React.ReactNode;
	active?: boolean;
	classNamesToAdd?: string;
	href?: string;
	onClick?: (page: number, e: React.MouseEvent) => void;
}

const PaginationItem = (props: IUiPaginationItemProps) => {
	const { pageIndex, active, classNamesToAdd, title, href, onClick } = props

	if (pageIndex === null) {
		return <>
			<li className="page-item">
				<Link
					className={active ? `page-link active ${classNamesToAdd ?? ''}` : `page-link ${classNamesToAdd ?? ''}`}
					to={`#`}
				>
					{title}
				</Link>
			</li>
		</>
	}

	return <>
		<li className="page-item">
			<Link
				className={active ? `page-link active ${classNamesToAdd ?? ''}` : `page-link ${classNamesToAdd ?? ''}`}
				title={`${pageIndex + 1}`}
				to={href ?? '#'}
				onClick={(e) => onClick?.(pageIndex, e)}
			>
				{title}
			</Link>
		</li>
	</>
}

export const UiPagination = (props: IUiPaginationProps) => {
	const { click, href, page, take, total, classesToAdd } = props

	if (take === 'all') {
		return null
	}

	const pageFirst = 0
	const pageLast = Math.max(Math.ceil(total / take) - 1, pageFirst)
	const pageCurrent = Math.max(Math.min(pageLast, page), pageFirst)
	const pagePrev = pageCurrent - 1
	const pageNext = pageCurrent + 1
	const rage = 2
	const pages = Array(2 * rage + 1)
		.fill(0)
		.map((_, it: number) => it - rage + pageCurrent)
		.filter((it: number) => pageFirst <= it && it <= pageLast)

	if (!total) {
		return null
	}

	return <>
		<div className={`d-sm-flex align-items-center justify-content-between text-center ${classesToAdd?.wrapper ?? ''}`}>
			{/*TODO: check the functionality of 'Items Per Page Show'*/}
			{/*<span className="fs-15 fw-medium text-dark mb-10 mb-sm-0 d-block">Items Per Page Show 10</span>*/}

			<nav aria-label="navigation">
				<ul className="pagination pagination-custom m-0 justify-content-center">
					{
						// Pagination.First
						pageFirst < pageCurrent
							? (
								<PaginationItem
									pageIndex={pageFirst}
									title={(<i className="ri-arrow-left-double-line"></i>)}
									href={href?.(pageFirst)}
									onClick={click}
								/>
							)
							: null
					}

					{
						// Pagination.Prev
						pageFirst < pagePrev && pages.length > 0
							? (
								<PaginationItem
									pageIndex={pagePrev}
									title={(<i className="ri-arrow-left-s-line"></i>)}
									href={href?.(pagePrev)}
									onClick={click}
								/>
							)
							: null
					}

					{
						!pages.includes(pageFirst) && pages.length > 0
							? (
								<PaginationItem
									pageIndex={pageFirst}
									title={pageFirst + 1}
									href={href?.(pageFirst)}
									onClick={click}
								/>
							)
							: null
					}

					{
						// Ellipsis
						!pages.includes(pageFirst + 1) && !pages.includes(pageFirst) && pages.length > 0
							? (
								<PaginationItem
									pageIndex={null}
									title={<IconMoreLine/>}
									classNamesToAdd={'disabled'}
								/>
							)
							: null
					}

					{
						// main pagination items
						pages
							.map((it: number) => (
								<PaginationItem
									key={it}
									pageIndex={it}
									title={it + 1}
									active={pageCurrent === it}
									href={href?.(it)}
									onClick={click}
								/>
							))
					}

					{
						// Ellipsis
						!pages.includes(pageLast - 1) && !pages.includes(pageLast) && pages.length > 0
							? (
								<PaginationItem
									pageIndex={null}
									title={<IconMoreLine/>}
									classNamesToAdd={'disabled'}
								/>
							)
							: null
					}

					{
						!pages.includes(pageLast) && pages.length > 0
							? (
								<PaginationItem
									pageIndex={pageLast}
									title={pageLast + 1}
									href={href?.(pageLast)}
									onClick={click}
								/>
							)
							: null
					}

					{
						// Pagination.Next
						pageNext < pageLast && pages.length > 0
							? (
								<PaginationItem
									pageIndex={pageNext}
									title={(<i className="ri-arrow-right-s-line"></i>)}
									href={href?.(pageNext)}
									onClick={click}
								/>
							)
							: null
					}

					{
						// Pagination.Last
						pageCurrent < pageLast && pages.length > 0
							? (
								<PaginationItem
									pageIndex={pageLast}
									title={(<i className="ri-arrow-right-double-line"></i>)}
									href={href?.(pageLast)}
									onClick={click}
								/>
							)
							: null
					}
				</ul>
			</nav>
		</div>
	</>

}
