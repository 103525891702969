import { setStorageToken } from '../hooks'
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router'
import * as lib from '../lib'
import * as state from '../states'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { SettingsIcon, HomeIcon, LogoIcon, GenerationIcon } from './ui'
import { Access } from '../lib'


export const SideMenu = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const [searchParams] = useSearchParams()

	const setRecoilTokens = useSetRecoilState(state.tokens)
	const [profile, setProfile] = useRecoilState(state.profile)
	const $access = profile ? new Access(profile) : null
	const offerGroupItems = useRecoilValue(state.offerGroups)

	const group = lib.groupSearchParams(searchParams.get('group') ?? '')

	const renderTrackingCapSubMenu = (): JSX.Element => {
		const renderItems = () => {
			return (
				offerGroupItems?.map((item, index) => {
					return (
						<li key={`${item}-${index}`}>
							<Link to={`/offer/scope?group=${encodeURIComponent(item)}`}
							      className={
								      [
									      group === item ? 'active' : ''
								      ].join(' ')}
							>
								{item}
							</Link>
						</li>
					)
				})
			)
		}

		return (
			<li className={[pathname === '/offer/scope' ? 'mm-active' : ''].join(' ')}>
				<Link to={`/offer/scope`} className="has-arrow menu-title">
					<span className="icon">
						<HomeIcon/>
					</span>
					<span className="title">Tracking Cap</span>
					<span className="count">{offerGroupItems?.length}</span>
				</Link>

				<ul className={
					[
						'sidemenu-second-level mm-collapse',
						pathname === '/offer/scope' ? 'mm-show' : ''
					].join(' ')}
				>
					{renderItems()}
				</ul>
			</li>
		)
	}

	const renderGen = (): JSX.Element => {
		return (
			<>
				<li>
					<Link to={`/offer/name/generation`} className="menu-title">
						<span className="icon">
							<GenerationIcon/>
						</span>
						<span className="title">Generation offer name</span>
					</Link>
				</li>
				<li>
					<Link to={`/postback/generation`} className="menu-title">
						<span className="icon">
							<GenerationIcon/>
						</span>
						<span className="title">Postback generation</span>
					</Link>
				</li>
			</>
		)
	}

	const renderLogoutSubMenu = (): JSX.Element => {
		return (
			<>
				<li>
					<Link to={`/login`} className="menu-title" onClick={(event) => {
						//TODO: check the logic --- to reset ALL data from Recoil state too (commonOptions...)???
						event.preventDefault()
						setProfile(null)
						setRecoilTokens({})
						setStorageToken(null)

						navigate('/login')
					}}>
						<span className="icon">
							<SettingsIcon/>
						</span>
						<span className="title">Logout</span>
					</Link>
				</li>
				{
					!!$access?.roleList && (
						<li>
							<Link to="/role" className="menu-title" >
								<span className="icon">
									<SettingsIcon/>
								</span>
								<span className="title">Roles</span>
							</Link>
						</li>
					)
				}
				{
					!!$access?.accountList && (
						<li>
							<Link to="/account" className="menu-title" >
								<span className="icon">
									<SettingsIcon/>
								</span>
								<span className="title">Accounts</span>
							</Link>
						</li>
					)
				}
			</>
		)
	}

	return <>
		<div className="sidebar-menu-area metismenu">
			<div className="simplebar-wrapper" style={{ margin: 0 }}>
				<div className="simplebar-height-auto-observer-wrapper">
					<div className="simplebar-height-auto-observer"></div>
				</div>
				<div className="simplebar-mask">
					<div className="simplebar-offset" style={{ right: '-20px', bottom: 0 }}>
						<div className="simplebar-content-wrapper" style={{ height: '100%', overflow: 'hidden scroll' }}>
							<div className="simplebar-content" style={{ padding: 0 }}>
								<div
									className="d-flex justify-content-between align-items-center border-bottom border-color bg-white position-sticky top-0 z-1 main-logo-wrap">
									{/*TODO: check the link for MAIN-LOGO click*/}
									<Link to="#" className="main-logo d-flex align-items-center text-decoration-none">
										<LogoIcon/>
										<span className="ms-3 logo-text">All In CRM</span>
									</Link>
									<div className="responsive-burger-menu d-block d-xl-none">
										<span className="top-bar"></span>
										<span className="middle-bar"></span>
										<span className="bottom-bar"></span>
									</div>
								</div>

								<ul className="sidebar-menu o-sortable">
									<li><span className="cat">PAGES</span></li>
									{renderTrackingCapSubMenu()}

									<br/>
									<br/>

									{/* <li><span className="cat">?</span></li>
									{renderGen()} */}

									<li><span className="cat">PROFILE</span></li>
									{renderLogoutSubMenu()}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="simplebar-placeholder" style={{ width: 'auto', height: '1778px' }}></div>
			</div>
			<div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
				<div className="simplebar-scrollbar" style={{ transform: 'translate3d(0px, 0px, 0px)', display: 'none' }}></div>
			</div>
			<div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
				<div className="simplebar-scrollbar"
				     style={{ height: '548px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }}></div>
			</div>
		</div>
	</>
}