import * as lib from '../lib'
import {IFetchAuthProfile} from '../types/'


export const authProfile = async (
	signal: AbortSignal,
	token?: string | null,
): Promise<IFetchAuthProfile | null> => {
	const headers = lib.bearer(token)
	const method: string = 'GET'
	const params: lib.FetchOptions['params'] = {}
	const uri: string = '/auth/profile'

	const options: lib.FetchOptions = {
		headers,
		method,
		params,
		signal,
	}
	const data = await lib.fetching<IFetchAuthProfile>(uri, options)

	if (data === null) {
		return null
	}

	const { profile, } = data

	return {
		profile,
	}
}
