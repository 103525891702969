import React from 'react'

type INotificationProps = {
	text: React.ReactNode;
}
export const UiNotification = (props: INotificationProps) => {
	const { text } = props

	return (
		<div className="p-3 rounded-3 text-center"
		     style={{ backgroundColor: 'rgba(255,0,0,0.1)' }}
		>
			<h4 className="fs-16 fw-semibold d-block"
			    style={{ color: 'rgba(255,0,0,0.7)', marginBottom: '0' }}
			>
				{text}
			</h4>
		</div>
	)
}