import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import dt from 'dayjs'

import * as lib from '../../lib'
import * as state from '../../states'
import { IAccount, IFetchItemsSetup, IRole } from '../../types'
import { EAccountIsActive, EAccountIsEmailVerified } from '../../types/enum'
import { ISelectOption } from '../../types/ui'
import { IS_ACTIVE_OPTIONS, IS_EMAIL_VERIFIED_OPTIONS } from '../../constants'
import { useRoleList } from '../../hooks'

type IAccountEditModalProps = {
	item: IAccount | null;
	show: boolean;
	onClose: () => void;
	onSave: () => void;
}

type IRoleField = {
	label: string;
	value: number;
}

export const AccountEditModal = (
	props: IAccountEditModalProps
) => {
	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)
	const tokens = useRecoilValue(state.tokens)
	const access = tokens?.access ?? null

	const { item, show, onClose, onSave } = props

	const setup: IFetchItemsSetup = {
		filter: [],
		sort: [],
		skip: 0,
		take: 'all',
	}
	const {
		error: roleError,
		items: roleItems,
		total: roleAmount,
		warmUp: roleWarmUp,
	} = useRoleList(setup, true)
	const roles: Array<IRoleField> = roleItems
	.map((role: IRole) => {
		return {
			value: role.id,
			label: `${role.id}. ${role.name} (${role.code})`,
		}
	})

	const [code, setCode] = useState<string>(item?.code ?? '')
	const [roleId, setRoleId] = useState<number | null>(item?.roleId ?? null)
	const [email, setEmail] = useState<string>(item?.email ?? '')
	const [isEmailVerified, setIsEmailVerified] = useState<ISelectOption<EAccountIsEmailVerified> | null>(
		IS_EMAIL_VERIFIED_OPTIONS.find(
			(option) => {
				const value: EAccountIsEmailVerified = item?.isEmailVerified === true ? EAccountIsEmailVerified.verified : EAccountIsEmailVerified.pending
				return option.value === value
			}
		) ?? IS_EMAIL_VERIFIED_OPTIONS.at(0) ?? null
  )
	const [isActive, setIsActive] = useState<ISelectOption<EAccountIsActive> | null>(
		IS_ACTIVE_OPTIONS.find(
			(option) => {
				const value: EAccountIsActive = item?.isActive === true ? EAccountIsActive.active : EAccountIsActive.unactive
				return option.value === value
			}
		) ?? IS_ACTIVE_OPTIONS.at(0) ?? null
  )
	const [registeredAt, setRegisteredAt] = useState<Date | null | undefined>(item?.registeredAt)
	const [login, setLogin] = useState<string>(item?.auth?.login ?? '')
	const [password, setPassword] = useState<string>('')

	if (!show) {
		return null
	}

	const authCreate = async (accountId: number) => {
		const uri: string = `account/${accountId}/auth`
		const method: string = 'POST'
		const params: lib.FetchOptions['params'] = {}
		const headers = lib.bearer(access)
		const body: string = JSON.stringify({
			...login && { login },
			...password && { password },
		})
		const options: lib.FetchOptions = { body, method, params, headers }

		return await lib.fetching<void>(uri, options)
	}

	const authUpdate = async (accountId: number) => {
		const uri: string = `account/${accountId}/auth`
		const method: string = 'PATCH'
		const params: lib.FetchOptions['params'] = {}
		const headers = lib.bearer(access)
		const body: string = JSON.stringify({
			...login && { login },
			...password && { password },
		})
		const options: lib.FetchOptions = { body, method, params, headers }

		return await lib.fetching<void>(uri, options)
	}

	const onUpdate = async () => {
		const accountId: number = item?.id ?? 0
		const uri: string = `account/${accountId}`
		const method: string = 'PATCH'
		const params: lib.FetchOptions['params'] = {}
		const headers = lib.bearer(access)
		const body: string = JSON.stringify({
			...code && { code },
			roleId,
			email,
			isEmailVerified: isEmailVerified?.value === EAccountIsEmailVerified.verified ? true
				: isEmailVerified?.value === EAccountIsEmailVerified.pending ? false
				: false,
			isActive: isActive?.value === EAccountIsActive.active ? true
				: isActive?.value === EAccountIsActive.unactive ? false
				: false,
			registeredAt: registeredAt ? dt(registeredAt).toISOString() : registeredAt,
		})
		const options: lib.FetchOptions = { body, method, params, headers }

		try {
			setFetchIsLoading(state.turnOnLoader)
			await lib.fetching<void>(uri, options)
			if (item?.auth) {
				await authUpdate(accountId)
			} else {
				await authCreate(accountId)
			}
			onSave()
		} catch (error: unknown) {
			// TODO: pretty notification alert
			console.error(error)
		} finally {
			setFetchIsLoading(state.turnOffLoader)
		}
	}

	return <div
		className="modal show"
		style={{ display: 'block', position: 'initial' }}
	>
		<Modal show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Edit account #{item?.id}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form>
					<Form.Group>

						{/*
						<Form.Label htmlFor={'input-code'}>Code</Form.Label>
						<Form.Control
							id={'input-code'}
							placeholder="Code"
							type="text"
							value={code}
							onChange={(e) => setCode(e.target.value)}
						/>
						<br/>
						*/}

						
						<Form.Label htmlFor={'select-role-id'}>Role</Form.Label>
						<Select
							inputId={'select-role-id'}
							className="react-select-container"
							options={roles}
							value={roles.find((role) => role.value === roleId) ?? null}
							isClearable={true}
							isSearchable={false}
							onChange={(selectedItem) => {
								setRoleId(selectedItem?.value ?? null)
							}}
						/>
						<br/>

						<Form.Label htmlFor={'input-email'}>Email</Form.Label>
						<Form.Control
							id={'input-email'}
							placeholder="Email"
							type="text"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<br/>

						<Form.Label htmlFor={'input-login'}>Login</Form.Label>
						<Form.Control
							id={'input-login'}
							placeholder="Login"
							type="text"
							value={login}
							onChange={(e) => setLogin(e.target.value)}
						/>
						<br/>

						<Form.Label htmlFor={'input-password'}>Password</Form.Label>
						<Form.Control
							id={'input-password'}
							placeholder="Password"
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<br/>

						<Form.Label htmlFor={'select-is-email-verified'}>Email verified</Form.Label>
						<Select
							inputId={'select-is-email-verified'}
							className="react-select-container"
							options={IS_EMAIL_VERIFIED_OPTIONS}
							value={isEmailVerified}
							isSearchable={false}
							onChange={(selectedItem) => {
								setIsEmailVerified(selectedItem)
							}}
						/>
						<br/>

						<Form.Label htmlFor={'select-is-active'}>Active</Form.Label>
						<Select
							inputId={'select-is-active'}
							className="react-select-container"
							options={IS_ACTIVE_OPTIONS}
							value={isActive}
							isSearchable={false}
							onChange={(selectedItem) => {
								setIsActive(selectedItem)
							}}
						/>

						{/*
						<br/>
						<Form.Label htmlFor={'input-registered-at'}>Registered At</Form.Label>
						<Form.Control
							id={'input-registered-at'}
							placeholder="Registered At"
							type="text"
							value={registeredAt}
							onChange={(e) => setRegisteredAt(e.target.value)}
						/>
						*/}

					</Form.Group>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="primary" onClick={onUpdate}>Save</Button>
				<Button variant="secondary" onClick={onClose}>Close</Button>
			</Modal.Footer>
		</Modal>
	</div>
}
