import { useState, useEffect, useRef } from 'react'
import { IAccountFilters, IFilterConfig } from '../types'
import { constructNewFilterFromConfig, constructUserFiltersFromUrl } from '../components/filters'
import * as lib from '../lib'
import { EFilterOperator, EFilterType, EFilterSpecOperator, EFilterFetchConvertType } from '../types/enum'
import { useRecoilValue } from 'recoil'
import * as state from '../states'
import { CAP_TYPE_OPTIONS, IS_SYSTEM_OPTIONS, STATUS_OPTIONS } from '../constants'
import { useSearchParams } from 'react-router'

type IUseFiltersAccountPage = {
	filter: [string, any][];
	send: boolean;
	activeFiltersCount: number;
	filterFillFactor: number;
	isFiltersPanelOpen: boolean;
	setIsFiltersPanelOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void;
	accountFiltersConfig: IFilterConfig<keyof IAccountFilters>[];
	defaultFiltersValue: IAccountFilters;
	initialFiltersValue: IAccountFilters;
	clearChildFilters: number;
	setUserFilters: (value: (((prevState: (IAccountFilters | null)) => (IAccountFilters | null)) | IAccountFilters | null)) => void;
}

const ACCOUNT_FILTERS_DEFAULT: IAccountFilters = {
	code: '',
	roleId: null,
	email: '',
	isEmailVerified: null,
	isActive: null,
	registeredAt: ''
}


export const useFiltersAccountPage = (): IUseFiltersAccountPage => {
	const isFirstRender = useRef<boolean>(true)

	// const profile = useRecoilValue(state.profile)

	const [searchParams, setSearchParams] = useSearchParams()

	const urlFilter = (() => {
		try {
			return lib.filterSearchParams(searchParams.get('f')) ?? []
		} catch (error) {
			console.error(error)
			return []
		}
	})()

	const [filter, setFilter] = useState<[string, any][]>([...urlFilter]) // main filter with mixins
	const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState<boolean>(false)
	const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0)

	const [clearChildFilters, setClearChildFilters] = useState<number>(0)
	const [send, setSend] = useState<boolean>(false)
	const [userFilters, setUserFilters] = useState<IAccountFilters | null>(null)

	const filterFillFactor = !!userFilters ? (100 * activeFiltersCount / Object.keys(userFilters).length) : 0

	const accountFiltersConfig: IFilterConfig<keyof IAccountFilters>[] = [
		// {
		// 	name: 'code',
		// 	label: 'Code',
		// 	type: EFilterType.input,
		// 	operator: EFilterOperator.isLike,
		// 	specOperator: EFilterSpecOperator.everywhere,
		// 	styles: { width: '200px' }
		// },
		{
			name: 'email',
			label: 'Email',
			type: EFilterType.input,
			operator: EFilterOperator.isLike,
			specOperator: EFilterSpecOperator.everywhere,
			styles: { width: '200px' }
		},
		// {
		// 	name: 'isEmailVerified',
		// 	label: 'isEmailVerified',
		// 	type: EFilterType.selectSingle,
		// 	operator: EFilterOperator.isEqual,
		// 	options: IS_EMAIL_VERIFIED_OPTIONS, // options: IS_SYSTEM_OPTIONS,
		// 	styles: { minWidth: '160px' }
		// },
		// {
		// 	name: 'isActive',
		// 	label: 'isActive',
		// 	type: EFilterType.selectSingle,
		// 	operator: EFilterOperator.isEqual,
		// 	options: IS_ACTIVE_OPTIONS,
		// 	styles: { minWidth: '160px' }
		// },
		// {
		// 	name: 'registeredAt',
		// 	label: 'Registered At',
		// 	type: EFilterType.input,
		// 	operator: EFilterOperator.isLike,
		// 	specOperator: EFilterSpecOperator.everywhere,
		// 	styles: { width: '200px' }
		// },
	]

	const initialUserFilters = constructUserFiltersFromUrl(
		urlFilter,
		accountFiltersConfig,
		ACCOUNT_FILTERS_DEFAULT
	)

	// reset filters onGroup (menu tabs) changed
	useEffect(() => {
		if (send) {
			setFilter([]) // clear main filter --> update in useEffect
			setUserFilters(ACCOUNT_FILTERS_DEFAULT)
			setClearChildFilters(prev => prev + 1)
		}
	}, [])

	useEffect(() => {
		//console.log('hook.useFiltersAccountPage useEffect::START:: ', { userFilters, filter })
		if (!!userFilters) {
			setSend(true)

			const { newFilter, activeFiltersCnt } = constructNewFilterFromConfig(
				userFilters,
				accountFiltersConfig
			)
			const newFilterStr = lib.filterSetup([...newFilter ?? []])

			setSearchParams((prev) => {
				if (newFilterStr) {
					prev.set('f', newFilterStr)
				} else {
					prev.delete('f')
				}

				// after first pageRefresh resetting is not needed
				if (!isFirstRender.current) {
					prev.delete('page')
				} else {
					isFirstRender.current = false
				}

				return prev
			})

			setFilter(newFilter)
			setActiveFiltersCount(activeFiltersCnt)

			// to open FiltersPanel if there were previously active filters on the page [onRefreshPage case]
			if (activeFiltersCnt > 0) {
				setIsFiltersPanelOpen(true)
			}
			console.log('hook.useFiltersAccountPage:: useEffect::END:: ', { userFilters, filter, newFilter, newFilterStr })
		}
	}, [userFilters])

	return {
		filter,
		send,
		activeFiltersCount,
		filterFillFactor,
		isFiltersPanelOpen,
		setIsFiltersPanelOpen,
		accountFiltersConfig,
		defaultFiltersValue: ACCOUNT_FILTERS_DEFAULT,
		initialFiltersValue: initialUserFilters,
		clearChildFilters,
		setUserFilters
	}
}