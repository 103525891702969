export const LoadingPage = (props: { classesToAdd?: string }) => {
	return (
		<div className={`preloader ${props?.classesToAdd ?? ''}`}
		     style={{opacity: 0.75}}
		>
			<div className="content">
				<div className="ball"></div>
				<div className="ball"></div>
				<div className="ball"></div>
				<div className="ball"></div>
				<div className="ball"></div>
				<div className="ball"></div>
				<div className="ball"></div>
				<div className="ball"></div>
				<div className="ball"></div>
				<div className="ball"></div>
			</div>
		</div>
	)
}
