import { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router'
import { useRecoilValue } from 'recoil'
import { AccountPage, DashboardPage, LoadingPage, LoginPage, OfferNameGenerationPage, OfferPage, OfferScopePage, RolePage, UnknownPage } from '../pages'

import * as layouts from './layouts'
import * as state from '../states'

import './App.scss'
import { Access } from '../lib'

export const App = () => {
	const isAuth = useRecoilValue(state.isAuth)
	const profile = useRecoilValue(state.profile)
	const $access = profile ? new Access(profile) : null

	return <>
		<Suspense fallback={<LoadingPage/>}>
			<BrowserRouter>
				<Routes>
					<Route element={<layouts.IndexLayout/>}>
						{isAuth === false ? (
							<Route element={<layouts.UnAuthLayout/>}>
								<Route path="/login" element={<LoginPage/>}/>
							</Route>
						) : null}

						{isAuth === true ? (
							<Route element={<layouts.AuthLayout/>}>
								<Route index element={<DashboardPage/>}/>
								<Route path="/generation/offer/name" element={<OfferNameGenerationPage/>}/>
								<Route path="/generation/postback" element={<OfferNameGenerationPage/>}/>
								{
									!!$access?.offerScopeList && (
										<Route path="/offer/scope" element={<OfferScopePage/>}/>
									)
								}
								{
									!!$access?.offerList && (
										<Route path="/offer" element={<OfferPage/>}/>
									)
								}
								{
									!!$access?.roleList && (
										<Route path="/role" element={<RolePage/>}/>
									)
								}
								{
									!!$access?.accountList && (
										<Route path="/account" element={<AccountPage/>}/>
									)
								}
							</Route>
						) : null}

						{/*<Route path="*" element={isAuth === true ? <DashboardPage/> : isAuth === false ? <LoginPage /> : <UnknownPage /> }/>*/}
						<Route path="*" element={<UnknownPage/>}/>
					</Route>
				</Routes>
			</BrowserRouter>
		</Suspense>
	</>
}
