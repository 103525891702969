import { atom, selector } from 'recoil'
import uniqid from 'uniqid'
import { IOptionsOfferAffiliateNetwork } from '../types'

const key = uniqid()
const affiliateNetworksAtom = atom<IOptionsOfferAffiliateNetwork>({
	key: `affiliateNetworks:atom:${key}`,
	default: null
})

export const affiliateNetworkOptions = selector<IOptionsOfferAffiliateNetwork>({
	key: `affiliateNetworks:selector:${key}`,
	get: ({ get }) => {
		return get(affiliateNetworksAtom)
	},
	set: ({ set }, value) => {
		return set(affiliateNetworksAtom, value)
	}
})