import { IconSearch, IconCross } from '../ui'
import uniqid from 'uniqid'
import { Form } from 'react-bootstrap'
import { IFilterInput } from '../../types'

export const FilterInput = (props: IFilterInput) => {
	const {
		value,
		setValue,
		label,
		placeholder,
		styles = {},
		isClearable = false,
		isDisabled = false,
		isIconSearchVisible = false
	} = props
	const key = uniqid()

	return (
		<div className="filter-input-cont" style={styles}>
			{label ? <Form.Label htmlFor={`input-${key}`}>{label}</Form.Label> : null}

			<div className={'src-form position-relative'}>
				<input type="text"
				       id={`input-${key}`}
				       className="form-control"
				       autoComplete={'off'}
				       placeholder={placeholder ?? ''}
				       disabled={isDisabled}
				       value={value ?? ''}
				       onChange={(event) => setValue(event.target.value)}
				/>

				<div className={'input-buttons bg-transparent position-absolute top-50 translate-middle-y'}>
					{isClearable && value?.length > 0 ? (
						<i className="icon-cross"
						   onClick={(event) => setValue('')}
						>
							<IconCross/>
						</i>
					) : null}

					{isIconSearchVisible ? (
						<>
							<span className="indicator-separator"></span>

							<i className="icon-search">
								<IconSearch/>
							</i>
						</>
					) : null}
				</div>
			</div>
		</div>
	)
}