import { INoFetch } from './INoFetch'

export class INoFetchAbort extends INoFetch {
	constructor(
		req: Request,
		timeout: number,
	) {
		super('abort', { req, timeout })
	}

}
