import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import * as fetchs from '../fetchs'
import * as lib from '../lib'
import * as state from '../states'
import { IAccountToken, Part } from '../types'
import { IFetchItemsSetup, INoFetch } from '../types'
import { IFetchItems, IRole } from '../types'

type IUseRoleList = {
	error: INoFetch | null;
	items: IRole[];
	total: number;
	warmUp: () => void;
}

export function useRoleList(
	setup: IFetchItemsSetup,
	send: boolean
): IUseRoleList {
	const tokens: Part<IAccountToken, 'access' | 'refresh'> | null = useRecoilValue(state.tokens)
	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)

	const [error, setError] = useState<INoFetch | null>(null)
	const [items, setItems] = useState<Array<IRole>>([])
	const [total, setTotal] = useState<number>(0)
	const [warmUpIt, setWarmUpIt] = useState<number>(0)
	const filter = lib.filterSetup([...setup?.filter ?? []])
	//console.log('useRoleList:: ', { sort: setup?.sort })
	const sort = lib.sortSetup([...setup?.sort ?? []])
	const skip = lib.skipSetup(setup?.skip)
	const take = lib.takeSetup(setup?.take)
	const access = tokens?.access ?? null
	const warmUp = () => setWarmUpIt(warmUpIt + 1)

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal

		const call = async () => {
			try {
				setFetchIsLoading(state.turnOnLoader)
				const res = await fetchs.listRole(filter, sort, skip, take, signal, access)
				const { items, total }: IFetchItems<IRole> = res
				setError(null)
				setItems(items)
				setTotal(total)
			} catch (error: unknown) {
				setError(error as INoFetch)
				setItems([])
				setTotal(0)
			} finally {
				setFetchIsLoading(state.turnOffLoader)
			}
		}

		send && call()

		return () => {
			controller.abort()
		}
	}, [
		setFetchIsLoading,
		send,
		filter, sort,
		skip, take,
		access,
		warmUpIt
	])

	return { error, items, total, warmUp }
}
