import { selector } from 'recoil'
import uniqid from 'uniqid'

import * as s1 from './state.profile'
import { TRoleAccess } from '../types'

const key = uniqid()

export const access = selector<TRoleAccess[]>({
	key: `profile.access:selector:${key}`,
	get: ({ get }) => {
		const profile = get(s1.profile)
		const access = profile?.role?.access ?? []
		return access
	},
})
