import { selector } from 'recoil'
import uniqid from 'uniqid'

import * as s1 from './state.profile'

const key = uniqid()

export const isUser = selector<boolean>({
	key: `profile.isUser:selector:${key}`,
	get: ({ get }) => {
		const profile = get(s1.profile)
		const profileRole = profile?.role
		return profileRole?.code === 'user'
	},
})
