import { selector } from 'recoil'
import uniqid from 'uniqid'

import * as s1 from './state.profile.access'

import { ERoleAccess } from '../types/enum'
import { TRoleAccess } from '../types'

type TRoleAccessTeam = Extract<TRoleAccess, { $key: ERoleAccess.offerScopeList; } | { $key: ERoleAccess.offerList; }> | undefined

const key = uniqid()

export const isTeam = selector<string>({
	key: `profile.isTeam:selector:${key}`,
	get: ({ get }) => {
		const access = get(s1.access)
		const rule: TRoleAccessTeam = access.find((item) => {
			return item.$key === ERoleAccess.offerScopeList || item.$key === ERoleAccess.offerList
		}) as TRoleAccessTeam
		console.log({ rule })
		return !!rule?.$value && !!rule?.group ? rule.group : ''
	}
})
