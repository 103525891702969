import { Button, Modal } from 'react-bootstrap'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import * as lib from '../../lib'
import * as state from '../../states'
import { IOfferScope } from '../../types'

type IOfferScopeRemoveModalProps = {
	item: IOfferScope | null;
	show: boolean;
	onClose: () => void;
	onSave: () => void;
}

export const OfferScopeRemoveModal = (
	props: IOfferScopeRemoveModalProps
) => {
	const tokens = useRecoilValue(state.tokens)
	const access = tokens?.access ?? null
	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)

	const { item, show, onClose, onSave } = props

	if (!show) {
		return null
	}

	const onRemove = async () => {
		const uri: string = `offer/scope/${item?.id}`
		const method: string = 'DELETE'
		const params: lib.FetchOptions['params'] = {}
		const headers = lib.bearer(access)
		const options: lib.FetchOptions = { method, params, headers }

		try {
			setFetchIsLoading(state.turnOnLoader)
			await lib.fetching<void>(uri, options)
			onSave()
		} catch(error: unknown) {
			// TODO: pretty notification alert
			console.error(error)
		} finally {
			setFetchIsLoading(state.turnOffLoader)
		}
	}

	return <div
		className="modal show"
		style={{ display: 'block', position: 'initial' }}
	>
		<Modal show={show} onHide={onClose} >
			<Modal.Header closeButton >
				<Modal.Title>Remove offer scope </Modal.Title>
			</Modal.Header>

			<Modal.Body >
				<h3 className={'fs-25'}>
					Remove scope #{item?.id}<br/>
					"{item?.name}".<br/><br/>
					Are you sure?
				</h3>
			</Modal.Body>

			<Modal.Footer >
				<Button variant="secondary" onClick={onClose}>Close</Button>
				<Button variant="primary" onClick={onRemove}>Remove</Button>
			</Modal.Footer>
		</Modal>
	</div>
}
