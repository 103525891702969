import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import * as fetchs from '../fetchs'
import * as state from '../states'
import { IAccountToken, Part, ICommonCountry, IOptionsOfferAffiliateNetwork } from '../types'
import { INoFetch } from '../types'

type IUseCommonFetchs = {
	errorOfferGroups: INoFetch | null;
	errorCountry: INoFetch | null;
	errorAffiliateNetwork: INoFetch | null;
	warmUp: () => void;
}
export const useCommonFetchs = (): IUseCommonFetchs => {
	const tokens: Part<IAccountToken, 'access' | 'refresh'> | null = useRecoilValue(state.tokens)
	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)
	const setOfferGroups = useSetRecoilState(state.offerGroups)
	const setCountryOptions = useSetRecoilState(state.countryOptions)
	const setAffiliateNetworkOptions = useSetRecoilState(state.affiliateNetworkOptions)

	const [errorOfferGroups, setErrorOfferGroups] = useState<INoFetch | null>(null)
	const [errorCountry, setErrorCountry] = useState<INoFetch | null>(null)
	const [errorAffiliateNetwork, setErrorAffiliateNetwork] = useState<INoFetch | null>(null)
	const [warmUpIt, setWarmUpIt] = useState<number>(0)

	const access = tokens?.access ?? null

	const warmUp = () => setWarmUpIt(warmUpIt + 1)

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal

		const call = async () => {
			try {
				setFetchIsLoading(state.turnOnLoader)

				// to launch common fetchs in parallel here
				const [offerGroup, commonCountry, offerAffiliateNetwork] = await Promise.allSettled([
					fetchs.listOfferGroup(signal, access),
					fetchs.listCommonCountry(signal, access),
					fetchs.listOfferAffiliateNetwork(signal, access)
				])
				// listOfferGroup
				if (offerGroup?.status === 'fulfilled' && offerGroup?.value?.items) {
					setErrorOfferGroups(null)
					setOfferGroups(offerGroup.value.items)
				} else if (offerGroup?.status === 'rejected') {
					setErrorOfferGroups(offerGroup.reason as INoFetch)
					setOfferGroups(null)
				}
				// listCommonCountry
				if (commonCountry?.status === 'fulfilled' && commonCountry?.value?.items) {
					const commonCountryMap: Array<ICommonCountry> = commonCountry.value.items.map(item => ({
						...item,
						value: item.label
					}))
					setErrorCountry(null)
					setCountryOptions(commonCountryMap)
				} else if (commonCountry?.status === 'rejected') {
					setErrorCountry(commonCountry.reason as INoFetch)
					setCountryOptions(null)
				}
				// listOfferAffiliateNetwork
				if (offerAffiliateNetwork?.status === 'fulfilled' && offerAffiliateNetwork?.value?.items) {
					const strComparator = (a: string, b: string) => {
						const nameA = a?.toUpperCase() // ignore upper and lowercase
						const nameB = b?.toUpperCase() // ignore upper and lowercase
						return nameA > nameB ? 1 : nameA < nameB ? -1 : 0
					}

					const offerAffiliateNetworkMap: IOptionsOfferAffiliateNetwork =
						offerAffiliateNetwork.value.items.sort(strComparator).map(item => ({
							label: item,
							value: item
						}))
					setErrorAffiliateNetwork(null)
					setAffiliateNetworkOptions(offerAffiliateNetworkMap)
				} else if (offerAffiliateNetwork?.status === 'rejected') {
					setErrorAffiliateNetwork(offerAffiliateNetwork.reason as INoFetch)
					setAffiliateNetworkOptions(null)
				}
			} finally {
				setFetchIsLoading(state.turnOffLoader)
			}
		}
		call()
		return () => {
			controller.abort()
		}
	}, [
		warmUpIt,
		access
	])
	return {
		errorOfferGroups,
		errorCountry,
		errorAffiliateNetwork,
		warmUp
	}
}