import React, { useState } from 'react'
import { Pagination, Table } from 'react-bootstrap'
import { useLocation, useNavigate, useSearchParams } from 'react-router'
import styled from 'styled-components'

import { useOfferList } from '../hooks'
import * as lib from '../lib'
import { IFetchItemsSetup, IOffer } from '../types'

const Cell = styled.td`
	box-sizing: border-box;
	vertical-align: middle;
	thead & {
		font-weight: 900;
	}
`

const Row = styled.tr`
	box-sizing: border-box;
`

const Agg = styled.span`
	box-sizing: border-box;
	margin: 0;
	padding: 0 !important;
	&:not(:last-child)::after{
		content: ', ';
	}
`

const TAKE: number | 'all' = 10

export const OfferPage = () => {
	const location = useLocation()
	const href = lib.pageHref(location, TAKE)
	const navigate = useNavigate()
	const [searchParams,] = useSearchParams()
	const filter = lib.filterSearchParams(searchParams.get('f')) ?? []
	const sort = lib.sortSearchParams(searchParams.get('s')) ?? []
	const page = lib.skipSearchParams(searchParams.get('page')) ?? 0
	const take = lib.takeSearchParams(searchParams.get('take')) ?? TAKE
	const skip = page > 0 && take !== 'all' ? page * take : 0
	const setup: IFetchItemsSetup = { filter, sort, skip, take }
	const { error, items, total, warmUp } = useOfferList(setup, true)
	const pageFirst = 0
	const pageId = take === 'all' ? 0 : Math.floor(skip / take)
	const pageLast = total < 1 ? 0 : take === 'all' ? 1 : Math.ceil(total / take)
	const pagePrev = Math.max(pageFirst, page - 1)
	const pageNext = Math.min(pageLast, page + 1)
	const rage = 2
	const pages = Array(2 * rage + 1).fill(0)
		.map((_, it: number) => it - rage + pageId)
		.filter((page: number) => pageFirst <= page && page < pageLast)

	return <>
		<Table striped bordered={false} hover responsive="lg">
			<thead>
				<Row>
					<Cell>#</Cell>
					<Cell>Id</Cell>
					<Cell>Name</Cell>
					<Cell>Group</Cell>
					<Cell>Affiliate Network</Cell>
					<Cell>Clicks</Cell>
					<Cell>Leads</Cell>
					<Cell>Sales</Cell>
					<Cell></Cell>
				</Row>
			</thead>
			<tbody>

			</tbody>
			{
				items.map((item: IOffer, it: number) => {
					return <Row key={item.id}>
						<Cell style={{ width: '1px' }}>{it + skip + 1}</Cell>
						<Cell>{item?.id}</Cell>
						<Cell>{item?.name}</Cell>
						<Cell>{item?.group}</Cell>
						<Cell>{item?.affiliateNetwork}</Cell>
						<Cell>{Number(item?.clicks).toFixed(0)}</Cell>
						<Cell>{Number(item?.leads).toFixed(0)}</Cell>
						<Cell>{Number(item?.sales).toFixed(0)}</Cell>
						<Cell style={{ width: '160px' }}>?</Cell>
					</Row>
				})
			}
		</Table>
		{
			total > 0 && (
				<Pagination>
					<Pagination.First />
					<Pagination.Prev />
					{
						pages.map((page: number) => {
							const active: boolean = page === pageId
							const href = new URLSearchParams(`${searchParams}`)
							if (take === 'all') {
								href.set('skip', `${0}`)
								href.set('take', `${take}`)
							} else if (typeof take === 'number') {
								href.set('skip', `${page * take}`)
								href.set('take', `${take}`)
							}
							return <Pagination.Item
								key={page}
								active={active}
								href={`?${href}`}
							>
								{page + 1}
							</Pagination.Item>
						})
					}
					<Pagination.Next />
					<Pagination.Last />
				</Pagination>
			)
		}
	</>
}
