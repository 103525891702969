import { Location } from 'react-router'
import { takeSearchParams } from './lib.takeSearchParams'

export const pageHref = (
	location: Location,
	TAKE: number | 'all',
) => (
	page: number,
	path?: string,
): URL & { tail: string } => {
	const origin = document.location.origin
	const l = new URL(`${location.pathname}${location.search}` ?? '', origin)
	const sp = l.searchParams
	const take = takeSearchParams(sp.get('take')) ?? TAKE
	const href = new URL(path ?? l.pathname, origin)
	Array.from(sp.entries()).forEach(([k, v]) => href.searchParams.append(k, v))
	href.searchParams.delete('page')
	href.searchParams.delete('take')
	if (page > 0) {
		href.searchParams.set('page', `${page}`)
	}
	if (!Object.is(TAKE, take)) {
		href.searchParams.set('take', `${take}`)
	}
	const tail = href.href.replace(href.origin, '')
	return { ...href, tail }
}
