export const IconView = () => (
	<svg xmlns="http://www.w3.org/2000/svg"
	     width="24px"
	     height="24px"
	     viewBox="0 0 24 24"
	     fill="none"
	     stroke="currentColor"
	     strokeWidth="2"
	     strokeLinecap="round"
	     strokeLinejoin="round"
	     className="feather feather-eye"
	>
		<path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
		<circle cx="12" cy="12" r="3"></circle>
	</svg>
)