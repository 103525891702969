import React, { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router'
import { useRecoilValue } from 'recoil'

import {
	AccountCreateModal,
	AccountListTable,
	AccountRemoveModal,
	AccountEditModal,
	UiPagination,
	ToolPanel,
	IconPlus,
	ESortOrder
} from '../components'
import { Filters, FiltersButtonIcon } from '../components/filters'
import { useAccountList, useFiltersAccountPage, useSortAccountPage } from '../hooks'
import * as lib from '../lib'
import * as state from '../states'
import { IFetchItemsSetup, IAccount } from '../types'
import { TAKE } from '../constants'
import { Access } from '../lib'

export const AccountPage = (): JSX.Element => {
	const profile = useRecoilValue(state.profile)
	const $access = profile ? new Access(profile) : null

	const location = useLocation()
	const href = lib.pageHref(location, TAKE)
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()

	const page = lib.skipSearchParams(searchParams.get('page')) ?? 0
	const take = lib.takeSearchParams(searchParams.get('take')) ?? TAKE
	const skip = (take === 'all') ? 0 : page > 0 ? page * take : 0

	const {
		filter,
		send,
		activeFiltersCount,
		filterFillFactor,
		isFiltersPanelOpen,
		setIsFiltersPanelOpen,
		accountFiltersConfig,
		defaultFiltersValue,
		initialFiltersValue,
		clearChildFilters,
		setUserFilters
	} = useFiltersAccountPage()

	//TODO: check sort output var type ?
	const {
		// sort: sortTmp,
		sortBy,
		setSortBy,
		order,
		setOrder
	} = useSortAccountPage()

	const setup: IFetchItemsSetup = {
		filter,
		sort: order !== ESortOrder.unset ? [[sortBy, order]] : [],
		skip,
		take
	}
	const { error, items, total, warmUp } = useAccountList(setup, send)


	const [createScope, setCreateScope] = useState<boolean>(false)
	const [editScope, setEditScope] = useState<IAccount | null>(null)
	const [removeScope, setRemoveScope] = useState<IAccount | null>(null)

	const onCreate = (e: React.MouseEvent) => {
		e.preventDefault()
		setCreateScope(true)
	}
	const onEdit = (item: IAccount, e: React.MouseEvent) => {
		e.preventDefault()
		setEditScope(item)
	}
	const onRemove = (item: IAccount, e: React.MouseEvent) => {
		e.preventDefault()
		setRemoveScope(item)
	}

	return (
		<>
			<div className="card rounded-3 border-0 mb-24">
				<div className="card-body p-25">
					{/* remove CREATE button for 'account' main page (without pre-selected group) */}
					<div className="d-flex align-items-center justify-content-between pb-20 border-bottom border-color">
						<FiltersButtonIcon
							activeFiltersCount={activeFiltersCount}
							filterFillFactor={filterFillFactor}
							onClick={() => setIsFiltersPanelOpen((val) => !val)}
						/>

						{
							!!onCreate && !!$access?.accountCreate
								? (
									<button
										type="button"
										className="btn btn-primary d-flex align-items-center"
										onClick={onCreate}
									>
										<IconPlus/>
										<span className="d-none d-lg-block">Add New</span>
									</button>
								)
								: null
						}
					</div>

					<Filters
						config={accountFiltersConfig}
						initialFiltersValue={initialFiltersValue}
						resetFiltersValue={defaultFiltersValue}
						isAutoModeWithDebounce={false}
						isFiltersPanelOpen={isFiltersPanelOpen}
						isFiltersToClear={clearChildFilters}
						setFiltersCB={(value) => {
							//console.log('page.AccountPage::setFiltersCB::', { value })
							setUserFilters(value)
						}}
					/>

					<AccountListTable
						items={items}
						skip={skip}
						sortBy={sortBy}
						setSortBy={setSortBy}
						order={order}
						setOrder={setOrder}
						onEdit={onEdit}
						onRemove={onRemove}
					/>

					<ToolPanel flow="<-">
						<UiPagination
							classesToAdd={{
								wrapper: 'mt-25'
							}}
							page={page}
							take={take}
							total={total}
							href={(page: number) => href(page).tail}
						/>
					</ToolPanel>
				</div>
			</div>

			{
				createScope && !!$access?.accountCreate && <AccountCreateModal
					show={createScope}
					onClose={() => setCreateScope(false)}
					onSave={() => {
						setCreateScope(false)
						navigate(href(0).tail)
						warmUp()
					}}
				/>
			}

			{
				!!editScope && !!$access?.accountModify && <AccountEditModal
					item={editScope}
					show={!!editScope}
					onClose={() => setEditScope(null)}
					onSave={() => {
						setEditScope(null)
						navigate(href(0).tail)
						warmUp()
					}}
				/>
			}

			{
				!!removeScope && !!$access?.accountRemove && <AccountRemoveModal
					item={removeScope}
					show={!!removeScope}
					onClose={() => setRemoveScope(null)}
					onSave={() => {
						setRemoveScope(null)
						navigate(href(0).tail)
						warmUp()
					}}
				/>
			}

		</>
	)
}
