import uniqid from 'uniqid'

export const IconFilter = (
	{
		color,
		fillFactor = 0
	}: { color?: string; fillFactor?: number }
) => {
	const key = uniqid()

	return (
		<svg xmlns="http://www.w3.org/2000/svg"
		     key={key}
		     viewBox="0 0 24 24"
		     width="24px"
		     height="24px"
		     stroke={color ?? `var(--mainColor)`}
		     strokeWidth="2"
		     strokeLinecap="round"
		     strokeLinejoin="round"
		     className="feather feather-filter"
		>
			<defs>
				<linearGradient id={`myLinearGradient-${key}`}
				                x1="0%" y1="0%"
				                x2="0%" y2="100%"
				                spreadMethod="pad">
					<stop offset="0%" stopColor="#fff" stopOpacity="1"/>
					<stop offset={`${Math.min(Math.max(0, 100 - fillFactor), 100)}%`}
					      stopColor={color ?? `var(--mainColor)`}
					      stopOpacity="1"
					/>
				</linearGradient>
			</defs>

			<polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"
			         style={{ fill: `url(#myLinearGradient-${key})` }}
			/>
		</svg>
	)
}
