import React from 'react'

export const IconSortAsc = ({ styles = {} }: { styles?: React.CSSProperties; }) => (
	<svg xmlns="http://www.w3.org/2000/svg"
	     viewBox="0 0 24 24"
	     width="24px"
	     height="24px"
	     fill="currentColor"
	     style={styles}
	>
		<path d="M19 3L23 8H20V20H18V8H15L19 3ZM14 18V20H3V18H14ZM14 11V13H3V11H14ZM12 4V6H3V4H12Z"></path>
	</svg>
)