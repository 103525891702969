import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import * as lib from '../../lib'
import * as state from '../../states'
import { IRole, TRoleAccess } from '../../types'
import { ISelectOption } from '../../types/ui'
import { IS_SYSTEM_OPTIONS } from '../../constants'
import { ERoleAccess, ERoleIsSystem } from '../../types/enum'
import { RoleAccessFields } from './RoleAccessFields'

type IRoleEditModalProps = {
	item: IRole | null;
	show: boolean;
	onClose: () => void;
	onSave: () => void;
}

const AccessRule = styled.div`
	padding: 0px 12px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 6px;
`

const MyControl = styled(Form.Control)`
	display: inline-flex;
	height: unset;
	padding: 2px 4px !important;
	width: 16em;
`

const MyCheck = styled(Form.Check)`
	display: inline-flex;
`

export const RoleEditModal = (
	props: IRoleEditModalProps
) => {
	const setFetchIsLoading = useSetRecoilState(state.fetchIsLoading)
	const tokens = useRecoilValue(state.tokens)
	const access = tokens?.access ?? null

	const { item, show, onClose, onSave } = props

	const [code, setCode] = useState<string>(item?.code ?? '')
	const [name, setName] = useState<string>(item?.name ?? '')
	const [isSystem, setIsSystem] = useState<ISelectOption<ERoleIsSystem> | null>(() => {
		return IS_SYSTEM_OPTIONS.find(
			(option) => {
				const value: ERoleIsSystem = item?.isSystem === true ? ERoleIsSystem.system : ERoleIsSystem.user
				return option.value === value
			}
		) ?? IS_SYSTEM_OPTIONS.at(0) ?? null
	})
	const [rules, setRules] = useState<Array<TRoleAccess>>(item?.access ?? [])
	const [notes, setNotes] = useState<string>(item?.notes ?? '')

	console.log({ rules, props, item })
	if (!show) {
		return null
	}

	const onUpdate = async () => {
		const uri: string = `role/${item?.id}`
		const method: string = 'PATCH'
		const params: lib.FetchOptions['params'] = {}
		const headers = lib.bearer(access)
		const body: string = JSON.stringify({
			code,
			name,
			isSystem: isSystem?.value === ERoleIsSystem.system ? true
				: isSystem?.value === ERoleIsSystem.user ? false
				: false,
			access: rules.filter((role: TRoleAccess) => role.$value === true),
			notes
		})
		const options: lib.FetchOptions = { body, method, params, headers }

		try {
			setFetchIsLoading(state.turnOnLoader)
			await lib.fetching<void>(uri, options)
			onSave()
		} catch (error: unknown) {
			// TODO: pretty notification alert
			console.error(error)
		} finally {
			setFetchIsLoading(state.turnOffLoader)
		}
	}

	return <div
		className="modal show"
		style={{ display: 'block', position: 'initial' }}
	>
		<Modal show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Edit role #{item?.id}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label htmlFor={'input-code'}>Code</Form.Label>
						<Form.Control
							id={'input-code'}
							placeholder="Code"
							type="text"
							value={code}
							onChange={(e) => setCode(e.target.value)}
						/>
						<br/>

						<Form.Label htmlFor={'input-name'}>Name</Form.Label>
						<Form.Control
							id={'input-name'}
							placeholder="Name"
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<br/>

						<Form.Label htmlFor={'select-is-system'}>Is</Form.Label>
						<Select
							inputId={'select-is-system'}
							className="react-select-container"
							options={IS_SYSTEM_OPTIONS}
							value={isSystem}
							isDisabled={false}
							isSearchable={false}
							onChange={(selectedItem) => {
								setIsSystem(selectedItem)
							}}
						/>
						<br/>

						<Form.Label htmlFor={'input-notes'}>Access</Form.Label>
						<RoleAccessFields rules={rules} setRules={setRules} />

						{/* <br/>
						<Form.Label htmlFor={'input-notes'}>Notes</Form.Label>
						<Form.Control
							id={'input-notes'}
							placeholder="Notes"
							type="text"
							value={notes}
							onChange={(e) => setNotes(e.target.value)}
						/> */}
					</Form.Group>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="primary" onClick={onUpdate}>Save</Button>
				<Button variant="secondary" onClick={onClose}>Close</Button>
			</Modal.Footer>
		</Modal>
	</div>
}
