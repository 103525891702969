import { useState, useEffect, useRef } from 'react'
import { ESortOrder } from '../components'
import { useSearchParams } from 'react-router'
import * as lib from '../lib'

export const OFFER_SCOPE_PAGE_SORT_FIELDS = ['id', 'name', 'capType', 'status'] as const

export type IOfferScopeSortField = typeof OFFER_SCOPE_PAGE_SORT_FIELDS[number]

type IUseSortOfferScopePage = {
	sortBy: IOfferScopeSortField;
	setSortBy: (value: (((prevState: IOfferScopeSortField) => IOfferScopeSortField) | IOfferScopeSortField)) => void;
	order: ESortOrder;
	setOrder: (value: (((prevState: ESortOrder) => ESortOrder) | ESortOrder)) => void;
}

const DEFAULT_SORT_BY: IOfferScopeSortField = 'id'
const DEFAULT_SORT_ORDER: ESortOrder = ESortOrder.desc
export const useSortOfferScopePage = (group: string): IUseSortOfferScopePage => {
	const isFirstRender = useRef<boolean>(true)
	const [groupPrev, setGroupPrev] = useState<string>(group)
	const [searchParams, setSearchParams] = useSearchParams()

	const urlSort = (() => {
		try {
			return lib.sortSearchParams(searchParams.get('s')) ?? []
		} catch (error) {
			console.error(error)
			return []
		}
	})().filter((item) => {
		const sortOrderValues = Object.values(ESortOrder)

		return OFFER_SCOPE_PAGE_SORT_FIELDS.includes(item[0] as IOfferScopeSortField) && sortOrderValues.includes(item[1] as ESortOrder)
	})

	const [sortBy, setSortBy] = useState<IOfferScopeSortField>(urlSort?.[0]?.[0] as IOfferScopeSortField ?? DEFAULT_SORT_BY)
	const [order, setOrder] = useState<ESortOrder>(urlSort?.[0]?.[1] as ESortOrder ?? DEFAULT_SORT_ORDER)

	useEffect(() => {
		let _sortBy = sortBy
		let _order = order
		//console.log('useSortOfferScopePage --- useEffect START:: ', { sortBy, _sortBy, order, _order })
		// reset sort logic [RSL] onGroup (menu tabs) changed
		if (!isFirstRender.current && groupPrev !== group) {
			setGroupPrev(group)
			_sortBy = DEFAULT_SORT_BY
			_order = DEFAULT_SORT_ORDER
			setSortBy(DEFAULT_SORT_BY)
			setOrder(DEFAULT_SORT_ORDER)
		}

		if (_sortBy && _order) {
			const newSortStr = lib.sortSetup([[_sortBy, _order]])

			setSearchParams((prev) => {
				// after first pageLoad/pageRefresh setting/resetting is not needed
				if (!isFirstRender.current) {
					if (_order !== ESortOrder.unset) {
						prev.set('s', newSortStr)
					} else {
						prev.delete('s')
					}
				} else {
					isFirstRender.current = false
				}

				return prev
			})
		}
		//console.log('useSortOfferScopePage --- useEffect END:: ', { sortBy, _sortBy, order, _order })
	}, [group, sortBy, order])

	return {
		// sort: order !== ESortOrder.unset ? [[sortBy, order]] : [],
		sortBy,
		setSortBy,
		order,
		setOrder
	}
}