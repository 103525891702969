export type INoFetchOptions = {
	readonly req: Request;
	readonly timeout: number;
	readonly body?: any;
	readonly error?: Error;
	readonly res?: Response;
}

export class INoFetch extends Error {

	constructor(
		public readonly type: string,
		protected readonly options: INoFetchOptions,
	) {
		super()
	}

	get body(): any | null {
		return this.options.body ?? null
	}

	get error(): Error | null {
		return this.options.error ?? null
	}

	get req(): Request {
		return this.options.req
	}

	get res(): Response | null {
		return this.options.res ?? null
	}

	get timout(): number {
		return this.options.timeout
	}

	get stacks(): string[] {
		return this.stack?.split('\n').map(_ => _.trim()) ?? []
	}

	get method(): string {
		return this.req.method
	}

	get url(): URL {
		return new URL(this.req.url)
	}

	get isAbort(): boolean {
		return this.type === 'abort'
	}

	get isError(): boolean {
		return this.type === 'error'
	}

	get isUnknown(): boolean {
		return this.type === 'unknown'
	}

	get status(): number | null {
		return this.res?.status ?? null
	}

	get statusText(): string | null {
		return this.res?.statusText ?? null
	}

	get causes(): unknown {
		return this.error?.cause
	}

	get message(): string {
		return this.options?.body?.message ?? this.error?.message ?? '?'
	}

}
