import {IconCalendar, IconCalendarSchedule} from '../../ui'
import {EOfferScopeCapType} from '../../../types/enum'


type ICapTypeIcon = {
	value: EOfferScopeCapType;
}
export const CapTypeIcon = ({value}: ICapTypeIcon) => {
	let icon;

	switch (value) {
		case EOfferScopeCapType.cumulative: {
			// CalendarIcon for 'cumulative' capType
			icon = <IconCalendar />
			break
		}
		case EOfferScopeCapType.daily: {
			// IconCalendarSchedule for 'daily' capType
			icon = <IconCalendarSchedule />
			break
		}
		default: {
			icon = null
			break
		}
	}

	return icon
}