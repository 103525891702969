import * as lib from '../lib'
import { IFetchItems, IFetchCommonCountry } from '../types'

export const listCommonCountry = async (
	signal: AbortSignal,
	token?: string | null,
): Promise<IFetchItems<IFetchCommonCountry>> => {
	const headers = lib.bearer(token)
	const method: string = 'GET'
	const params: lib.FetchOptions['params'] = {}
	const uri: string = 'common/countries?take=all'

	const options: lib.FetchOptions = {
		headers,
		method,
		params,
		signal,
	}
	const data = await lib.fetching<IFetchItems<IFetchCommonCountry>>(uri, options)

	return {
		items: data?.items ?? [],
		total: data?.total ?? 0
	}
}
