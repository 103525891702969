import { INoFetch } from './INoFetch'

export class INoFetchError extends INoFetch {
	constructor(
		req: Request,
		res: Response,
		timeout: number,
		body: any,
	) {
		super('error', { body, req, res, timeout })
	}

}
