import React from 'react'
import { UiTableSortLabel, ESortOrder } from './UiTableSortLabel'

type IHeadCellFormatter = {
	value: any;
	configItem: any;
	cellIndex: number
}

export type IHeadCellFormatterSortable<T> = IHeadCellFormatter & {
	sortBy: T;
	setSortBy: (sortBy: T) => void;
	order: ESortOrder;
	setOrder: (order: ESortOrder) => void;
}

type IRowCellFormatter<T> = {
	value: any,
	rowData: T;
	rowsData: Array<T>;
	rowIndex: number;
	cellIndex: number
}

export type IUiTableConfig<T extends Record<string, any> = any> = {
	key: string;
	title: string;
	headCellFormatter?: (options: IHeadCellFormatter) => React.ReactNode;
	rowCellFormatter?: (options: IRowCellFormatter<T>) => React.ReactNode;
	classesToAdd?: {
		th?: string;
		td?: string;
	};
	isSortable?: boolean;
}

type IUiTableProps<T extends Record<string, any> = any> = {
	data: Array<T>;
	config: IUiTableConfig<T>[];
	classesToAdd?: {
		wrapper?: string;
		table?: string;
	};
}

const NO_DATA_CELL: React.ReactNode = '???'
const NO_DATA: React.ReactNode = (<div style={{ textAlign: 'center' }}><h5>No Data found</h5></div>)

const DEFAULT_SORT_ORDER: ESortOrder = ESortOrder.unset

const getNextSortOrder = (currentOrder: ESortOrder) => {
	const orderList: ESortOrder[] = [ESortOrder.desc, ESortOrder.asc, ESortOrder.unset]
	const index = orderList?.indexOf(currentOrder)
	const nextIndex = (index + 1 >= 0 && index + 1 < orderList.length) ? index + 1 : 0

	return orderList[nextIndex]
}

export function headCellFormatterSortable<T>(
	{
		value,
		configItem,
		cellIndex,
		sortBy,
		setSortBy,
		order,
		setOrder
	}: IHeadCellFormatterSortable<T>
): React.ReactNode {
	const cellItemKey = configItem?.key

	const innerOrder = cellItemKey === sortBy ? order : DEFAULT_SORT_ORDER

	const onSortByChange = (key: T) => () => {
		const nextSortOrder = getNextSortOrder(innerOrder)
		setSortBy(key)
		setOrder(nextSortOrder)
	}

	return (
		<UiTableSortLabel active={cellItemKey === sortBy}
		                  direction={innerOrder}
		                  onClick={onSortByChange(cellItemKey)}
		>
			{value ?? NO_DATA_CELL}
		</UiTableSortLabel>
	)
}

export const UiTable = (props: IUiTableProps) => {
	const { data, config, classesToAdd } = props

	return <>
		<div className="global-table-area">
			<div className={`table-responsive overflow-auto ${classesToAdd?.wrapper ?? ''}`}>
				<table className={`table align-middle table-bordered ${classesToAdd?.table ?? ''}`}>
					<thead className="text-dark">
					<tr>
						{
							config.map((configItem, configItemIndex) => {
								const value = configItem?.title

								return <th
									className={`${configItem?.classesToAdd?.th ?? ''}`}
									key={`${configItem?.key ?? configItemIndex}`}
								>
									{
										configItem?.headCellFormatter?.({
											value,
											configItem,
											cellIndex: configItemIndex
										}) ?? value ?? NO_DATA_CELL
									}
								</th>
							})
						}
					</tr>
					</thead>

					<tbody className="text-body o-sortable">
					{
						data.length ? data.map((dataItem, dataIndex) => {
							return <tr
								key={`${dataItem?.id ?? dataIndex}`}
							>
								{
									config.map((configItem: any, configItemIndex) => {
										const value = dataItem?.[configItem?.key]

										return <td
											key={`${configItem?.key ?? configItemIndex}`}
											className={`fw-medium fs-15 text-dark ${configItem?.classesToAdd?.td ?? ''}`}
										>
											{
												configItem?.rowCellFormatter?.({
													value,
													rowData: dataItem,
													rowsData: data,
													rowIndex: dataIndex,
													cellIndex: configItemIndex
												}) ?? value ?? NO_DATA_CELL
											}
										</td>
									}) ?? null
								}
							</tr>
						}) : (<tr>
							<td colSpan={config.length}>{NO_DATA}</td>
						</tr>)
					}
					</tbody>
				</table>
			</div>
		</div>
	</>
}