import { createInstance, LOCALSTORAGE } from 'localforage'

// https://www.npmjs.com/package/localforage
// https://localforage.github.io/localForage/#data-api-setitem

export class StorageService {
	private readonly store: ReturnType<typeof createInstance>

	constructor() {
		const options: LocalForageOptions = {
			driver: LOCALSTORAGE,
			name: 'all-in',
			storeName: 'keitaro',
			version: 1.0,
			description: '',
		}
		this.store = createInstance(options)
	}

	async read<T = any>(key: string): Promise<T | undefined> {
		try {
			const json: string | null = await this.store.getItem(key)
			if (json === null) {
				throw new Error(`Item "${key}" not found in store`)
			}
			const data: T = JSON.parse(json)
			return data			
		} catch (error) {
			console.error(error)
			return undefined
		}
	}

	async remove(key: string): Promise<boolean> {
		try {
			await this.store.removeItem(key)
			return true
		} catch (error) {
			console.error(error)
			return false
		}
	}

	async save<T = any>(key: string, data?: T): Promise<boolean> {
		try {
			const json: string = JSON.stringify(data ?? null)
			await this.store.setItem(key, json)
			return true
		} catch (error) {
			console.error(error)
			return false
		}
	}
}

export const store = new StorageService()
