import React from 'react'

export const IconSortUnset = ({ styles = {} }: { styles?: React.CSSProperties; }) => (
	<svg xmlns="http://www.w3.org/2000/svg"
	     viewBox="0 0 24 24"
	     width="24px"
	     height="24px"
	     fill="currentColor"
	     style={styles}
	>
		<path d="M12 3V5H3V3H12ZM16 19V21H3V19H16ZM22 11V13H3V11H22Z"></path>
		{/*<path d="M16 18V20H5V18H16ZM21 11V13H3V11H21ZM19 4V6H8V4H19Z"></path>*/}
	</svg>
)