import * as lib from '../lib'
import { IFetchListOfferAffiliateNetwork } from '../types'

export const listOfferAffiliateNetwork = async (
	signal: AbortSignal,
	token?: string | null
): Promise<IFetchListOfferAffiliateNetwork> => {
	const headers = lib.bearer(token)
	const method: string = 'GET'
	const params: lib.FetchOptions['params'] = {}
	const uri: string = 'offer/affiliate-network?take=all'

	const options: lib.FetchOptions = {
		headers,
		method,
		params,
		signal
	}
	const data = await lib.fetching<IFetchListOfferAffiliateNetwork>(uri, options)

	return {
		items: data?.items ?? [],
		total: data?.items?.length ?? 0
	}
}