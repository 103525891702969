import { useState, useEffect, useRef } from 'react'
import { IOfferScopeFilters, IFilterConfig } from '../types'
import { constructNewFilterFromConfig, constructUserFiltersFromUrl } from '../components/filters'
import * as lib from '../lib'
import { EFilterOperator, EFilterType, EFilterSpecOperator, EFilterFetchConvertType } from '../types/enum'
import { useRecoilValue } from 'recoil'
import * as state from '../states'
import { CAP_TYPE_OPTIONS, STATUS_OPTIONS } from '../constants'
import { useSearchParams } from 'react-router'

type IUseFiltersOfferScopePage = {
	filter: [string, any][];
	send: boolean;
	activeFiltersCount: number;
	filterFillFactor: number;
	isFiltersPanelOpen: boolean;
	setIsFiltersPanelOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void;
	offerScopeFiltersConfig: IFilterConfig<keyof IOfferScopeFilters>[];
	defaultFiltersValue: IOfferScopeFilters;
	initialFiltersValue: IOfferScopeFilters;
	clearChildFilters: number;
	setUserFilters: (value: (((prevState: (IOfferScopeFilters | null)) => (IOfferScopeFilters | null)) | IOfferScopeFilters | null)) => void;
}

const OFFER_SCOPE_FILTERS_DEFAULT: IOfferScopeFilters = {
	name: '',
	'stats.ids': null,
	capType: null,
	status: null,
	affiliateNetwork: null,
	country: null
}


export const useFiltersOfferScopePage = (group: string): IUseFiltersOfferScopePage => {
	const isFirstRender = useRef<boolean>(true)
	const [groupPrev, setGroupPrev] = useState<string>(group)

	const profile = useRecoilValue(state.profile)
	//TODO: REMOVE CRUTCH - ACCESS FOR TEAM-X-USERS'
	const isTeam = useRecoilValue(state.isTeam)
	const profileRole = profile?.role

	const [searchParams, setSearchParams] = useSearchParams()

	const urlFilter = (() => {
		try {
			return lib.filterSearchParams(searchParams.get('f')) ?? []
		} catch (error) {
			console.error(error)
			return []
		}
	})()

	const [filter, setFilter] = useState<[string, any][]>([...urlFilter]) // main filter with mixins
	const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState<boolean>(false)
	const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0)

	const [clearChildFilters, setClearChildFilters] = useState<number>(0)
	const [send, setSend] = useState<boolean>(false)
	const [userFilters, setUserFilters] = useState<IOfferScopeFilters | null>(null)

	const filterFillFactor = !!userFilters ? (100 * activeFiltersCount / Object.keys(userFilters).length) : 0

	const countryOptions = useRecoilValue(state.countryOptions)
	const affiliateNetworkOptions = useRecoilValue(state.affiliateNetworkOptions)

	const offerScopeFiltersConfig: IFilterConfig<keyof IOfferScopeFilters>[] = [
		{
			name: 'name',
			label: 'Name',
			type: EFilterType.input,
			operator: EFilterOperator.isLike,
			specOperator: EFilterSpecOperator.everywhere,
			styles: { width: '200px' }
		},
		{
			name: 'stats.ids',
			label: 'Offer ID',
			type: EFilterType.input,
			fetchConvertType: EFilterFetchConvertType.numberArray,
			operator: EFilterOperator.isSubSet,
			styles: { width: '150px' }
		},
		{
			name: 'affiliateNetwork',
			label: 'Affiliate Network',
			type: EFilterType.selectMultiple,
			operator: EFilterOperator.isSubSet,
			options: affiliateNetworkOptions ?? [],
			styles: { minWidth: '200px' }
		},
		{
			name: 'country',
			label: 'Country',
			type: EFilterType.selectMultiple,
			operator: EFilterOperator.isSubSet,
			options: countryOptions ?? [],
			styles: { minWidth: '200px' }
		},
		{
			name: 'capType',
			label: 'Cap Type',
			type: EFilterType.selectSingle,
			operator: EFilterOperator.isEqual,
			options: CAP_TYPE_OPTIONS,
			styles: { minWidth: '200px' }
		},
		{
			name: 'status',
			label: 'Status',
			type: EFilterType.selectSingle,
			operator: EFilterOperator.isEqual,
			options: STATUS_OPTIONS,
			styles: { minWidth: '160px' }
		}
	]

	const initialUserFilters = constructUserFiltersFromUrl(
		urlFilter,
		offerScopeFiltersConfig,
		OFFER_SCOPE_FILTERS_DEFAULT
	)

	useEffect(() => {
		let _userFilters = userFilters
		//console.log('hook.useFiltersOfferScopePage useEffect::START:: ', { userFilters, _userFilters, filter })
		// reset filters [RFC] onGroup (menu tabs) changed
		if (send && groupPrev !== group) {
			setGroupPrev(group)

			setFilter([]) // clear main filter --> update in useEffect
			_userFilters = OFFER_SCOPE_FILTERS_DEFAULT
			setUserFilters(OFFER_SCOPE_FILTERS_DEFAULT)
			setClearChildFilters(prev => prev + 1)
		}

		if (!!_userFilters) {
			setSend(true)
			//console.log('hook.useFiltersOfferScopePage useEffect::MIDDLE:: ', { userFilters, _userFilters, filter })

			const { newFilter, activeFiltersCnt } = constructNewFilterFromConfig(
				_userFilters,
				offerScopeFiltersConfig
			)
			const newFilterStr = lib.filterSetup([...newFilter ?? []])

			setSearchParams((prev) => {
				if (newFilterStr) {
					prev.set('f', newFilterStr)
				} else {
					prev.delete('f')
				}

				// after first pageLoad/pageRefresh resetting is not needed
				if (!isFirstRender.current) {
					prev.delete('page')
				} else {
					isFirstRender.current = false
				}

				if (!!isTeam.length && !isTeam.includes(prev.get('group') ?? '') ) {
					prev.set('group', group)
				}

				return prev
			})

			if (group) {
				newFilter.push([`group${EFilterOperator.isEqual}`, group])
			}

			setFilter(newFilter)
			setActiveFiltersCount(activeFiltersCnt)

			// to open FiltersPanel if there were previously active filters on the page [onRefreshPage case]
			if (activeFiltersCnt > 0) {
				setIsFiltersPanelOpen(true)
			}
			//console.log('hook.useFiltersOfferScopePage:: useEffect::END:: ', { userFilters, _userFilters, filter, newFilter, newFilterStr })
		}
	}, [userFilters, group])

	return {
		filter,
		send,
		activeFiltersCount,
		filterFillFactor,
		isFiltersPanelOpen,
		setIsFiltersPanelOpen,
		offerScopeFiltersConfig,
		defaultFiltersValue: OFFER_SCOPE_FILTERS_DEFAULT,
		initialFiltersValue: initialUserFilters,
		clearChildFilters,
		setUserFilters
	}
}