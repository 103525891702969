import { Form } from 'react-bootstrap'
import Select from 'react-select'
import { IFilterSelectSingle } from '../../types'
import uniqid from 'uniqid'

export const FilterSelectSingle = (props: IFilterSelectSingle<string>) => {
	const {
		value,
		setValue,
		options,
		label,
		placeholder,
		styles = {},
		isSearchable = false,
		isClearable = false,
		isDisabled = false
	} = props
	const key = uniqid()
	const foundValue = options.find(item => item.value === value) ?? null

	return (
		<div className={'filter-input-cont'} style={styles}>
			{label ? <Form.Label htmlFor={`select-single-${key}`}>{label}</Form.Label> : null}

			<Select
				inputId={`select-single-${key}`}
				className="react-select-container"
				classNamePrefix="react-select"
				options={options}
				value={foundValue}
				placeholder={placeholder ?? 'Select ...'}
				isSearchable={isSearchable}
				isClearable={isClearable}
				isDisabled={isDisabled}
				isMulti={false}
				styles={{
					control: (baseStyles, state) => {
						//console.log('SingleSelect', {baseStyles, state})
						return ({
							...baseStyles,
							cursor: 'pointer'
							// TODO: check the styles
							// border: state.isFocused ? `1px solid var(--borderColor09)` : `1px solid hsl(0, 0%, 80%)`,
							// // This line disable the blue border
							// boxShadow: state.isFocused ? `1px solid var(--borderColor09)` : `1px solid hsl(0, 0%, 80%)`,
							/*'&:focus': {
								border: state.isFocused ? `1px solid var(--borderColor09)` : `1px solid hsl(0, 0%, 80%)`
							}*/
						})
					},
					option: (styles, state) => {
						return {
							...styles,
							cursor: 'pointer'
						}
					}
				}}
				onChange={(selectedItem) => {
					setValue(selectedItem?.value ?? null)
				}}
			/>
		</div>
	)
}