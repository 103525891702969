import React from 'react'
import { IconFilter } from '../ui'

type IFiltersButtonIcon = {
	activeFiltersCount: number;
	filterFillFactor: number;
	onClick: () => void;
	styles?: React.CSSProperties;
}

export const FiltersButtonIcon = (props: IFiltersButtonIcon) => {
	const {
		activeFiltersCount = 0,
		filterFillFactor = 0,
		onClick,
		styles = {},
	} = props

	return (
		<i className={`filters-button icon`}
		   style={styles}
		   onClick={(event) => onClick()}
		>
			<IconFilter fillFactor={filterFillFactor}/>
			<span className="count">{activeFiltersCount}</span>
		</i>
	)
}