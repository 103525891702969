import { atom, selector } from 'recoil'
import uniqid from 'uniqid'

import { IAccountToken, Part } from '../types'

const key: string = uniqid()

const tokensAtom = atom<Part<IAccountToken, 'access' | 'refresh'> | null>({
	key: `tokens:atom:${key}`,
	default: null,
})

export const tokens = selector<Part<IAccountToken, 'access' | 'refresh'> | null>({
	key: `tokens:selector:${key}`,
	get: ({ get }) => {
		return get(tokensAtom)
	},
	set: ({ set }, newValue) => {
		if (!!newValue && newValue instanceof Object) {
			return set(tokensAtom, newValue)
		}
		throw new Error('tokens:selector:: Incorrect value set')
	},
})
