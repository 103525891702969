import { Outlet, useNavigate, useLocation } from 'react-router'
import { useRecoilValue } from 'recoil'

import * as state from '../../states'
import { LoadingPage } from '../../pages'
import { useAppInit } from '../../hooks'
import { useEffect } from 'react'

import { ToastContainer } from 'react-toastify'

export const IndexLayout = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const fetchIsLoading = useRecoilValue(state.fetchIsLoading)
	const isAuth = useRecoilValue(state.isAuth)

	//TODO: check the logic (profile is not consistent after logout here [from inner data-state!])
	const { error, profile: profileNew, warmUp } = useAppInit(true)
	//console.log('layout.indexLayout:: useAppInit:: ', {profileNew})

	useEffect(() => {
		if (isAuth === true && ['/login'].includes(location.pathname)) {
			navigate('/')
		}
	}, [isAuth, navigate])

	return <>
		<div style={{ display: 'none' }}>App</div>

		{fetchIsLoading > 0 ? (<LoadingPage/>) : null}

		<ToastContainer/>
		<Outlet/>
	</>
}
