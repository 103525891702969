type ILogoIconProps = {
	width?: string;
	height?: string;
}

export const LogoIcon = ({width, height}: ILogoIconProps) => {
	return (
		<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
		     width={`${width ?? '36px'}`}
		     height={`${height ?? '45px'}`}
		     viewBox="0 0 2438.000000 3026.000000"
		     preserveAspectRatio="xMidYMid meet">

			<g transform="translate(0.000000,3026.000000) scale(0.100000,-0.100000)"
			   fill="#000000" stroke="none">
				<path d="M12190 15135 l0 -15130 1515 1515 1515 1515 0 1247 c0 687 -3 1248
-7 1248 -5 0 -286 -278 -625 -617 l-618 -618 0 12095 0 12095 -890 890 -890
890 0 -15130z"/>
				<path d="M9608 27673 l-888 -888 0 -11655 0 -11655 1515 -1515 1515 -1515 0
1255 0 1255 -625 625 -625 625 -2 12177 -3 12178 -887 -887z"/>
				<path d="M15660 15135 l0 -11660 890 890 890 890 0 9880 0 9880 -890 890 -890
890 0 -11660z"/>
				<path d="M3517 21602 l-3507 -3507 0 -1270 0 -1270 2620 2620 2620 2620 0
-5665 0 -5665 -840 840 -840 840 0 1460 0 1460 620 620 620 620 0 1265 0 1265
-1515 -1515 -1515 -1515 0 -2195 0 -2195 2630 -2630 2630 -2630 0 9978 c0
5487 -3 9977 -8 9977 -4 0 -1585 -1578 -3515 -3508z"/>
				<path d="M19130 15135 l0 -8190 890 890 890 890 2 3467 3 3466 829 -2581 c457
-1420 835 -2601 841 -2624 l11 -42 892 892 892 892 0 2940 0 2940 -888 888
c-488 488 -890 887 -894 887 -5 0 -9 -798 -10 -1772 l-3 -1772 -838 2619 -838
2620 -889 890 -890 890 0 -8190z"/>
			</g>
		</svg>
	)
}