import React from 'react'
import { IconSortDesc, IconSortAsc, IconSortUnset } from './UiIcons'

export enum ESortOrder {
	desc = 'desc',
	asc = 'asc',
	unset = 'unset'
}

type ITableSortLabel = {
	active: boolean;
	direction: ESortOrder;
	onClick: () => void;
	children: React.ReactNode;
}
export const UiTableSortLabel = (props: ITableSortLabel) => {
	const { active, direction, onClick, children } = props

	let icon
	const iconColor = active ? 'rgb(93, 135, 255)' : 'black'

	switch (direction) {
		case ESortOrder.desc: {
			icon = <IconSortDesc styles={{ color: iconColor }}/>
			break
		}
		case ESortOrder.asc: {
			icon = <IconSortAsc styles={{ color: iconColor }}/>
			break
		}
		default: {
			icon = <IconSortUnset styles={{ color: `var(--bodyColor)` }}/>
			break
		}
	}

	return (
		<i className={'table-sort-label'}
		   onClick={() => onClick()}
		>
			{children}
			{icon}
		</i>
	)
}